import React, {useState, useEffect} from 'react'
import SelectSearch from "react-select-search";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import {Editor} from "@tinymce/tinymce-react";

function TerapiaPaciente(props) {
    const [etiquetas, setEtiquetas] = useState([])
    const [terapiaPaciente, setTerapiaPaciente] = useState({
        id: "",
        terapia: "",
        paciente: "",
        tag: "",
        texto: ""
    })
    useEffect(() => {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/tags/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setEtiquetas(response.tags)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/paciente/?terapia=' + props.terapia.id + '&paciente=' + props.paciente.paciente
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        if (response.length > 0) {
                                            setTerapiaPaciente(response[0])
                                        } else {
                                            setTerapiaPaciente({
                                                id: "",
                                                terapia: props.terapia.id,
                                                paciente: props.paciente.paciente,
                                                tag: "",
                                                texto: ""
                                            })
                                        }}
                                    )
                        } else {
                            setTerapiaPaciente({
                                id: "",
                                terapia: props.terapia.id,
                                paciente: props.paciente.paciente,
                                tag: "",
                                texto: ""
                            })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })


        }
        ,
        [props]
    )

    function handle_etiqueta(value, object) {
        update_terapia_paciente({...terapiaPaciente, tag: object.name})
    }

    function handle_input(e) {
        const {name, value} = e.target;
        setTerapiaPaciente(prev => ({...prev, [name]: value}))
    }

    function handle_editor(content, editor) {
        setTerapiaPaciente(previous => ({...previous, texto: content}))
    }

    function handle_blur(e) {
        update_terapia_paciente(terapiaPaciente)
    }

    function update_terapia_paciente(obj) {
        if (obj.id === "") {
            const terpac = {...obj}
            delete terpac.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/paciente/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(terpac)
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        setTerapiaPaciente(response)
                                    })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            const terpac = {...obj}
            delete terpac.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/paciente/detail/' + obj.id + "/"
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(terpac)
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        setTerapiaPaciente(response)
                                    })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    return (
        <div className="border-top mt-2 border-secondary">
            <div className="row ml-2  mt-2">
                <label className="">Etiqueta</label>
                {etiquetas.length > 0 &&
                <SelectSearch
                    className="select-search mr-4 ml-3"
                    // value={etiqueta}
                    options={etiquetas.map((etiqueta, index) =>
                        ({name: etiqueta, value: index})
                    )}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_etiqueta}
                />
                }
                <input type="text" name="tag" value={terapiaPaciente.tag} onChange={handle_input} onBlur={handle_blur}/>

                <h3 className="ml-5">{props.paciente.nombre}</h3>
            </div>
            <div className={`bg-white texto-terapia mt-2`}>
                <Editor id='texto'
                        name='texto'
                        init={{
                            placeholder: "Texto",
                            menubar: true,
                            branding: false,
                            plugins: [
                                'advlist autolink lists link image  print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help  '
                            ],
                            language: 'es_ES',
                            inline: false,
                            elementpath: false,
                            height: 600,
                            toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                        }}
                        value={terapiaPaciente.texto}
                        onEditorChange={handle_editor}
                        onBlur={handle_blur}

                />
            </div>
        </div>

    )

}

export default TerapiaPaciente