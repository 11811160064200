import React from 'react';
import "./Medicaciones.css"

function SearchMedicamentoInline(props) {

    return (
        <tr className="w-100 searchInline-linea" onClick={() => props.selectMedicamento(props.medicamento)}>
            <td>
                <img className="img-fluid foto-inline" src={props.medicamento.foto} alt={""} />
            </td>
            <td>
                {props.medicamento.nombre}
            </td>
            <td>
                {props.medicamento.formaFarmaceutica}
            </td>
        </tr>
    )

}

export default SearchMedicamentoInline