import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import MedicamentoCentroInline from "./MedicamentoCentroInline";
import {authHeaders} from "../Utils/authUtils";

import './Pacientes.css';


function MedicamentosCentro(props) {
    const [medicamentosList, setMedicamentosList] = useState({})
    const [is_max, setIs_max] = useState(false)

    function loadMedicamentos() {
        authHeaders().then(authHeader => {
            let endpoint = ""
            endpoint = APIPATH + 'api/medicamentos/uso/centro/' + props.centro + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setMedicamentosList(response)

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    // function delete_paciente(paciente) {
    //     setUserList(prevState => prevState.filter(profile => profile.user.username !== user.user.username))
    // }

    useEffect(() => {
            loadMedicamentos();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props]
    );

    return (
        <div className="row">
            <div className={`${is_max ? 'col-9 medicamentosArea-max' : 'col-12 medicamentosArea'} bg-white px-0`}>
                <div className="col-12 bg-white m-0">
                    <div className="row">
                        <h3 className=" ml-4  text-primary">Medicamentos</h3>
                        {medicamentosList.warning > 0 &&
                        <span
                            className="badge ml-2 badge-pill mb-3 mt-1 badge-warning">{medicamentosList.warning}</span>
                        }
                        {medicamentosList.alert > 0 &&
                        <span className=" badge ml-2 badge-pill mb-3 mt-1 badge-danger ">{medicamentosList.alert}</span>
                        }
                        <div className="col-1 ml-auto">
                            <button type="button" className=" mr-1 mt-0 pt-1 border-0 link-button bg-white text-success"
                                    onClick={() => {
                                        props.maximizar("medicamentos", !is_max)
                                        setIs_max(prev => !prev)
                                    }}>
                                {is_max ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                    </svg> :
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                        <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                        <path
                                            d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <table className="bg-white table ">
                    <thead>


                    <tr>

                        <th className="colspan=2 text-primary bg-white">Medicamento</th>
                        <th className="text-primary bg-white">Paciente</th>
                        <th className="text-center  text-primary bg-white">Dias</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(medicamentosList).length > 0 && medicamentosList.listUso.map((medicamento, index) =>
                        <MedicamentoCentroInline
                            key={index}
                            medicamento={medicamento}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MedicamentosCentro;
