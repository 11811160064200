import React, {useState, useEffect} from 'react'
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";

function Saldos(props) {
    const [depositos, setDepositos] = useState([])
    const [pacientes, setPacientes] = useState([])

    useEffect(() => {
        if (props.centro.id !== undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/saldo/depositos/?usuario=True&centro=' + props.centro.id
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setDepositos(response.depositos)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/saldo/pacientes/?centro=' + props.centro.id
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setPacientes(response.pacientes)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }, [props])

    return (<div className="row">
        <div className="col-6 bg-white">
            <h3 className="col-4 text-primary">Depósitos</h3>
            <table className="table">
                <thead>
                <tr>
                    <th>
                        Depósito
                    </th>
                    <th>
                        Debe
                    </th>
                    <th>
                        Haber
                    </th>
                    <th>
                        Saldo
                    </th>
                </tr>
                </thead>
                <tbody>
                {depositos.map((deposito, index) =>
                    <tr key={index}>
                        <td>
                            {deposito.deposito}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(deposito.debe)}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(deposito.haber)}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(deposito.saldo)}
                        </td>
                    </tr>
                )

                }
                </tbody>
            </table>
        </div>
        <div className="col-6 bg-white">
            <h3 className="col-4 text-primary">Pacientes</h3>
            <table className="table">
                <thead>
                <tr>
                    <th colSpan="2">
                        Paciente
                    </th>
                    <th>
                        Debe
                    </th>
                    <th>
                        Haber
                    </th>
                    <th>
                        Saldo
                    </th>
                </tr>
                </thead>
                <tbody>
                {pacientes.map((paciente, index) =>
                    <tr key={index}>
                        <td width="10%">
                            {paciente.foto &&
                            <img src={paciente.foto} alt=""
                                 className="img-fluid"/>
                            }
                        </td>
                        <td>
                            {paciente.alias}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(paciente.debe)}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(paciente.haber)}
                        </td>
                        <td>
                            {new Intl.NumberFormat("de-DE", {
                                style: "currency",
                                currency: "EUR"
                            }).format(paciente.saldo)}
                        </td>
                    </tr>
                )

                }
                </tbody>
            </table>
        </div>

    </div>)
}

export default Saldos