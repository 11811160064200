import React, {useState} from 'react';
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import ConfirmDelete from "./ConfirmDelete";
import ChangePassword from "./ChangePassword";

function UserInline(props) {
    const [profile, setProfile] = useState(props.profile)
    const [saveInput, setSaveInput] = useState(false)
    const [formErrors, setFormErrors] = useState({email: false})

    function change_profile(event) {
        let {name, value, checked} = event.target
        if (['is_manager', 'has_historial', 'is_monitor', 'is_contabilidad', 'is_contabilidad_all', 'is_externo'].includes(name)) {
            value = checked
        }
        const profile_data = {...profile, [name]: value}
        setProfile(profile_data)
        let ok = true
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/update/' + profile_data.user.username + '/'
            const data = {
                is_manager: profile_data.is_manager,
                has_historial: profile_data.has_historial,
                is_monitor: profile_data.is_monitor,
                is_contabilidad: profile_data.is_contabilidad,
                is_contabilidad_all: profile_data.is_contabilidad_all,
                is_externo: profile_data.is_externo,
            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }

            fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    if (response.ok) {
                        return response.json()
                    } else {
                        return ''
                    }
                }
            ).then(
                response => {
                    if (ok) {
                        setProfile(previous => {
                            return {...previous, ...response}
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function handle_input(event) {
        let {name, value} = event.target
        setProfile(previous => {
            return {...previous, user: {...previous.user, [name]: value}}
        })
        if (event.target.validity.typeMismatch) {
            setFormErrors(previous => {
                return {...previous, [name]: true}
            })
            setSaveInput(false)
        } else {
            setFormErrors(previous => {
                return {...previous, [name]: false}
            })
            setSaveInput(true)

        }
    }

    function change_user(event) {
        let {name, value, checked} = event.target
        if (name === 'is_active') {
            value = checked
        }
        const user_data = {...profile.user, [name]: value}
        save_user(user_data, name)
    }

    function change_email(event) {
        event.preventDefault()
        const user_data = {...profile.user}
        save_user(user_data, 'email')
    }

    function save_user(user_data, name) {
        console.log(name)
        let ok = true
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/user/' + user_data.username + '/'
            const data = user_data
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            console.log(data)
            fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    if (response.ok) {
                        return response.json()
                    } else {
                        return ''
                    }
                }
            ).then(
                response => {
                    if (ok) {
                        setProfile(previous => {
                            return {...previous, user: {...response}}
                        })
                        setSaveInput(false)
                    } else {
                        setFormErrors(previous => {
                            return {...previous, [name]: true}
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function delete_user(user) {
        props.delete_user(user)
    }


    let disabled_is_manager = false

    if (!props.is_manager || props.profile.user.username === props.user.username) {
        disabled_is_manager = true
    }
    return (
        <tr>
            <td>{props.profile.user.username}</td>
            <td>
                <input type='email'
                       className={`${formErrors.email ? "border border-danger" : "border-0"} bg-light w-100 form-control`}
                       disabled={!(props.is_manager || props.profile.user.username === props.user.username)} name="email"
                       value={profile.user.email} onChange={handle_input}/>
            </td>
            <td>
                <button
                    className={`border-0 bg-white ${saveInput ? "text-success" : ""} ${formErrors.email ? "text-danger" : ""}`}
                    disabled={!saveInput}
                    onClick={change_email}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                </button>
            </td>

            <td className='text-center'><input type="checkbox" name="is_manager" disabled={disabled_is_manager}
                                               checked={profile.is_manager} onChange={change_profile}/></td>
            <td className='text-center'><input type="checkbox" name="has_historial" disabled={!props.is_manager}
                                               checked={profile.has_historial} onChange={change_profile}/></td>
             <td className='text-center'><input type="checkbox" name="is_monitor" disabled={!props.is_manager}
                                               checked={profile.is_monitor} onChange={change_profile}/></td>
             <td className='text-center'><input type="checkbox" name="is_contabilidad" disabled={!props.is_manager}
                                               checked={profile.is_contabilidad} onChange={change_profile}/></td>
             <td className='text-center'><input type="checkbox" name="is_contabilidad_all" disabled={!props.is_manager}
                                               checked={profile.is_contabilidad_all} onChange={change_profile}/></td>
             <td className='text-center'><input type="checkbox" name="is_externo" disabled={!props.is_manager}
                                               checked={profile.is_externo} onChange={change_profile}/></td>
            <td className='text-center'><input type="checkbox" name="is_active" disabled={disabled_is_manager}
                                               checked={profile.user.is_active} onChange={change_user}/></td>
            <td>
                {props.is_manager &&
                <div>
                    <button className={`border-0 bg-white text-danger`} data-toggle="modal"
                            data-target={`#deleteModal-${props.id}`}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </button>
                    <ConfirmDelete
                        modalId={`deleteModal-${props.id}`}
                        profile={profile}
                        delete_user={delete_user}
                    />
                </div>
                }
            </td>
            <td>
                {(props.is_manager || props.profile.user.username === props.user.username) &&
                <div>
                    <button className={`border-0 bg-white text-success`} data-toggle="modal"
                            data-target={`#passwordModal-${props.id}`}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-key" fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"/>
                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                        </svg>
                    </button>
                     <ChangePassword
                        modalId={`passwordModal-${props.id}`}
                        profile={profile}
                    />
                </div>
                }
            </td>
        </tr>
    )
}

export default UserInline;

