import React from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";

// import './App.css';

function ConfirmDelete(props) {

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }

    function delete_user(event) {

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/' + props.profile.user.username + '/'
            const lookupOptions = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include'
            }
            fetch(endpoint, lookupOptions).then(response => {
                if (response.ok) {
                    close_modal()
                    props.delete_user(props.profile)
                }
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }


    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Borrar Usuario</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        Se va a proceder a borrar el usuario <strong>{props.profile.user.username}</strong>. Está Seguro?
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cancelar</button>
                        <button type="button" className="btn btn-primary" onClick={delete_user}>Borrar</button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ConfirmDelete