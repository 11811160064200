import React, {useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";

// import './App.css';

function ChangePassword(props) {
    const [formFields, setFormFields] = useState({
        password1: "",
        password2: ""
    })
    const [valid, setValid] = useState(false)

    function handleInput(event) {

        let value2 = ""
        const {name, value} = event.target
        setFormFields(prevState => {
            return {...prevState, [name]: value}
        })
        if (name === 'password1') {
            value2 = formFields.password2
        } else {
            value2 = formFields.password1
        }
        if (value2 === value) {
            setValid(true)
        } else {
            setValid(false)
        }
    }

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        setFormFields({
            password1: "",
            password2: ""
        })
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }

    function change_password(event) {

        const endpoint = APIPATH + 'api/accounts/password/change/'
        const data = {"password": formFields.password1, username: props.profile.user.username}
        let status = 0

        authHeaders().then(authHeader => {
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                body: JSON.stringify(data),
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    status = response.status
                    return response.json()
                }
            ).then(
                response => {
                    if ([401, 403].indexOf(status) !== -1) {
                        console.log('Error al registrar la contraseña')
                    } else {
                        close_modal()
                    }

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Cambiar Contraseña para {props.profile.user.username}</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type="password" className="col-8 form-control mt-4 mx-auto" name="password1"
                               placeholder="Contraseña" value={formFields.password1} onChange={handleInput}/>
                        <input type="password" className="col-8 form-control mt-2 mb-4 mx-auto" name="password2"
                               placeholder="Repetir Contraseña" value={formFields.password2} onChange={handleInput}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cancelar</button>
                        <button type="button" className="btn btn-primary" disabled={!valid}
                                onClick={change_password}>Cambiar
                        </button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ChangePassword