import React, {useState, useEffect} from 'react';
import {Modal} from "react-bootstrap";
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";


function MovimientoIva(props) {
    const nuevoProveedor = {
        cif: "",
        nombre: "",
        direccion1: "",
        direccion2: "",
        cp: "",
        localidad: "",
        provincia: ""
    }
    const [tablaIva, setTablaIva] = useState([])
    const [proveedores, setProveedores] = useState([])
    const [proveedor, setProveedor] = useState(nuevoProveedor)
    const [factura, setFactura] = useState("")
    useEffect(() => {
        setFactura(props.factura)
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contabilidad/tiposIva'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    const tempTabla = []
                    response.forEach(tipoIva => {
                        let base = 0

                        if (props.tiposIva.filter(mov => mov.tipo === tipoIva.id).length > 0) {
                            base = props.tiposIva.filter(mov => mov.tipo === tipoIva.id)[0].base
                        }
                        const cuota = parseFloat(base) * (parseFloat(tipoIva.tipo) / 100)
                        tempTabla.push({
                            base: base,
                            tipo: tipoIva.id,
                            iva: tipoIva.tipo,
                            cuota: cuota,
                            total: parseFloat(base) + parseFloat(cuota)
                        })
                    })
                    setTablaIva(tempTabla)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contabilidad/proveedor/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setProveedores(response)
                    if (props.proveedor && props.proveedor !== "") {
                        setProveedor(response.filter(prov => prov.id === props.proveedor)[0])
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }, [props])


    function handle_input(e) {
        const {value, dataset} = e.target
        const tipo = parseInt(dataset.id)
        if ((!isNaN(parseFloat(value)) && isFinite(value)) || value === "") {
            setTablaIva(prev => prev.map(iva => {
                if (iva.tipo === tipo) {
                    return {
                        ...iva,
                        base: value, cuota: value * (parseFloat(iva.iva) / 100),
                        total: value * (1 + parseFloat(iva.iva) / 100)
                    }
                } else {
                    return iva
                }
            }))

        }
    }

    function handle_proveedor(e) {
        const {value, name} = e.target
        if (name !== "cif" || (name === "cif" && value.length < 11)) {
            setProveedor(prev => ({...prev, [name]: value}))
            if (name === "cif" && proveedores.map(prov => prov.cif).includes(value)) {
                setProveedor(proveedores.filter(prov => prov.cif === value)[0])
            }
        }
    }

    function submit_iva(e) {
        const prov = {...proveedor}
        if (prov.id === undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/proveedor/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(prov)
                }
                fetch(endpoint, lookupOptions).then(response => response.json()).then(response => {
                    props.update(tablaIva, response.id, factura)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })


        } else {
            delete prov.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/proveedor/detail/' + proveedor.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(prov)
                }

                fetch(endpoint, lookupOptions).then(response => response.json()).then(response => {
                        props.update(tablaIva, response.id, factura)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }

    }


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="md">
                <Modal.Header closeButton>
                    <h5 className="modal-title col-12 text-center">IVA</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className=" col-2 ">Proveedor</label>
                        <SelectSearch
                            className="select-search select-2 ml-3 col-8"
                            value={proveedor.id}
                            options={proveedores.map((proveedor, index) =>
                                ({name: proveedor.cif + " " + proveedor.nombre, value: proveedor.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={(value, obj) => {
                                setProveedor(proveedores.filter(prov => prov.id === value)[0])
                            }}
                        />

                        <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={(e) => {
                                    setProveedor(nuevoProveedor)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <div className="row mt-2 ml-2">
                            <label className=" col-3 ">Factura</label>
                            <input type="text" name="factura" className="form-control col-9" value={factura}
                                   onChange={(e) => {setFactura(e.target.value)}} />
                        </div>
                        <div className="row px-4 mt-3">
                            <input type="text" name="cif" className="form-control col-4" value={proveedor.cif}
                                   onChange={handle_proveedor} placeholder="CIF"/>
                            <input type="text" name="nombre" className="form-control col-7 ml-3"
                                   value={proveedor.nombre}
                                   onChange={handle_proveedor} placeholder="Nombre"/>
                        </div>
                        <div className="row px-4 mt-1">
                            <input type="text" name="direccion1" className="form-control" value={proveedor.direccion1}
                                   onChange={handle_proveedor} placeholder="Dirección"/>
                        </div>
                        <div className="row px-4 mt-1">
                            <input type="text" name="direccion2" className="form-control" value={proveedor.direccion2}
                                   onChange={handle_proveedor} placeholder="..."/>
                        </div>
                        <div className="row px-4 mt-1 mb-3">
                            <input type="text" name="cp" className="form-control col-2" value={proveedor.cp}
                                   onChange={handle_proveedor} placeholder="Cod.Postal"/>
                            <input type="text" name="localidad" className="form-control ml-1 col-5"
                                   value={proveedor.localidad}
                                   onChange={handle_proveedor} placeholder="Población"/>
                            <input type="text" name="provincia" className="form-control ml-1 col-4"
                                   value={proveedor.provincia}
                                   onChange={handle_proveedor} placeholder="Provincia"/>
                        </div>
                    </div>
                    <table className="table">
                        <thead>
                        <tr className="bg-light">
                            <th>Tipo</th>
                            <th>Base</th>
                            <th>Cuota</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tablaIva.map(tipoIva =>
                            <tr key={tipoIva.tipo}>
                                <td>
                                    {tipoIva.iva}%
                                </td>
                                <td>
                                    <div className="row">
                                        <input type='text'
                                               className="form-control base-iva"
                                               name="base"
                                               data-id={tipoIva.tipo}
                                               value={tipoIva.base}
                                               onChange={handle_input}/>
                                        <span className="mt-2 ml-1">€</span>
                                    </div>
                                </td>
                                <td>
                                    {parseFloat(tipoIva.cuota).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}
                                </td>
                                <td>
                                    {parseFloat(tipoIva.total).toLocaleString('de-DE', {
                                        style: 'currency',
                                        currency: 'EUR'
                                    })}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td colSpan="3">Total</td>
                            <td className={
                                Math.round(parseFloat(tablaIva.map(iva => iva.total).reduce((a, b) => a + b, 0)) * 100) === Math.round(parseFloat(props.total) * 100) ? 'text-success' : 'text-danger'
                            }>
                                {parseFloat(tablaIva.map(iva => iva.total).reduce((a, b) => a + b, 0)).toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}</td>
                        </tr>
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-warning"
                            onClick={props.handleClose}>Cancelar
                    </button>
                    <button type="button" className="btn btn-primary"
                            disabled={(Math.round(parseFloat(tablaIva.map(iva => iva.total).reduce((a, b) => a + b, 0)) * 100) !== Math.round(parseFloat(props.total) * 100)) || proveedor.cif === ""}
                            onClick={submit_iva}>Aceptar
                    </button>

                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default MovimientoIva;