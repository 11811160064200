import React, {useState} from 'react';

import './Login.css';
import {APIPATH, TOKEN_REFRESH_MINUTES} from "../settings";
import moment from "moment";

function Login(props) {
    const [formFields, setFormFields] = useState({
        username: '',
        password: '',
    })
    const [formErrors, setFormErrors] = useState({})

    function handleInput(event) {

        let {name, value} = event.target
        setFormFields(previous => {
            return {
                ...previous,
                [name]: value,
            };
        });

    }

    function submitForm(event) {
        event.preventDefault()
        const endpoint = APIPATH + 'api/accounts/jwt/'
        const data = {"username": formFields.username, "password": formFields.password}
        let status = 0

        const lookupOptions = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            credentials: 'include',
        }
        fetch(endpoint, lookupOptions).then(
            response =>  {
                status= response.status
                return response.json()
            }
        ).then(
            response => {
                if ([401, 403].indexOf(status) !== -1) {
                    setFormErrors({form: 'Usuario y/o contraseña no válidos'})
                } else {
                    const now = new Date()
                    const refreshTimeOut = moment(now).add(TOKEN_REFRESH_MINUTES-1, 'm').toDate()
                    sessionStorage.setItem('userToken', JSON.stringify(response))
                    sessionStorage.setItem('refreshTimeOut', refreshTimeOut)
                    props.history.push('/')
                    props.logBar()
                }

            }
        ).catch(
            error => {
                console.log("error", error)
            }
        )
    }

    return (
        <div className="container mt-5 pt-5 mx-auto col-lg-2 col-md-4">
            <form onSubmit={submitForm}>
                <h3 className="text-center mb-4">Iniciar Sesión</h3>
                <input type="text" name='username' id='username' className="form-control mb-3 border-0 rounded-pill "
                       value={formFields.username} placeholder='Usuario' onChange={handleInput}
                       required="required"/>
                <input type="password" name='password' id='password' className="form-control mb-3 border-0 rounded-pill"
                       onChange={handleInput}
                       required="required" placeholder='Contraseña'
                       value={formFields.password}
                />
                <button className='btn btn-primary mb-5 w-100 rounded-pill'>Acceder</button>
            </form>
            {formErrors.form !== undefined ?
                <span className="text-danger mt-5"> {formErrors.form}</span>
                : ""
            }

        </div>
    );
}

export default Login;
