import React, {useState} from 'react';
import './App.css';
import MenuBar from "./MenuBar/MenuBar";
import Login from './Auth/Login';
import Users from "./Auth/Users";
import Home from "./Home";
import Password from "./Auth/Password";
import Paciente from "./Pacientes/Paciente"
import Centros from "./Pacientes/Centros";
import Terapias from "./Terapias/Terapias";
import Servicios from "./Facturacion/Servicios";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {PrivateRoute} from "./Utils/PrivateRoute";
import GruposTarifa from "./Facturacion/GruposTarifa"
import Contratos from "./Contratos/Contratos"
import GruposMovimiento from "./Contabilidad/GruposMovimiento"
import Facturacion from "./Facturacion/Facturacion"
import Depositos from "./Contabilidad/Depositos"
import Programacion from "./Terapias/Programacion"
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import Movimientos from "./Contabilidad/Movimientos";
import Saldos from "./Contabilidad/Saldos";
import TerapiasView from "./Terapias/TerapiasView";
import Explotacion from "./Contabilidad/Explotacion"
import Recibidas from "./Contabilidad/Recibidas"
import Blog from "./Blog/Blog"

function App() {
    const handle = useFullScreenHandle();
    let {user} = sessionStorage.getItem('userToken') === null ? {user: {id: 0}} : JSON.parse(sessionStorage.getItem('userToken'));

    const [userState, setUserState] = useState(user)
    const [centro, setCentro] = useState({})

    function changeCentro(cent) {

        if (cent.id !== centro.id) {
            setCentro(cent)
        }
    }

    function logBar() {
        let {user} = sessionStorage.getItem('userToken') !== null && JSON.parse(sessionStorage.getItem('userToken'));
        setUserState(user)
    }

    return (
        <div className="container-fluid App px-0">
            <FullScreen handle={handle}>
                <BrowserRouter>
                    <MenuBar user={userState} setCentro={changeCentro} centro={centro} handle={action => {
                        if (action === "enter") {
                            handle.enter()
                        } else {
                            handle.exit()
                        }
                    }}/>
                    <Switch>
                        <Route exact path='/tera/login' render={(props) => (
                            <Login {...props} logBar={logBar}/>
                        )}/>

                        <Route path='/tera/password/:token' children={<Password/>}/>
                        <PrivateRoute exact path='/tera/paciente/detail' component={Paciente}/>
                        <PrivateRoute exact path='/tera/paciente/detail/:id' component={Paciente}/>
                        <PrivateRoute exact path='/tera/paciente/centro' component={Centros}/>
                        <PrivateRoute exact path='/tera/usuarios' component={Users}/>
                        <PrivateRoute exact path='/tera/facturacion/servicios' component={Servicios}
                                      privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/facturacion/facturacion' component={Facturacion}
                                      privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/contabilidad/movimientos' component={Movimientos}
                                      privateProps={{centro: centro, user: userState}}/>
                        <PrivateRoute exact path='/tera/contabilidad/recibidas' component={Recibidas}
                                      privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/terapias' component={Terapias} privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/programacion_terapias' component={Programacion}
                                      privateProps={{centro: centro.id}}/>
                        <PrivateRoute exact path='/tera/consulta_terapias' component={TerapiasView}
                                      privateProps={{centro: centro.id}}/>
                        <PrivateRoute exact path='/tera/contratos' component={Contratos}
                        />
                        <PrivateRoute exact path='/tera/contabilidad/explotacion' component={Explotacion}
                                      privateProps={{centro: centro.id}}/>
                        <PrivateRoute exact path='/tera/grupos_tarifa' component={GruposTarifa}
                                      privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/grupos_movimiento' component={GruposMovimiento}
                                      privateProps={{centro: centro}}/>
                        <PrivateRoute exact path='/tera/depositos' component={Depositos}
                                      privateProps={{centro: centro, user: userState}}/>
                        <PrivateRoute exact path='/tera/blog' component={Blog}
                                      />
                        <PrivateRoute exact path='/tera/contabilidad/saldos' component={Saldos}
                                      privateProps={{centro: centro, user: userState}}/>
                        <PrivateRoute component={Home} privateProps={{centro: centro}}/>
                    </Switch>
                </BrowserRouter>
            </FullScreen>
        </div>
    );
}

export default App;
