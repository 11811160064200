import React, {useState, useEffect} from 'react';
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";


function DepositosInline(props) {
    const [deposito, setDeposito] = useState(props.deposito)
    const [userList, setUserList] = useState([])
    const [centroList, setCentroList] = useState([])

    useEffect(() => {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/centro/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    let lista = response.filter(centro => {
                        return centro.personal.map(value => value.user).includes(props.user.id)
                    })
                    setCentroList(lista)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setUserList(response.filter(profile => profile.user.is_active).map((profile) =>
                        ({id: profile.user.id, name: profile.user.username})
                    ))
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        setDeposito(props.deposito)
    }, [props])

    function change_deposito(event) {
        let {name, value, checked} = event.target
        if (name === 'registro') {
            value = checked
        }
        setDeposito({...deposito, [name]: value})
        save_deposito({...deposito, [name]: value})
    }

    function save_deposito(deposito) {
        const data = {...deposito}
        delete data.id
        let ok = true
        if (deposito.nombre !== "") {
            if (deposito.id === "") {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/contabilidad/depositos/'
                    const lookupOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        body: JSON.stringify(data)
                    }

                    fetch(endpoint, lookupOptions).then(
                        response => {
                            ok = response.ok
                            return response.json()
                        }
                    ).then(
                        response => {
                            if (ok) {
                                setDeposito(response)
                                if (props.ultimo) {
                                    props.alta()
                                }
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            } else {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/contabilidad/depositos/detail/' + deposito.id + '/'
                    const lookupOptions = {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        body: JSON.stringify(data)
                    }

                    fetch(endpoint, lookupOptions).then(
                        response => {
                            ok = response.ok
                            return response.json()
                        }
                    ).then(
                        response => {
                            if (ok) {
                                setDeposito(response)
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            }
        }
    }

    function handle_input(event) {
        let {name, value} = event.target
        setDeposito(previous =>
            ({...previous, [name]: value}))

    }

    function handle_accesos(value, object) {
        save_deposito({...deposito, usuarios: value.map(usuario => ({deposito: deposito.id, usuario: usuario}))})
        setDeposito(prev => ({...prev, usuarios: value.map(usuario => ({deposito: deposito.id, usuario: usuario}))}))
    }

    function handle_centro(value, object) {
        if (value===" ") {value=""}
        save_deposito({...deposito, centro: value})
        setDeposito(prev => ({...prev, centro: value}))
    }

    return (
        <tr>

            <td>
                <input type='text'
                       className={` bg-light w-100 form-control`}
                       name="nombre"
                       value={deposito.nombre} onChange={handle_input} onBlur={change_deposito}/>
            </td>
            <td width="15%">
                <input type='text'
                       className={` bg-light w-100 form-control`}
                       name="cc"
                       value={deposito.cc} onChange={handle_input} onBlur={change_deposito}/>
            </td>
            <td width="12%">{centroList.length > 0 && <SelectSearch
                className="select-search"
                value={deposito.centro}
                options={[{name:"Todos", value:" "}, ...centroList.map(cent =>
                    ({name: cent.nombre, value: cent.id})
                )]}
                search
                printOptions="on-focus"
                placeholder="Seleccione Centro"
                onChange={handle_centro}
            />}</td>
            <td>{userList.length > 0 &&
            <SelectSearch
                className="select-search"
                value={deposito.usuarios.map(usuario => usuario.usuario)}
                options={userList.map(user =>
                    ({name: user.name, value: user.id})
                )}
                multiple
                search
                printOptions="on-focus"
                placeholder="Seleccione usuarios"
                onChange={handle_accesos}
            />
            }</td>
            <td className='text-center'><input type="checkbox" name="registro"
                                               checked={deposito.registro} onChange={change_deposito}/></td>
        </tr>
    )
}

export default DepositosInline;

