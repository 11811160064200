import React, {useState, useEffect} from 'react'
import {APIPATH} from "../settings";
import {useDrag} from 'react-dnd'
import {ItemTypes} from "./ItemTypes";


function MedicamentoCompra(props) {
    const [cantidadUso, setCantidadUso] = useState(0)
    const [{isDragging}, drag] = useDrag({
        item: {id: props.medicamento.id, type: ItemTypes.USO},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult && dropResult.name === 'trash') {
                props.deleteUso(item.id)
            }

        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        setCantidadUso(props.medicamento.cantidad_uso)
    },[props])

    return (
        <div
            className={`row mx-3 mb-2 border ${props.medicamento.dias_uso > 4 ? 'bg-white text-primary' : props.medicamento.dias_uso < 1 ? 'bg-danger text-white' : 'bg-no-ultima text-secondary'}`}>
            <img ref={drag} className={`img-fluid col-3 px-0 drag-item ${isDragging && 'img-dragging'}`} alt=""
                 src={`${props.medicamento.foto !== "" ? props.medicamento.foto : APIPATH + "static/media/cajamedicamentos.jpg"}`}/>
            <div className="col-7 "> {props.medicamento.nombre}</div>
            <div className="col-2 ">
                <div className="row">
                    {props.medicamento.dias_uso} Dia{props.medicamento.dias_uso > 1 && 's'} Dis.
                </div>
                <div className="form-group row">

                    <input type="text" className="col-5 px-1 text-small" id={`cantidad_${props.medicamento.id}`}
                           value={cantidadUso}
                           name="cantidad_uso" onChange={(e) => setCantidadUso(e.target.value)}
                           onBlur={()=>{props.change(props.medicamento.id, cantidadUso)}}/>
                    <label htmlFor={`cantidad_${props.medicamento.id}`} className="col-6">Ud.</label>
                </div>
            </div>
        </div>
    )
}

export default MedicamentoCompra