import React from 'react'
import {APIPATH} from "../settings";
import {useDrop} from 'react-dnd'
import {ItemTypes} from "./ItemTypes";
import MedicamentoCompra from "./MedicamentoCompra";
import MedicamentoUso from "./MedicamentoUso"

function ControlPaciente(props) {

    const [{canDropMedicacion, isOverMedicacion}, dropMedicacion] = useDrop({
        accept: ItemTypes.MEDICAMENTO,
        drop: () => ({name: 'compra'}),
        collect: (monitor) => ({
            isOverMedicacion: monitor.isOver(),
            canDropMedicacion: monitor.canDrop(),
        }),
    });

    const isActiveMedicacion = canDropMedicacion && isOverMedicacion;

    const [{canDropTrash, isOverTrash}, dropTrash] = useDrop({
        accept: [ItemTypes.COMPRADO, ItemTypes.USO],
        drop: () => ({name: 'trash'}),
        collect: (monitor) => ({
            isOverTrash: monitor.isOver(),
            canDropTrash: monitor.canDrop(),
        }),
    });

     const [{canDropCompra, isOverCompra}, dropCompra] = useDrop({
        accept: ItemTypes.COMPRADO,
        drop: () => ({name: 'uso'}),
        collect: (monitor) => ({
            isOverCompra: monitor.isOver(),
            canDropCompra: monitor.canDrop(),
        }),
    });

    const isActiveCompra = canDropCompra && isOverCompra;


    return (
        <div  style={{position: "relative", overflow: 'hidden'}}>
            <div ref={dropCompra} style={{
                backgroundImage: 'url(' + APIPATH + 'static/media/dispensacion.png)',
                backgroundSize: 'cover',
                height: "360px",
                width: '100%',
                marginBottom: "30px",
                overflowY: "auto",
                overflowX: "hide",
            }}>
            <h5 className="pt-2 ml-3 mb-3 text-primary">
                    {isActiveCompra ? 'Libere Medicamento' : canDropCompra? 'Libere aquí para Comenzar el uso' : 'Area de dispensación'}
                {props.warningMed > 0 &&
                <span className="badge ml-2 badge-pill badge-warning">{props.warningMed}</span>
                }
                {props.alertMed > 0 &&
                <span className="badge ml-2 badge-pill badge-danger ">{props.alertMed}</span>
                }
            </h5>
                {props.listUso.map(med => (
                    <MedicamentoUso
                        key={med.id}
                        medicamento={med}
                        deleteUso={props.deleteUso}
                        change={props.change}
                    />
                ))}

            </div>
            <div ref={dropTrash} className={`trash-medicacion ${canDropTrash && 'show-trash'}`}>
                <svg width="10em" height="10em" viewBox="0 0 16 16" className="bi bi-trash2-fill" fill={`${isOverTrash ? "#107c05" : "#6bc9ee"}`}
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.037 3.225l1.684 10.104A2 2 0 0 0 5.694 15h4.612a2 2 0 0 0 1.973-1.671l1.684-10.104C13.627 4.224 11.085 5 8 5c-3.086 0-5.627-.776-5.963-1.775z"/>
                    <path fillRule="evenodd"
                          d="M12.9 3c-.18-.14-.497-.307-.974-.466C10.967 2.214 9.58 2 8 2s-2.968.215-3.926.534c-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466zM8 5c3.314 0 6-.895 6-2s-2.686-2-6-2-6 .895-6 2 2.686 2 6 2z"/>
                </svg>
            </div>


            <div ref={dropMedicacion} style={{
                backgroundImage: 'url(' + APIPATH + 'static/media/cobro.png' +
                    ')',
                backgroundSize: 'cover',
                width: "100%",
                height: "360px",
                overflowY: "auto",
                overflowX: "hide",

            }}>
                <h5 className="pt-3 ml-3 mb-4 text-primary">{isActiveMedicacion ? 'Libere Medicamento' : canDropMedicacion ? 'Libere aquí para comprar' : 'Area de compras'}</h5>
                {props.listCompra.map(med => (
                    <MedicamentoCompra
                        key={med.id}
                        medicamento={med}
                        deleteCompra={props.deleteCompra}
                        addUso={props.addUso}
                    />
                ))}
            </div>
        </div>
    )

}

export default ControlPaciente;