import React, {useState, useEffect} from 'react';
import Calendario from "./Calendario";
import Terapia from "./Terapia";
import {APIPATH} from "../settings";
import {authHeaders} from "../Utils/authUtils";


function Terapias(props) {
    const nuevaTerapia = {
        id: "",
        nombre: "",
        asistentes: [],
        fecha: "",
        comentarios: "",
        programacion: "",
        centro: "",
        terapeuta: ""
    }
    const [centro, setCentro] = useState({})
    const [calendarioKey, setCalendarioKey] = useState(1)
    const [terapia, setTerapia] = useState(nuevaTerapia)

    const [maxTerapia, setMaxTerapia] = useState(false)

    useEffect(() => {
        setCentro(props.centro)
        setCalendarioKey(prev => prev + 1)
        setTerapia(nuevaTerapia)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function setProgramacion(programacion, fecha) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/terapia/programacionDia/' + programacion + '/?fecha=' + fecha.substr(0, 19)
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setTerapia(response)
                    setCalendarioKey(prev => prev + 1)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function readTerapia(terapia) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/detail/' + terapia + "/"
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setTerapia({...response, fecha: response.fecha.substr(0, 16)})
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    return (
        <div className="container-fluid">
            {centro.id !== undefined && !maxTerapia &&
            <Calendario
                key={calendarioKey}
                centro={centro}
                setProgramacion={setProgramacion}
                setTerapia={readTerapia}
            />}
            {centro.id !== undefined &&
            <Terapia
                terapia={terapia}
                centro={centro}
                max={maxTerapia}
                setMax={() => {
                    setMaxTerapia(prev => !prev)
                }}
            />}
        </div>
    )
}

export default Terapias;