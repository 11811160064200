import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import GruposTarifaInline from './GruposTarifaInline'

// import './App.css';


function GruposTarifa() {
    const [tarifas, setTarifas] = useState([])

    function loadTarifas() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/tarifas/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setTarifas(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    useEffect(() => {
            loadTarifas();
        }, []
    );

    return (
        <div className="  overflow-auto">

            <div className="row bg-white w-100 m-0">
                      <h3 className="col-11 text-primary">Grupos de Tarificación</h3>
            </div>
            <table className="bg-white table">
                <thead>

                <tr className={"text-primary"}>
                    <th className={"text-center"}>
                        Concepto
                    </th>
                    <th/>
                    <th className={"text-center"}>Activo</th>

                </tr>
                </thead>
                <tbody>
                {tarifas != null && tarifas.map(tarifa =>
                    <GruposTarifaInline
                        key={tarifa.id}
                        ultimo={false}
                        tarifa={{id: tarifa.id, nombre: tarifa.nombre, activa: tarifa.activa}}
                    />
                )}
                <GruposTarifaInline
                    key={tarifas.length}
                    ultimo={true}
                    alta={loadTarifas}
                    tarifa={{id: "", nombre: "", activa: false}}
                />
                </tbody>
            </table>

        </div>
    );
}

export default GruposTarifa;
