import React, {useState, useEffect} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import SelectSearch from 'react-select-search';
import "../Utils/select.css"
import "./Paciente.css"
import {Editor} from "@tinymce/tinymce-react";

function Centros(props) {
    const [centroList, setCentroList] = useState([])
    const [userList, setUserList] = useState([])
    const [depositos, setDepositos] = useState([])
    const [centro, setCentro] = useState({
        id: "",
        nombre: "",
        direccion1: "",
        direccion2: "",
        cp: "",
        localidad: "",
        provincia: "",
        personal: [],
        ingreso_factura: "",
        ingreso_nofactura: "",
        normas: "",
        aviso: false,
    })
    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/centro/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setCentroList(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contabilidad/depositos/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setDepositos(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setUserList(response.filter(profile => profile.user.is_active).map((profile) =>
                        ({id: profile.user.id, name: profile.user.username})
                    ))
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [])

    function newCentro() {
        setCentro({
            id: "",
            nombre: "",
            direccion1: "",
            direccion2: "",
            cp: "",
            localidad: "",
            provincia: "",
            personal: [],
            ingreso_factura: "",
            ingreso_nofactura: "",
            normas: "",
            aviso: false,
        })
    }

    function handle_centro(value, object) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/centro/detail/' + value + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    const centr = {...response}
                    Object.keys(centr).forEach(key => {
                        if (centr[key] === null) {
                            centr[key] = ""
                        }
                    })

                    setCentro(centr)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        setDisabled(false)
    }

    function handle_input(event) {

        let {name, value, checked} = event.target
        if (['aviso', ].includes(name)) {
            value = checked
        }
        setCentro(previous => {
            return {...previous, [name]: value}
        })

    }

    function submitForm(e) {
        e.preventDefault()
        if (centro.id === "") {
            const data = centro
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/centro/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setCentro(response)
                        setCentroList(prev => ([...prev, response]))
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        } else {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/centro/detail/' + centro.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(centro)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setCentro(response)
                        setCentroList(prev => ([...prev.filter(item => item.id !== response.id), response]))
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        }
        setDisabled(true)
    }

    function handle_personal(value, object) {
        setCentro(prevState => ({
            ...prevState, personal: value.map(item => (
                {centro: centro.id, user: item}
            ))
        }))
    }

    function handle_ingreso_factura(value, object) {
        setCentro(prevState => ({
            ...prevState, ingreso_factura: value

        }))
    }

    function handle_ingreso_nofactura(value, object) {
        setCentro(prevState => ({
            ...prevState, ingreso_nofactura: value

        }))
    }

    function handle_editor(content, editor) {
        setCentro(previous => ({...previous, normas: content}))
    }

    return (
        <div className="container ml-5 row">
            <div className="col-6 border-right ml-3">
                <div className="row bg-primary text-white">
                    <div className="col-2">
                        <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-primary text-white"
                                onClick={() => {
                                    setDisabled(false)
                                    newCentro()
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                    </div>
                    <h5 className="pt-1"> Centros </h5>

                </div>
                <SelectSearch
                    className="select-search mt-5 mb-5"
                    value={centro.id}
                    options={centroList.map(cent =>
                        ({name: cent.nombre, value: cent.id})
                    )}
                    search
                    printOptions="on-focus"
                    placeholder="Seleccione Centro"
                    onChange={handle_centro}
                />
                <hr/>
                <form onSubmit={submitForm}>
                    <div className="form-group mt-3 mb-3 ">
                        <label htmlFor="nombre">Nombre</label>
                        <input type='text' id="nombre" required
                               className={` form-control `} disabled={disabled}
                               name="nombre" value={centro.nombre} onChange={handle_input}/>
                    </div>
                    <div className="form-group mt-5 ">
                        <label htmlFor="direccion1">Dirección</label>
                        <input type='text' id="direccion1"
                               className={`w-100 form-control `} disabled={disabled}
                               name="direccion1" value={centro.direccion1} onChange={handle_input}/>
                        <input type='text' id="direccion2"
                               className={`mt-1 w-100 form-control `} disabled={disabled}
                               name="direccion2" value={centro.direccion2} onChange={handle_input}/>
                    </div>
                    <div className="row">
                        <div className="form-group col-4">
                            <label htmlFor="localidad">Localidad</label>
                            <input type='text' id="localidad"
                                   className={` w-100 form-control `} disabled={disabled}
                                   name="localidad" value={centro.localidad} onChange={handle_input}/>
                        </div>

                        <div className="form-group col-3">
                            <label htmlFor="cp">Cod.Postal</label>
                            <input type='text' id="cp"
                                   className={` w-100 form-control `} disabled={disabled}
                                   name="cp" value={centro.cp} onChange={handle_input}/>
                        </div>

                        <div className="form-group col-5 ml-auto pl-0">
                            <label htmlFor="provincia">Provincia</label>
                            <input type='text' id="provincia"
                                   className={` w-100 form-control `} disabled={disabled}
                                   name="provincia" value={centro.provincia} onChange={handle_input}/>
                        </div>
                    </div>
                    <div className="row mt-3 ">
                        <span className="col-2">Personal</span>
                        {userList.length > 0 &&
                        <SelectSearch
                            className="select-search col-10  "
                            value={centro.personal.map(value => value.user)}
                            options={userList.map(user =>
                                ({name: user.name, value: user.id})
                            )}
                            multiple
                            search
                            disabled={disabled}
                            printOptions="on-focus"
                            placeholder="Seleccione usuarios"
                            onChange={handle_personal}
                        />
                        }
                    </div>
                    <div className="row mt-5">
                        <span className="col-4">Ingreso Facturas</span>
                        {depositos.length > 0 &&
                        <SelectSearch
                            className="select-search col-7"
                            value={centro.ingreso_factura}
                            options={depositos.map(deposito =>
                                ({name: deposito.nombre, value: deposito.id})
                            )}
                            search
                            disabled={disabled}
                            printOptions="on-focus"
                            placeholder="Seleccione Depósito"
                            onChange={handle_ingreso_factura}
                        />
                        }
                    </div>
                    <div className="row mt-3 ">
                        <span className="col-4">Ingreso NO Facturas</span>
                        {depositos.length > 0 &&
                        <SelectSearch
                            className="select-search col-7"
                            value={centro.ingreso_nofactura}
                            options={depositos.map(deposito =>
                                ({name: deposito.nombre, value: deposito.id})
                            )}
                            search
                            disabled={disabled}
                            printOptions="on-focus"
                            placeholder="Seleccione Depósito"
                            onChange={handle_ingreso_nofactura}
                        />
                        }
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 mt-4">
                            <button type="submit" className='btn btn-primary mb-2 w-100 rounded-pill mt-5'
                                    disabled={disabled}>Guardar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-4 mt-5">
                <span className="mb-2 mt-5">Normas</span>
                <div className="mt-2">
                    <Editor id='normas'
                            name='normas'
                            init={{
                                placeholder: "Normas",
                                menubar: false,
                                branding: false,
                                plugins: [
                                    'advlist autolink lists link image  print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help  '
                                ],
                                language: 'es_ES',
                                width: 800,
                                height:500,
                                elementpath: false,
                                toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                            }}
                            value={centro.normas}
                            onEditorChange={handle_editor}
                            disabled={disabled}

                    />
                </div>

                <input type="checkbox" className="mt-1 mr-2" name="aviso" checked={centro.aviso} onChange={handle_input} disabled={disabled} />
                 <label className="mt-3">Aviso</label>
            </div>

        </div>
    )
}

export default Centros