import React, {useState, useEffect} from "react";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import "./Contratos.css"
import moment from "moment";
import ContratoPacienteInline from "./ContratoPacienteInline";

function Contratos(props) {
    const [contratos, setContratos] = useState([])
    const [contactos, setContactos] = useState([])
    const [contratosPaciente, setContratosPaciente] = useState([])
    const nuevoContratoPaciente = {
        id: "",
        contrato: "",
        paciente: props.paciente.id,
        fecha: moment(new Date()).format('YYYY-MM-DD'),
        importe: 0,
        suplidos: 0,
    }

    useEffect(() => {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contratos/?activo=True'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setContratos(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
         authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/contactos/?q=' + props.paciente.alias
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setContactos(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        loadContratos()
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function loadContratos() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contratos/paciente/?paciente=' + props.paciente.id
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setContratosPaciente(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }



    return (
        <div className="row mx-3">
            <div className="col-12 bg-white m-0">
                <div className="row">
                    {/*<button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"*/}
                    {/*        onClick={() => {*/}
                    {/*            setContrato(nuevoContrato)*/}
                    {/*        }}>*/}
                    {/*    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"*/}
                    {/*         fill="currentColor"*/}
                    {/*         xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path fillRule="evenodd"*/}
                    {/*              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>*/}
                    {/*        <path fillRule="evenodd"*/}
                    {/*              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>*/}
                    {/*    </svg>*/}
                    {/*</button>*/}
                    <h3 className="col-4 text-primary">Contratos</h3>
                </div>
            </div>
            <div className="col-12 bg-white contrato-area">
                <table className="table">
                    <thead>
                    <tr>
                        <th>
                            Contrato
                        </th>
                        <th>
                            Fecha
                        </th>
                        <th>
                            Contacto
                        </th>
                        <th>
                            Importe
                        </th>
                        <th>
                            Suplidos
                        </th>
                        <th>
                        </th>
                        <th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {contratosPaciente.map((contrato, index) =>
                        <ContratoPacienteInline
                            key={index}
                          contrato = {contrato}
                          contratos={contratos}
                          contactos = {contactos}
                            reload={loadContratos}
                        />
                    )}
                    <ContratoPacienteInline
                          key={contratosPaciente.length}
                          contrato = {nuevoContratoPaciente}
                          contratos={contratos}
                          contactos = {contactos}
                          reload={loadContratos}
                        />
                    </tbody>
                </table>
            </div>

        </div>
    )

}

export default Contratos