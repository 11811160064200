import React, {useState, useEffect} from 'react';
import Prescripciones from "./Prescripciones";
import Prescripcion from "./Prescripcion";
import ControlPaciente from "./ControlPaciente";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";


function Medicacion(props) {
    const [selectedPrescripcion, setSelectedPrescripcion] = useState({
        id: "",
        medicamentos: [],
        adjuntos: [],
        doctor: "",
        fecha: "",
        notas: "",
        paciente: "",
        activa: false,
    })

    const [lastPrescripcion, setLastPrescripcion] = useState({
        id: "",
        medicamentos: [],
        adjuntos: [],
        doctor: "",
        fecha: "",
        notas: "",
        paciente: "",
        activa: false,
    })

    const [updatePrescripciones, setUpdatePrescripciones] = useState(false)

    const [listCompra, setListCompra] = useState([])
    const [listUso, setListUso] = useState([])
    const [alertMed, setAlertMed] = useState(0)
    const [warningMed, setWarningMed] = useState(0)


    function setDetail(prescripcion) {
        setSelectedPrescripcion(prescripcion)
        if (lastPrescripcion === undefined) {
            setLastPrescripcion(prescripcion)
        }
    }


    function refreshPrescripciones() {
        loadUso()
        setUpdatePrescripciones(previous => !previous)
    }

    function addCompra(id) {

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/compra/'
            const data = {
                action: "add",
                paciente: props.paciente,
                medicamento: id,

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListCompra(response.listStock)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    function deleteCompra(id) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/compra/'
            const data = {
                action: "delete",
                paciente: props.paciente,
                medicamento: id,

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListCompra(response.listStock)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    useEffect(() => {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/compra/'
            const data = {
                action: "",
                paciente: props.paciente,
                medicamento: "",

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListCompra(response.listStock)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        loadUso()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function loadUso() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/dispensa/'
            const data = {
                action: "",
                paciente: props.paciente,
                medicamento: "",

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListUso(response.listUso)
                    setAlertMed(response.alert)
                    setWarningMed(response.warning)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function deleteUso(id) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/dispensa/'
            const data = {
                action: "delete",
                paciente: props.paciente,
                medicamento: id,

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListUso(response.listUso)
                    setAlertMed(response.alert)
                    setWarningMed(response.warning)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function addUso(id) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/dispensa/'
            const data = {
                action: "add",
                paciente: props.paciente,
                medicamento: id,

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListUso(response.listUso)
                    setAlertMed(response.alert)
                    setWarningMed(response.warning)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        deleteCompra(id)
    }

    function changeUso(id, value) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/dispensa/'
            const data = {
                action: "change",
                paciente: props.paciente,
                medicamento: id,
                cantidad: value,

            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setListUso(response.listUso)
                    setAlertMed(response.alert)
                    setWarningMed(response.warning)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        deleteCompra(id)
    }

    return (
        <div className="row">
            <DndProvider backend={HTML5Backend}>
                <div className="col-4 mt-4 border-right">
                    <Prescripciones
                        alias={props.alias}
                        prescripcionDetail={setDetail}
                        update={updatePrescripciones}
                        setLast={(prescripcion) => {
                            setLastPrescripcion(prescripcion)
                        }}
                    />
                </div>
                <div className="col-4 mt-4">
                    {selectedPrescripcion !== undefined &&
                    <Prescripcion
                        prescripcion={selectedPrescripcion}
                        paciente={props.paciente}
                        refreshPrescripciones={refreshPrescripciones}
                        prescripcionDetail={setDetail}
                        addCompra={addCompra}
                        setLast={(prescripcion) => {
                            setLastPrescripcion(prescripcion)
                        }}
                        last={selectedPrescripcion.id === lastPrescripcion.id}
                    />}
                </div>
                <div className="col-4 mt-4">
                    {props.control_medicacion && selectedPrescripcion !== undefined  &&
                    <ControlPaciente
                        paciente={props.paciente}
                        update={updatePrescripciones}
                        listCompra={listCompra}
                        listUso={listUso}
                        deleteCompra={deleteCompra}
                        deleteUso={deleteUso}
                        addUso={addUso}
                        change={changeUso}
                        alertMed ={alertMed}
                        warningMed={warningMed}
                    />}
                </div>

            </DndProvider>
        </div>

    )


}

export default Medicacion;