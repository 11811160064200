import React, {useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";

// import './App.css';

function CreateContacto(props) {
    const [formFields, setFormFields] = useState({
        nombre: "",
        relacion: "",
        telefono: "",
        email: ""
    })
    const [formErrors, setFormErrors] = useState({initial: true})
    const mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    function handleInput(event) {
        const {name, value} = event.target
        const validate_data = {...formFields, [name]: value}
        setFormFields(prevState => ({...prevState, [name]: value}))
        let errors = {}
        if (validate_data.nombre === "") {
            errors = {...errors, nombre: true}
        }
        if (validate_data.email !== "" &&
            !mailformat.test(String(validate_data.email).toLowerCase())) {
            errors = {...errors, email: true}
        }
        setFormErrors(errors)


    }

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        setFormFields({
            nombre: "",
            relacion: "",
            email: "",
            telefono: ""
        })
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }

    function add_contacto(event) {
        event.preventDefault();
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/contactos/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify({...formFields, paciente: props.paciente})
            }
            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            props.addContacto(response)
                            close_modal()
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }


    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Nuevo Contacto</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="modal-body">

                        <input type="text"
                               className={`${formErrors.nombre && 'border border-danger'} col-8 form-control mt-4 mx-auto`}
                               name="nombre"
                               placeholder="Nombre" value={formFields.nombre} onChange={handleInput}/>
                        <input type="text" className="col-8 form-control mt-2 mb-4 mx-auto" name="relacion"
                               placeholder="Relacion" value={formFields.relacion} onChange={handleInput}/>
                        <input type="text" className="col-8 form-control mt-2 mb-4 mx-auto" name="telefono"
                               placeholder="Teléfono" value={formFields.telefono} onChange={handleInput}/>
                        <input type="email"
                               className={`${formErrors.email && 'border border-danger'} col-8 form-control mt-2 mb-4 mx-auto`}
                               name="email"
                               placeholder="Correo Electrónico" value={formFields.email} onChange={handleInput}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>
                        <button type="button" className="btn btn-primary" disabled={Object.keys(formErrors).length > 0}
                                onClick={add_contacto}>Crear
                        </button>
                    </div>

                </div>
            </div>
        </div>


    )
}

export default CreateContacto;