import React, {useState} from "react";

import 'whatwg-fetch';

import "./Utils/select.css"
import "./Pacientes/Paciente.css"
import Pacientes from "./Pacientes/Pacientes";
import MedicamentosCentro from "./Pacientes/MedicamentosCentro"
import TerapiasView from "./Terapias/TerapiasView";
import Notas from "./Notas/Notas"
import Pautas from "./Notas/Pautas"


function Home(props) {
    const minimos = {
        pacientes: false,
        medicamentos: false,
        terapias: false,
        notas: false,
        pautas: false,
    }
    const [maximizar, setMaximizar] = useState(minimos)
    const [is_max, setIs_max] = useState(false)
    let userAccess = sessionStorage.getItem('userToken') === null ? {user: {id: 0}} : JSON.parse(sessionStorage.getItem('userToken'));

    function max_area(area, value) {
        setMaximizar({...minimos, [area]: value})
        setIs_max((value))
    }

    return (
        <div className="row px-5">
            {props.centro.id !== undefined &&
            <div
                className={`${!is_max ?
                    userAccess.has_historial ?
                        'col-12 col-md-6 col-xl-4'
                        :
                        'col-12 col-md-6'
                    :
                    maximizar.pacientes || maximizar.notas ? 'col-12' : 'd-none'} border-right`}>
                <div className={`${is_max && !maximizar.pacientes && 'd-none'} border-right`}>
                    <Pacientes
                        centro={props.centro.id}
                        maximizar={max_area}
                        foto_width={!is_max ?
                            userAccess.has_historial ?
                                '16%'
                                :
                                '10%'
                            :
                            '10%'}
                    />
                </div>
                <div className={`${is_max && !maximizar.notas && 'd-none'} border-right`}>
                    <Notas
                        centro={props.centro.id}
                        maximizar={max_area}
                        foto_width={!is_max ?
                            userAccess.has_historial ?
                                'col-8'
                                :
                                'col-6'
                            :
                            'col-8'}
                    />
                </div>
            </div>}
            {props.centro.id !== undefined &&
            <div
                className={`${!is_max ?
                    userAccess.has_historial ?
                        'col-12 col-md-6 col-xl-4'
                        :
                        'col-12 col-md-6'
                    :
                    maximizar.medicamentos || maximizar.pautas ?
                        'col-12' :
                        'd-none'}
                         border-right`}>
                <div className={`${is_max && !maximizar.medicamentos && 'd-none'} border-right`}>
                    <MedicamentosCentro
                        centro={props.centro.id}
                        maximizar={max_area}
                    />
                </div>
                <div className={`${is_max && !maximizar.pautas && 'd-none'} border-right`}>
                    <Pautas
                        centro={props.centro.id}
                        maximizar={max_area}
                        foto_width={!is_max ?
                            userAccess.has_historial ?
                                'col-11'
                                :
                                'col-6'
                            :
                            'col-8'}
                    />
                </div>
            </div>}
            {props.centro.id !== undefined && userAccess.has_historial &&
            <div
                className={`${!is_max ? 'col-4 d-none d-xl-block' : maximizar.terapias ? 'col-12' : 'd-none'} border-right`}>
                <TerapiasView
                    centro={props.centro.id}
                    maximizar={max_area}
                />
            </div>}

        </div>
    )
}

export default Home