import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import GruposMovimientoInline from './GruposMovimientoInline'

// import './App.css';


function GruposMovimiento() {
    const [grupos, setGrupos] = useState([])

    function loadGrupos() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contabilidad/grupos/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setGrupos(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    useEffect(() => {
            loadGrupos();
        }, []
    );

    return (
        <div className="  overflow-auto">

            <div className="row bg-white w-100 m-0">
                <div className="col-1">

                </div>
                <h3 className="col-11 text-primary">Clases de Movimiento</h3>
            </div>
            <table className="bg-white table">
                <thead>

                <tr className={"text-primary"}>
                    <th className={"text-center"}>
                        Concepto
                    </th>
                    <th/>
                    <th className={"text-center"}>Activo</th>

                </tr>
                </thead>
                <tbody>
                {grupos != null && grupos.map(grupo =>
                    <GruposMovimientoInline
                        key={grupo.id}
                        ultimo={false}
                        grupo={{id: grupo.id, nombre: grupo.nombre, activo: grupo.activo}}
                    />
                )}
                <GruposMovimientoInline
                    key={grupos.length}
                    ultimo={true}
                    alta={loadGrupos}
                    grupo={{id: "", nombre: "", activo: false}}
                />
                </tbody>
            </table>

        </div>
    );
}

export default GruposMovimiento;
