import React, {useEffect, useState} from 'react'
import Fichero from "../Utils/Fichero";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import "./Terapias.css"
import {useMountedState} from "react-use";

import TerapiaFileInline from "./TerapiaFileInline";

function TerapiaFiles(props) {
    const [fileList, setFileList] = useState([])
    const isMounted = useMountedState();
    const [disabled, setDisabled] = useState(true)
    const [numFiles, setNumFiles] = useState(0)

    function delete_file(terapiaFile) {
        if (isMounted()) {
            setFileList(prevState => prevState.filter(tfile => tfile.id !== terapiaFile.id))
            setNumFiles(prev => prev -1)
        }
    }

    function uploadFile(imageBase64, terapiaFile) {
        let form_data = new FormData()
        form_data.append('terapia', props.terapia.id)
        form_data.append('tipo', 'terapia')
        form_data.append('fichero', terapiaFile)
        form_data.append('descripcion', terapiaFile.name)


        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/adjuntos/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                    ...authHeader
                },
                credentials: 'include',
                // body: JSON.stringify(paciente)
                body: form_data

            }

            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            if (isMounted()) {
                                setFileList(prevState => [response, ...prevState])
                                setNumFiles(prev => prev + 1)
                            }
                        })
                    } else {
                        response.json().then(response=> {console.log(response)})
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    useEffect(() => {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/adjuntos/?terapia=' + props.terapia.id
                    + '&tipo=terapia'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        if (isMounted()) {
                            setFileList(response)
                            setNumFiles(response.length)
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }, [props, isMounted]
    );


    return (
        <div className="">
            <div className="row subtitulo w-100 m-0 p-2 ">
                {!disabled &&
                <button type="button" className=" mr-1 mt-0 pt-1 subtitulo border-0"
                        data-toggle="modal"
                        data-target="#ficheroIdModal">
                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                         fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                        <path fillRule="evenodd"
                              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                    </svg>
                </button>
                }
                <h6 className="text-white pt-2 ml-5">Ficheros Adjuntos </h6>{numFiles > 0 && <span className="badge badge-pill badge-secondary mb-3">{numFiles}</span>}

                {!disabled ?
                    <button type="button" className="border-0 titulos-prescripcion ml-auto" onClick={() => {
                        setDisabled(true)
                        props.expanded(false)
                    }}>
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash " fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                        </svg>
                    </button> :
                    <button type="button" className="border-0 titulos-prescripcion ml-auto" onClick={() => {
                        setDisabled(false)
                        props.expanded(true)
                    }}>
                        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                            <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                            <path
                                d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                        </svg>
                    </button>}
            </div>
            {!disabled &&
            <div className="mx-3">
                {fileList != null && fileList.map(terapiaFile =>
                    <TerapiaFileInline
                        key={terapiaFile.id}
                        terapiaFile={terapiaFile}
                        delete_file={delete_file}/>
                )}
            </div>}
            <Fichero
                modalId="ficheroIdModal"
                uploadFile={uploadFile}
            />
        </div>
    )
}

export default TerapiaFiles;