import React, {useState} from 'react';
import './MenuBar.css';
import {APIPATH} from "../settings";
import {Link} from "react-router-dom"
import SelectCentro from "../Utils/SelectCentro";
import {Modal} from "react-bootstrap";


function MenuBar(props) {
    let user = props.user
    const [fullScreen, setFullScreen] = useState(false)
    const [showNormas, setShowNormas] = useState(false)
    let userAccess = sessionStorage.getItem('userToken') === null ? {user: {id: 0}} : JSON.parse(sessionStorage.getItem('userToken'));

    function logout(event) {
        event.preventDefault()
        sessionStorage.removeItem('userToken')
        window.location.assign('/')
    }


    return (
        <div className="py-0 my-0">
            <nav className="navbar navbar-expand-lg navbar-light bg-light ">
                <span className="navbar-brand link-button py-0 px-0">
                    <Link to="/"><img src={`${APIPATH}static/media/metodo_alpha_trans.jpg`}
                                      className="img-fluid  logo-img"
                                      alt="ALPHA"/>
                     </Link>
                </span>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {userAccess.user.id !== 0 &&
                    <ul className="navbar-nav mr-auto">
                        {userAccess.has_historial &&
                        <li className="nav-item dropdown">

                        <span className="nav-link dropdown-toggle link-button mr-4" id="terapiasDD"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-people"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1h7.956a.274.274 0 0 0 .014-.002l.008-.002c-.002-.264-.167-1.03-.76-1.72C13.688 10.629 12.718 10 11 10c-1.717 0-2.687.63-3.24 1.276-.593.69-.759 1.457-.76 1.72a1.05 1.05 0 0 0 .022.004zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10c-1.668.02-2.615.64-3.16 1.276C1.163 11.97 1 12.739 1 13h3c0-1.045.323-2.086.92-3zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"/>
                            </svg>
                            <Link className="text-secondary" to="/tera/terapias"> Terapias </Link>
                        </span>
                            <div className="dropdown-menu" aria-labelledby="terapiasDD">
                                <Link className="dropdown-item link-button" to="/tera/consulta_terapias">Consulta</Link>
                                <Link to='/tera/programacion_terapias'
                                      className="dropdown-item link-button">Programacion</Link>
                                <Link className="dropdown-item link-button" to="/tera/terapias">Alta Terapias</Link>
                            </div>
                        </li>
                        }
                        {userAccess.is_contabilidad &&
                        <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle link-button mr-4" id="factDD"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-receipt"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd"
                                    d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
                              <path fillRule="evenodd"
                                    d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            Facturación
                        </span>
                            <div className="dropdown-menu" aria-labelledby="factDD">
                                <Link to='/tera/facturacion/servicios'
                                      className="dropdown-item link-button">Servicios</Link>
                                <Link to='/tera/facturacion/facturacion'
                                      className="dropdown-item link-button">Facturacion</Link>
                            </div>
                        </li>
                        }
                        <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle link-button mr-4" id="contabilidadDD"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-receipt"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd"
                                    d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z"/>
                              <path fillRule="evenodd"
                                    d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            Contabilidad
                        </span>
                            <div className="dropdown-menu" aria-labelledby="contabilidadDD">
                                {(userAccess.is_monitor || userAccess.is_contabilidad) &&
                                <Link to='/tera/contabilidad/movimientos'
                                      className="dropdown-item link-button">Movimientos</Link>
                                }
                                {(userAccess.is_contabilidad) &&
                                <Link to='/tera/contabilidad/saldos'
                                      className="dropdown-item link-button">Saldos</Link>
                                }
                                {(userAccess.is_contabilidad_all) &&
                                <Link to='/tera/contabilidad/explotacion'
                                      className="dropdown-item link-button">Cuenta Explotación</Link>
                                }
                                {(userAccess.is_contabilidad_all) &&
                                <Link to='/tera/contabilidad/recibidas'
                                      className="dropdown-item link-button">Facturas Recibidas</Link>
                                }

                            </div>
                        </li>
                        <li className="nav-item dropdown">
                        <span className="nav-link dropdown-toggle link-button" id="navbarDropdown"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-gear-fill mr-1 "
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 0 0-5.86 2.929 2.929 0 0 0 0 5.858z"/>
                            </svg>
                            Configuracion
                        </span>
                            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                {userAccess.is_manager &&
                                <div>
                                    <Link to='/tera/paciente/centro'
                                          className="dropdown-item link-button">Centros</Link>
                                    <Link to='/tera/usuarios' className="dropdown-item link-button">Usuarios</Link>
                                    <Link to='/tera/contratos' className="dropdown-item link-button">Contratos</Link>
                                    <span className="dropdown-item">Psiquiatras</span>
                                    <div className="dropdown-divider"></div>
                                    <Link to='/tera/grupos_tarifa' className="dropdown-item link-button">Grupos de
                                        Tarifa</Link>
                                    <Link to='/tera/grupos_movimiento' className="dropdown-item link-button">Clasificación
                                        de
                                        Movimientos</Link>
                                    <Link to='/tera/depositos' className="dropdown-item link-button">Banco y Caja</Link>
                                    <div className="dropdown-divider"></div>
                                    <Link to='/tera/blog' className="dropdown-item link-button">Blog</Link>
                                </div>
                                }
                            </div>
                        </li>

                    </ul>
                    }
                    {user.id !== 0 &&
                    <span className="nav-item ml-auto border-left select-0">
                    <SelectCentro
                        user={user}
                        setCentro={props.setCentro}
                    />
                       </span>
                    }
                    {user.id !== 0 &&
                    <button
                        className={`nav-item mt-0 link-button rounded-pill py-2 px-2 ml-3 border-0 ${props.centro.aviso ? 'bg-danger text-white' : 'bg-light'}`}
                        onClick={() => setShowNormas(true)}>
                        Normas {props.centro.aviso && '!!!'}
                    </button>

                    }
                    {user.id !== 0 ?
                        <span className="nav-item ml-2 bg-light border-left">
                                <button className="nav-item mt-0  border-0 bg-light" onClick={logout}>
                                    <svg className="bi bi-door-closed door" width="2.5em" height="2.5em"
                                         viewBox="0 0 16 16"
                                         fill="#28a745" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2zm1 0v13h8V2H4z"/>
                                        <path d="M7 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                        <path fillRule="evenodd"
                                              d="M1 15.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13a.5.5 0 0 1-.5-.5z"/>
                                    </svg>
                                    <span className="nav-item " href="{% url 'logout' %}"> {user.username} </span>
                                </button>
                            </span>
                        : ""}
                    {fullScreen ?
                        <button className="nav-item mt-0  ml-5 link-button border-0 " onClick={() => {
                            setFullScreen(false)
                            props.handle("exit")
                        }}>
                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </button>
                        : userAccess.user.id !== 0 &&
                        <button className="nav-item mt-0 ml-5 link-button border-0" onClick={() => {
                            setFullScreen(true)
                            props.handle("enter")
                        }}>
                            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                <path
                                    d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                            </svg>
                        </button>
                    }

                </div>


            </nav>
            <div className="shadow-separation"></div>

            <Modal show={showNormas} onHide={() => setShowNormas(false)} animation={false} size="xl">
                <Modal.Header closeButton>
                    <h5 className="modal-title w-100 text-center">Normas Generales Centro</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="ml-4" dangerouslySetInnerHTML={{__html: props.centro.normas}}/>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setShowNormas(false)}>Cancelar
                    </button>


                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default MenuBar;