import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import "./Facturacion.css"


function GeneracionFactura(props) {
    const [preFacturas, setPreFacturas] = useState([])
    const [checkAll, setCheckAll] = useState(false)

    useEffect(() => {
            api_generacion()
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function api_generacion() {
        if (props.centro.id !== undefined) {
            authHeaders().then(authHeader => {
                    let endpoint = APIPATH + 'api/facturacion/servicios/generacion/?centro=' + props.centro.id
                    if (props.paciente !== undefined && props.paciente !== 0) {
                        endpoint = endpoint + "&paciente=" + props.paciente
                    }
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        // body: JSON.stringify(data)
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => {
                            if (response.ok) {
                                response.json()
                                    .then(
                                        response => {
                                            setPreFacturas(response.servicios)
                                        }
                                    )
                            } else {
                                setPreFacturas([])
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                }
            )
        }
    }

    function select_factura(e) {
        const {checked, dataset} = e.target
        const factList = [...preFacturas]
        factList[dataset.index] = {...factList[dataset.index], select: checked}
        setPreFacturas(factList)
        setCheckAll(false)
    }

    function check_all(e) {
        const {checked} = e.target
        if (checked) {
            setCheckAll(true)
            setPreFacturas(prev => prev.map(fact => ({...fact, select: true})))
        } else {
            setCheckAll(false)
            setPreFacturas(prev => prev.map(fact => ({...fact, select: false})))
        }
    }

    function generar_facturas() {
        authHeaders().then(authHeader => {
                let endpoint = APIPATH + 'api/facturacion/crear/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(preFacturas.filter((factura => factura.select)))
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            props.refresh()
                        } else {
                            alert("Error generando Facturas")
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            }
        )
    }


    return (
        <div>
            <div className="row border-right bg-white pl-3 border-top">
                <div className="col-12 bg-white pt-2">
                    <div className="row">
                        <h5 className="col-4 ml-3 text-primary">Pendiente</h5>
                        <button type="button" className="btn ml-auto mr-4 mb-1 mt-0 btn-primary"
                                onClick={generar_facturas}> Generar Proforma
                        </button>
                    </div>
                </div>

                <div className='col-12 bg-white '>
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan="2">Paciente</th>
                            <th>Tarifa</th>
                            <th>Concepto</th>
                            <th>Importe</th>
                            <th><input type="checkbox"
                                       checked={checkAll}
                                       onChange={check_all}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {preFacturas.map((factura, index) => (
                            <tr key={index}>
                                <td width="12%">
                                    {factura.img !== null &&
                                    <img src={`${factura.img}`} alt="" className="img-fluid"/>}
                                </td>
                                <td>{factura.alias}</td>
                                <td>{factura.grupoTarifaNombre}</td>
                                <td>{factura.concepto}</td>
                                <td>{(factura.importe * (1 + factura.iva / 100)).toLocaleString('de-DE', {
                                    style: 'currency',
                                    currency: 'EUR'
                                })}</td>
                                <td><input type="checkbox" data-index={index}
                                           checked={factura.select !== undefined ? factura.select : false}
                                           onChange={select_factura}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default GeneracionFactura