import React from 'react'
import {APIPATH} from "../settings";
import {useDrag} from 'react-dnd'
import {ItemTypes} from "./ItemTypes";


function MedicamentoCompra(props) {

    const [{isDragging}, drag] = useDrag({
        item: {id: props.medicamento.id, type: ItemTypes.COMPRADO},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult && dropResult.name === 'trash') {
                props.deleteCompra(item.id)
            }
            if (item && dropResult && dropResult.name === 'uso') {
                props.addUso(item.id)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            className={`row mx-3 mb-2 border ${props.medicamento.ultima ? 'bg-white text-primary' : 'bg-no-ultima text-secondary'}`}>
            <img ref={drag} className={`img-fluid col-3 px-0 drag-item ${isDragging && 'img-dragging'}`} alt=""
                 src={`${props.medicamento.foto !== "" ? props.medicamento.foto : APIPATH + "static/media/cajamedicamentos.jpg"}`}/>
            <div className="col-7 "> {props.medicamento.nombre}</div>
            <div className="col-2 pt-4 "> {props.medicamento.stock} Caja{props.medicamento.stock > 1 && 's'}</div>
        </div>
    )
}

export default MedicamentoCompra