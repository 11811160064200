import React, {useEffect, useState} from 'react';
import {authHeaders} from "../../Utils/authUtils";
import {APIPATH} from "../../settings";


import "./Anamnesis.css"

function Consumo(props) {

    const [anamnesis, setAnamnesis] = useState({
        paciente: null,
        tabaco: "",
        alcohol: "",
        cannabis: "",
        cocaina: "",
        heroina: "",
        sintesis: "",
        benzodiacepina: "",
        adiccion_sexo: false,
        adiccion_trabajo: false,
        adiccion_juego: false,
        adiccion_compras: false,
        adiccion_tecnologia: false,
        motivacionConsumo: [],
    })
    const check_fields = ['adiccion_sexo', 'adiccion_trabajo', 'adiccion_juego', 'adiccion_compras',
        'adiccion_tecnologia',]
    const [tipoConsumo, setTipoConsumo] = useState({})
    const [motivaciones, setMotivaciones] = useState({})

    useEffect(() => {

        if (Object.keys(props.anamnesis).length !== 0) {
            setAnamnesis(props.anamnesis)
        }
        //
        // Carga tipo de consumo
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/tipo_consumo/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setTipoConsumo(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga Motivaciones consumo
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/motivacion_consumo/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setMotivaciones(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }, [props])


    function handle_input(event) {

        let {name, value} = event.target
        // if (name === 'cambio') {
        //     value = checked
        // }
        setAnamnesis(previous => {
            return {...previous, [name]: value}
        })
    }

     function submit_input(event) {
        let {name, value, checked} = event.target
        if (check_fields.includes(name)) {
            value = checked
        }
        props.update({paciente:anamnesis.paciente, [name]: value})
    }


    function handle_blur(event) {
        let {name, value} = event.target
        props.update({paciente: anamnesis.paciente, [name]: value})
    }

    function handle_motivacion(event) {
        const {name, checked} = event.target
        const motivacion = event.currentTarget.dataset.motivacion
        let motivaList = anamnesis.motivacionConsumo


        if (anamnesis.motivacionConsumo.filter(mot => mot.motivacion === motivacion).length === 0) {
            motivaList.push({
                motivacion: motivacion,
                inicio: false,
                mantenimiento: false,
                recaidas: false,
                [name]: checked
            })
        } else
            motivaList = anamnesis.motivacionConsumo.map(mot => {
                if (mot.motivacion === motivacion) {
                    return {...mot, [name]: checked}
                } else {
                    return mot
                }
            })

        props.update(({
            paciente: anamnesis.paciente, motivacionConsumo: motivaList
        }))

    }

    return (
        <form className="text-small row">

            <div className="col-3 border mt-5 pt-3 ml-5 ">
                <h5 className="text-center mb-3">Sustancias</h5>
                <hr/>
                <div className="row mt-3 form-group ">
                    <label htmlFor="tabaco" className="col-5 col-form-label">Tabaco</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="tabaco"
                                name="tabaco"
                                value={anamnesis.tabaco} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group mt-3">
                    <label htmlFor="alcohol" className="col-5 col-form-label">Alcohol</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="alcohol"
                                name="alcohol"
                                value={anamnesis.alcohol} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group mt-3">
                    <label htmlFor="cannabis" className="col-5 col-form-label">Cannabis</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="cannabis"
                                name="cannabis"
                                value={anamnesis.cannabis} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group mt-3">
                    <label htmlFor="cocaina" className="col-5 col-form-label">Cocaina</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="cocaina"
                                name="cocaina"
                                value={anamnesis.cocaina} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group  mt-3">
                    <label htmlFor="heroina" className="col-5 col-form-label">Heroina</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="heroina"
                                name="heroina"
                                value={anamnesis.heroina} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group mt-3">
                    <label htmlFor="sintesis" className="col-5 col-form-label">Síntesis</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="sintesis"
                                name="sintesis"
                                value={anamnesis.sintesis} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <div className="row form-group mt-3">
                    <label htmlFor="benzodiacepinas" className="col-5 col-form-label">Benzodiacepinas</label>
                    <div className="col-7">
                        <select className="form-control text-small bg-white " id="benzodiacepinas"
                                name="benzodiazepinas"
                                value={anamnesis.benzodiacepinas} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoConsumo).map(key => {
                                return (<option key={key} value={key}>{tipoConsumo[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                <hr className="my-3" />
                <h5 className="text-center">Adiccion sin Sustancias</h5>
                <div className="row mt-3">
                    <label htmlFor="adiccion_sexo" className = "ml-4">Sexo</label>
                    <input className="form-check-input ml-2" type="checkbox" name="adiccion_sexo" id="adiccion_sexo"
                           checked={anamnesis.adiccion_sexo}
                           onChange={submit_input}/>
                    
                </div>
                <div className="row">
                    <label htmlFor="adiccion_juego" className = "ml-4">Juego</label>
                    <input className="form-check-input ml-2" type="checkbox" name="adiccion_juego" id="adiccion_juego"
                           checked={anamnesis.adiccion_juego}
                           onChange={submit_input}/>
                    
                </div>
                <div className="row">
                    <label htmlFor="adiccion_compras" className = "ml-4">Compras</label>
                    <input className="form-check-input ml-2" type="checkbox" name="adiccion_compras" id="adiccion_compras"
                           checked={anamnesis.adiccion_compras}
                           onChange={submit_input}/>
                    
                </div>
                <div className="row">
                    <label htmlFor="adiccion_tecnologia" className = "ml-4">Nuevas Tecnologías</label>
                    <input className="form-check-input ml-2" type="checkbox" name="adiccion_tecnologia" id="adiccion_tecnologia"
                           checked={anamnesis.adiccion_tecnologia}
                           onChange={submit_input}/>
                    
                </div>
            </div>
            <table className="table  col-6 mt-5 mx-auto border rounded ">
                <thead>
                <tr className="bg-primary text-white mt-5">
                    <th width="55%">Motivación para el Consumo</th>
                    <th className="text-center border-left" width="15%">Inicio</th>
                    <th className="text-center border-left" width="15%">Mantenimiento</th>
                    <th className="text-center border-left" width="15%">Recaidas</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(motivaciones).map(key => (
                    <tr key={key}>
                        <td>
                            {motivaciones[key]}
                        </td>
                        <td className="text-center">
                            <input data-motivacion={key}
                                   className="form-check-input " type="checkbox"
                                   name="inicio"
                                   checked={anamnesis.motivacionConsumo.filter(mot => (mot.motivacion === key)).length > 0 ?
                                       anamnesis.motivacionConsumo.filter(mot => (mot.motivacion === key))[0].inicio : false
                                   }
                                   onChange={handle_motivacion}
                            />
                        </td>
                        <td className="text-center">
                            <input data-motivacion={key}
                                   className="form-check-input " type="checkbox"
                                   name="mantenimiento"
                                   checked={anamnesis.motivacionConsumo.filter(mot => (mot.motivacion === key)).length > 0 ?
                                       anamnesis.motivacionConsumo.filter(mot => (mot.motivacion === key))[0].mantenimiento : false
                                   }
                                   onChange={handle_motivacion}
                            />
                        </td>
                        <td className="text-center">
                            <input data-motivacion={key}
                                   className="form-check-input " type="checkbox"
                                   name="recaidas"
                                   checked={anamnesis.motivacionConsumo.filter(psico => (psico.motivacion === key)).length > 0 ?
                                       anamnesis.motivacionConsumo.filter(psico => (psico.motivacion === key))[0].recaidas : false
                                   }
                                   onChange={handle_motivacion}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </form>


    )

}

export default Consumo