import React, {useState, useEffect} from 'react';
import 'whatwg-fetch';
import {Modal} from "react-bootstrap";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import moment from "moment"


function UpdateServicio(props) {

    const [servicio, setServicio] = useState({
        concepto: "",
        importe: "0",
        periodicidad: "mensual",
        iva: "0",
        // periodo_inicio: "",
        // ano_inicio: "",
        fecha_inicio: "",
        anticipado: true,
        facturar: true,
        activo: true,
        paciente: "",
        grupoTarifa: ""
    })

    const [tarifas, setTarifas] = useState([])

    useEffect(() => {
        if (props.servicio.id !== undefined) {
            setServicio(props.servicio)
        } else setServicio({
                concepto: "",
                importe: "0",
                periodicidad: "mensual",
                iva: "0",
                // periodo_inicio: "",
                // ano_inicio: "",
                fecha_inicio: moment(new Date()).format("YYYY-MM-DD"),
                anticipado: true,
                facturar: true,
                paciente: "",
                grupoTarifa: "",
                activo:true,
            }
        )

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/tarifas/query/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setTarifas(response)

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })


    }, [props])


    function handle_input(e) {
        let {value, name, checked} = e.target
        if (['anticipado', 'facturar', 'activo'].includes(name)) {
            value = checked
        }
        let valid = true
        if (['importe', 'iva'].includes(name) && !(!isNaN(parseFloat(value)) && isFinite(value)) && value !=="") {
            valid = false
        }

        if (valid) {

            setServicio(prev => ({...prev, [name]: value}))
        }
    }

    function handle_paciente(value, object) {
        setServicio(prev => ({...prev, paciente: value}))
    }

    function handle_grupoTarifa(value, object) {
        setServicio(prev => ({...prev, grupoTarifa: value}))
    }

    function update_servicio(e) {
        if (servicio.id === undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/servicios/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(servicio)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        props.alta(response)
                        props.handleClose()

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            const data = {...servicio}
            delete data['id']
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/servicios/detail/' + servicio.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(servicio)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        props.alta(response)
                        props.handleClose()

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Servicio</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className=" ml-3 mr-2">Paciente</label>
                        {props.pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={servicio.paciente}
                            options={[{name: "...", value: ""}, ...props.pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }

                        <label className=" ml-5 mr-2">Tarifa</label>
                        {tarifas.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={servicio.grupoTarifa}
                            options={[{name: "...", value: ""}, ...tarifas.map((tarifa, index) =>
                                ({name: tarifa.nombre, value: tarifa.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_grupoTarifa}
                        />
                        }
                    </div>
                    <div className="form-group mt-5">
                        <label htmlFor="concepto">Concepto</label>
                        <input type='text' id="concepto"
                               className={` w-100 form-control `}
                               name="concepto" value={servicio.concepto} onChange={handle_input}/>
                    </div>
                    <div className="form-group row ml-4 mt-4">
                        <label htmlFor="importe" className="mt-2">Importe</label>
                        <input type='text' id="importe"
                               className={` col-2 form-control ml-3`}
                               name="importe" value={servicio.importe} onChange={handle_input}/>
                        <span className="mt-2">€</span>
                        <label htmlFor="iva" className="ml-5 mt-2">Iva</label>
                        <input type='text' id="iva"
                               className={`col-1 form-control ml-3 `}
                               name="iva" value={servicio.iva} onChange={handle_input}/>
                        <span className="mt-2">%</span>
                    </div>
                    {/*<div className="form-group ">*/}
                    {/*    <label htmlFor="periodicidad">Periodo Inicio Facturación</label>*/}
                    {/*    <select className="form-control  bg-white ml-2 col-8" id="periodo_inicio" name="periodo_inicio"*/}
                    {/*            value={servicio.periodo_inicio} onChange={handle_input}>*/}
                    {/*        <option value=""></option>*/}
                    {/*        {periodos.map(periodo =>*/}
                    {/*            <option key={periodo.id} value={periodo.id}>{periodo.nombre}</option>*/}
                    {/*        )}*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    {/*<div className="form-group ">*/}
                    {/*    <label htmlFor="ano_inicio">Año Inicio Facturación</label>*/}
                    {/*    <select className="form-control  bg-white ml-2 col-8" id="periodicidad" name="periodicidad"*/}
                    {/*            value={new Date().getFullYear()} onChange={handle_input}>*/}
                    {/*        <option value={new Date().getFullYear() - 1}>{new Date().getFullYear() - 1}</option>*/}
                    {/*        <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>*/}
                    {/*        <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div className="form-group row ml-4 mt-4">
                        <label htmlFor="fecha_inicio">Fecha Inicio Facturación</label>
                        <input type="date" className="form-control  bg-white ml-2 col-3" id="fecha_inicio"
                               name="fecha_inicio" value={servicio.fecha_inicio}
                               onChange={handle_input}/>

                        <label htmlFor="periodicidad" className="ml-5">Periodicidad</label>
                        <select className="form-control  bg-white ml-2 col-2" id="periodicidad" name="periodicidad"
                                value={servicio.periodicidad} onChange={handle_input}>
                            <option value=""></option>
                            <option value="mensual">Mensual</option>
                            <option value="quincenal">Quincenal</option>
                            <option value="semanal">Semanal</option>
                        </select>
                    </div>
                    <div className="form-group ml-4">
                        <input className="form-check-input " type="checkbox" checked={servicio.anticipado}
                               id="anticipado"
                               name="anticipado"
                               onChange={handle_input}/>
                        <label className="form-check-label mr-5" htmlFor="anticipado">
                            Facturar periodo por anticipado
                        </label>
                    </div>
                    <div className="form-group ml-4">
                        <input className="form-check-input " type="checkbox" checked={servicio.facturar}
                               id="facturar"
                               name="facturar"
                               onChange={handle_input}/>
                        <label className="form-check-label mr-5" htmlFor="facturar">
                            Generar Factura
                        </label>
                    </div>
                    <div className="form-group ml-4">
                        <input className="form-check-input " type="checkbox" checked={servicio.activo}
                               id="activo"
                               name="activo"
                               onChange={handle_input}/>
                        <label className="form-check-label mr-5" htmlFor="activo">
                            Activo
                        </label>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar</button>

                    <button type="button" className="btn btn-primary" onClick={update_servicio} disabled={
                        servicio.fecha_inicio === ""
                    }
                    >Actualizar
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default UpdateServicio;