import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import DateRange from "react-date-range/dist/components/DateRange";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {es} from 'react-date-range/dist/locale'
import {addDays} from 'date-fns';
import "./Paciente.css"
import moment from 'moment';


function TerapiaPacienteView(props) {
    const [etiquetas, setEtiquetas] = useState([])
    const [terapias, setTerapias] = useState([])
    const [etiqueta, setEtiqueta] = useState("")
    const [etiquetaId, setEtiquetaId] = useState("")
    const [search, setSearch] = useState("")
    const [dateSelection, setDateSelection] = useState([
        {
            startDate: addDays(new Date(), -30),
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    useEffect(() => {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/tags/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setEtiquetas(response.tags)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                let endpoint = APIPATH + 'api/terapias/paciente/?paciente=' + props.paciente
                endpoint = endpoint + '&startDate=' + moment(dateSelection[0].startDate).format('YYYY-MM-DD 00:00')
                endpoint = endpoint + '&endDate=' + moment(dateSelection[0].endDate).format('YYYY-MM-DD 23:59')
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        setTerapias(response)
                                    }
                                )
                        } else {
                            setTerapias([])
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function handle_etiqueta(value, object) {
        setEtiquetaId(value)
        setEtiqueta(object.name)
    }

    function handle_input(e) {
        const {value} = e.target;
        setSearch(value)
    }

    function restablece_terapias(e) {
        setSearch("")
        setDateSelection([{
            startDate: addDays(new Date(), -90),
            endDate: new Date(),
            key: 'selection'
        }])
        setEtiquetaId(null)
    }

    function query_terapias(e) {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/terapias/paciente/?paciente=' + props.paciente
            if (search !== "") {
                endpoint = endpoint + '&search=' + search
            }
            if (etiqueta !== "") {
                endpoint = endpoint + '&tag=' + etiqueta
            }
            endpoint = endpoint + '&startDate=' + moment(dateSelection[0].startDate).format('YYYY-MM-DD 00:00')
            endpoint = endpoint + '&endDate=' + moment(dateSelection[0].endDate).format('YYYY-MM-DD 23:59')
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    if (response.ok) {
                        response.json()
                            .then(
                                response => {
                                    setTerapias(response)
                                }
                            )
                    } else {
                        setTerapias([])
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })


    }


    return (
        <div className="row">

            <div className="col-3 pb-5 mr-4 ">
                <div className=" mb-3 subtitulo">
                    <h5 className="py-2 px-3"> Búsqueda</h5>
                </div>
                <label className=" ml-3">Etiqueta</label>
                {etiquetas.length > 0 &&
                <SelectSearch
                    className="select-search ml-3"
                    value={etiquetaId}
                    options={etiquetas.map((etiqueta, index) =>
                        ({name: etiqueta, value: index})
                    )}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_etiqueta}
                />
                }
                <label className="mt-4 ml-3">Búsqueda</label>
                <textarea rows="3" className="w-100  ml-3 form-control" name="search" value={search}
                          onChange={handle_input}/>
                <label className="mt-4 col-12">Fechas</label>
                <div className=" mb-4">
                    <DateRange
                        editableDateInputs={true}
                        onChange={item => setDateSelection([item.selection])}
                        moveRangeOnFirstSelection={false}
                        locale={es}
                        direction="vertical"
                        scroll={{enabled: true}}
                        ranges={dateSelection}
                    />
                </div>
                <div className="row">
                <button type="button" className="btn btn-primary ml-5 mr-5" onClick={query_terapias}>Buscar</button>
                <button type="button" className="btn btn-secondary ml-5" onClick={restablece_terapias}>Restablecer
                </button>
                </div>
            </div>
            <div className="col-8 lista-terapias border-left">
                {terapias.map(terapia =>
                    <div
                        key={terapia.id}
                    >
                        <div className="row subtitulo py-2 px-4 ">
                            <h6 className="">{moment(terapia.fecha).format('dddd Do MMMM  YYYY, h:mm:ss a')}</h6>
                            <h5 className="ml-auto">{terapia.nombreTerapia}</h5>
                            <h6 className="mr-auto ml-2 mt-1">({terapia.terapeuta})</h6>
                            <h6 className="ml-auto">{terapia.tag}</h6>
                        </div>
                        <div dangerouslySetInnerHTML={{__html: terapia.texto}}/>

                    </div>
                )}

            </div>

        </div>
    )
}

export default TerapiaPacienteView