import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import "./Facturacion.css"
import moment from "moment"
import UpdateProforma from "./UpdateProforma";
import FechaFacturacion from "./FechaFacturacion";

function Proformas(props) {
    const [preFacturas, setPreFacturas] = useState([])
    const [checkAll, setCheckAll] = useState(false)
    const [proforma, setProforma] = useState({})
    const [showUpdate, setShowUpdate] = useState(false)
    const [showFecha, setShowFecha] = useState(false)
    const [minFecha, setMinFecha] = useState(new Date())
    const [cobrar, setCobrar] = useState(false)
    const [anular, setAnular] = useState(false)

    useEffect(() => {
            query_proforma()
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function query_proforma() {
        if (props.centro.id !== undefined) {
            authHeaders().then(authHeader => {
                    let endpoint = APIPATH + 'api/facturacion/?estado=proforma&centro=' + props.centro.id
                    if (props.paciente !== undefined && props.paciente !== 0) {
                        endpoint = endpoint + "&paciente=" + props.paciente
                    }
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        // body: JSON.stringify(data)
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => {
                            if (response.ok) {
                                response.json()
                                    .then(
                                        response => {
                                            setPreFacturas(response)
                                        }
                                    )
                            } else {
                                setPreFacturas([])
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                }
            )
        }
    }

    function select_factura(e) {
        const {checked, dataset} = e.target
        const factList = [...preFacturas]
        factList[dataset.index] = {...factList[dataset.index], select: checked}
        setPreFacturas(factList)
        setCheckAll(false)
    }

    function check_all(e) {
        const {checked} = e.target
        if (checked) {
            setCheckAll(true)
            setPreFacturas(prev => prev.map(fact => ({...fact, select: true})))
        } else {
            setCheckAll(false)
            setPreFacturas(prev => prev.map(fact => ({...fact, select: false})))
        }
    }

    function handleAction(e) {
        const {value} = e.target
        if (value === "enviar") {
            let envios = 0
            preFacturas.forEach(factura => {
                if (factura.select && factura.facturar) {
                    authHeaders().then(authHeader => {
                        const endpoint = APIPATH + 'api/facturacion/getpdf/' + factura.id + '/'
                        const lookupOptions = {
                            method: "GET",
                            headers: {
                                'Content-Type': 'application/json',
                                ...authHeader
                            },
                            credentials: 'include',
                        }

                        fetch(endpoint, lookupOptions).then(response =>
                            response.json()
                        ).then(response => {
                            const endpoint = APIPATH + 'pdf/proforma/' + response.token + '/?email=True'
                            const lookupOptions = {
                                method: "GET",
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                mode: 'no-cors',
                            }
                            fetch(endpoint, lookupOptions).then(response => {
                                envios = envios + 1
                            }).catch(error => {
                                console.log(error);
                                alert("No se pudo enviar la Factura")
                            })
                        })
                            .catch(error => {
                                console.log(error);
                                alert("No se pudo enviar la Factura")
                            });
                    })
                }
            })
            alert(" Facturas enviadas")
        }
        if (['cobrar', 'facturar', 'anular'].includes(value)) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/getfechafacturar/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(response =>
                    response.json()
                ).then(response => {
                    setMinFecha(response.fecha)
                    if (value === "cobrar") {
                        setCobrar(true)
                    } else {
                        setCobrar(false)
                    }
                    if (value === "anular") {
                        setAnular(true)
                    } else {
                        setAnular(false)
                    }
                    setShowFecha(true)
                })
                    .catch(error => {
                        console.log(error);
                        alert("No se pudo cobrar la Factura")
                    });
            })
        }
    }

    function enviar_cobro(fecha, cobro, anular) {
        const data = {
            "cobrar": cobro,
            "anular": anular,
            "fecha": fecha,
            "facturas": preFacturas.filter(factura => factura.select).map(factura => factura.id)
        }

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/facturar/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(response => props.refresh())
                .catch(error => {
                    console.log(error);
                    alert("Error en el Cobro")
                });
        })


    }


    return (
        <div>
            <div className="row border-right border-top">
                <div className="col-12 bg-white px-3 pt-2">
                    <div className="row">
                        <button type="button" className=" mr-2 ml-4 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={(e) => {
                                    setProforma({})
                                    setShowUpdate(true)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <h5 className="col-4 ml-3 text-primary">Proforma</h5>
                        <select className="form-control col-4 ml-auto mr-3" value="" onChange={handleAction}>
                            <option value="">--Acción--</option>
                            <option value="enviar">Enviar</option>
                            <option value="cobrar">Cobrar</option>
                            <option value="facturar">Facturar</option>
                            <option value="anular">Anular</option>
                        </select>
                    </div>
                </div>

                <div className='col-12 bg-white px-3'>
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan="2">Paciente</th>
                            <th>Importe</th>
                            <th>Fecha</th>
                            <th>Enviada</th>
                            <th>Factura</th>
                            <th><input type="checkbox"
                                       checked={checkAll}
                                       onChange={check_all}/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {preFacturas.map((factura, index) => (
                            <tr key={index}>
                                <td width="12%">
                                    {factura.img !== null &&
                                    <button type="button" className="btn-link border-0 bg-white"
                                            onClick={() => {
                                                setProforma(factura)
                                                setShowUpdate(true)
                                            }}>
                                        <img src={`${factura.img}`} alt="" className="img-fluid"/>
                                    </button>}
                                </td>
                                <td width="25%">
                                    <button type="button" className="btn-link border-0 bg-white"
                                            onClick={() => {
                                                setProforma(factura)
                                                setShowUpdate(true)
                                            }}>
                                        {factura.alias}
                                    </button>
                                    <p>{factura.nombre_factura}</p>
                                </td>
                                <td>
                                    <button type="button" className="btn-link border-0 bg-white"
                                            onClick={() => {
                                                setProforma(factura)
                                                setShowUpdate(true)
                                            }}>
                                        {parseFloat(factura.importe_total).toLocaleString('de-DE', {
                                            style: 'currency',
                                            currency: 'EUR'
                                        })}
                                    </button>
                                </td>
                                <td>
                                    <button type="button" className="btn-link border-0 bg-white"
                                            onClick={() => {
                                                setProforma(factura)
                                                setShowUpdate(true)
                                            }}>

                                        {moment(factura.fecha_creacion.substring(0, 10)).format('DD MMM YY')}
                                    </button>
                                </td>
                                <td className="text-center"><input type="checkbox" data-index={index} disabled={true}
                                                                   checked={factura.envio_proForma}/>
                                </td>
                                <td>
                                    {factura.facturar &&
                                    <div className="border text-center">
                                        Factura
                                    </div>
                                    }
                                </td>
                                <td><input type="checkbox" data-index={index}
                                           checked={factura.select !== undefined ? factura.select : false}
                                           onChange={select_factura}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <UpdateProforma
                show={showUpdate}
                handleClose={() => setShowUpdate(false)}
                factura={proforma}
                centro={props.centro}
                alta={query_proforma}
                refresh={props.refresh}
            />
            <FechaFacturacion
                show={showFecha}
                handleClose={() => setShowFecha(false)}
                fecha={minFecha}
                facturar={enviar_cobro}
                cobro={cobrar}
                anular={anular}
            />
        </div>
    )
}

export default Proformas