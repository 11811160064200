import React, {useEffect, useState, useRef} from "react";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import PrescripcionInline from "./PrescripcionInline";
import moment from "moment";
import {useMountedState} from "react-use";



function Prescripciones(props) {
    const [prescripciones, setPrescripciones] = useState([])
    const firstUpdate = useRef(true)
    const isMounted = useMountedState();
    useEffect(() => {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/prescripcion/?alias=' + props.alias
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    if (isMounted()) {
                        setPrescripciones(response)
                        if (firstUpdate.current) {
                            firstUpdate.current = false
                            props.prescripcionDetail(response[0])
                            props.setLast(response[0])
                        }

                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function setDetail(prescripcion) {
        props.prescripcionDetail(prescripcion)
    }

    function newPrescripcion() {
        props.prescripcionDetail({
            id: "",
            medicamentos: [],
            adjuntos: [],
            doctor: "",
            fecha: moment().format('YYYY-MM-DD'),
            notas: "",
            paciente: "",
            activa: true
        })
    }

    function deletePrescripcion(prescripcion) {
        const presList = prescripciones.filter(pres => pres.id !== prescripcion.id)
        if (isMounted()) {
            setPrescripciones(presList)
            props.prescripcionDetail(presList[0])
            props.setLast(presList[0])
            if (presList.length === 0) {
                newPrescripcion()
                props.setLast({
                    id: "",
                    medicamentos: [],
                    adjuntos: [],
                    doctor: "",
                    fecha: moment().format('YYYY-MM-DD'),
                    notas: "",
                    paciente: "",
                    activa: true
                })
            }
        }


    }

    return (
        <div>
            <div className="row bg-primary w-100 m-0 p-2">
                <div className="col-1">
                    <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-primary text-white"
                            onClick={newPrescripcion}
                    >
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                </div>
                <h5 className="col-11  text-white">Historial</h5>
            </div>
            <table className="bg-white table">
                <thead>
                <tr className="">
                    <th>
                        Fecha
                    </th>
                    <th>Doctor</th>
                    <th>Medicaciones</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {prescripciones != null && prescripciones.map(prescripcion =>
                    <PrescripcionInline
                        key={prescripcion.id}
                        prescripcion={prescripcion}
                        setPrescripcion={setDetail}
                        deletePrescripcion={deletePrescripcion}
                    />
                )}
                </tbody>
            </table>

        </div>
    );


}

export default Prescripciones;