import React, {useEffect, useState} from 'react';
import {FileIcon, defaultStyles} from "react-file-icon";
import moment from "moment";
import ConfirmDeleteFichero from "./ConfirmDeleteFichero";

function TerapiaFileInline(props) {
    const [extension, setExtension] = useState("")
    const [isImage, setIsImage] = useState(false)
    const [terapiaFile, setTerapiaFile] = useState({})


    useEffect(() => {
        let ext = ""
        if (props.terapiaFile.descripcion) {
            ext = props.terapiaFile.descripcion.split('.').pop()
            setExtension(ext)
        }
        if (['gif', 'png', 'jpg', 'jpeg', 'png', 'bmp'].includes(ext)) {
            setIsImage(true)
        }
        setTerapiaFile(props.terapiaFile)
    }, [props])

    function delete_file(terapiaFile) {
        props.delete_file(terapiaFile)
    }

    return (
        <div className="row mb-1">
            <div className="col-2 ">
                {isImage ?
                <img src={terapiaFile.fichero} className="img-fluid" alt="..." /> :
                <FileIcon extension={extension}
                          {...defaultStyles[extension]} />}
            </div>
            <div className="col-7 text-small wrap-word">
                <a href={terapiaFile.fichero} target="_blank" rel="noopener noreferrer">
                    {terapiaFile.descripcion && terapiaFile.descripcion.substring(0, 100)}</a>
            </div>
            <div className="col-2 text-small">
                {moment(terapiaFile.alta).format('D MMM YYYY')}{}
            </div>
            <div className="col-1">
                <button type="button" className={`border-0 bg-white text-danger`} data-toggle="modal"
                        data-target={`#deleteFile-${props.terapiaFile.id}`}>
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                         fill="currentColor"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fillRule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                    </svg>
                </button>
                <ConfirmDeleteFichero
                    modalId={`deleteFile-${props.terapiaFile.id}`}
                    fichero={terapiaFile}
                    delete_fichero={delete_file}
                />
            </div>

        </div>
    )
}

export default TerapiaFileInline;