

const APIPATH_PROD = '/'
const APIPATH_DEV = "http://127.0.0.1:8000/"
const TOKEN_REFRESH_MINUTES = 4

let APIPATH = APIPATH_DEV
if (process.env.NODE_ENV === 'production') {
    APIPATH = APIPATH_PROD
}

export  {
    APIPATH,
    APIPATH_DEV,
    APIPATH_PROD,
    TOKEN_REFRESH_MINUTES
}