import React from 'react';

import "./Paciente.css"

function MedicamentoCentroInline(props) {



    return (
        <tr className={` ${props.medicamento.dias_uso > 4 ? 'bg-white text-primary' : props.medicamento.dias_uso < 1 ? 'bg-danger text-white' : 'bg-no-ultima text-secondary'}`}>
            <td>{props.medicamento.nombre}</td>
            <td>
                {props.medicamento.paciente}
            </td>
            <td>
                {props.medicamento.dias_uso}
            </td>

        </tr>
    )
}

export default MedicamentoCentroInline;

