import React, {useState, useEffect, useRef} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "./authUtils";
import SelectSearch from 'react-select-search';
import "./select.css"


function SelectCentro(props) {
    const firstUpdate = useRef(true)
    const [centroList, setCentroList] = useState([])
    const [centro, setCentro] = useState({
        id: "",
        nombre: "",
        direccion1: "",
        direccion2: "",
        cp: "",
        localidad: "",
        provincia: "",
        personal: [],
    })


    useEffect(() => {
        let cent = JSON.parse(sessionStorage.getItem('centro'));
        if (props.user.id !== 0 & firstUpdate.current) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/centro/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        let lista = response.filter(centro => {
                            return centro.personal.map(value => value.user).includes(props.user.id)
                        })
                        setCentroList(lista)
                        if (!cent) {
                            setCentro(lista[0])
                            props.setCentro(lista[0])
                            sessionStorage.setItem('centro', JSON.stringify(lista[0]))
                        } else {
                            setCentro(cent)
                             props.setCentro(cent)
                        }
                        firstUpdate.current = false

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }, [props])

    function handle_centro(value, object) {
        // authHeaders().then(authHeader => {
        //     const endpoint = APIPATH + 'api/pacientes/centro/detail/' + value + '/'
        //     const lookupOptions = {
        //         method: "GET",
        //         headers: {
        //             'Content-Type': 'application/json',
        //             ...authHeader
        //         },
        //         credentials: 'include',
        //     }
        //     fetch(endpoint, lookupOptions).then(
        //         response => response.json()
        //     ).then(
        //         response => {
        //             // setCentro(response)
        //             props.setCentro(response)
        //         }
        //     ).catch(
        //         error => {
        //             console.log("error", error)
        //         }
        //     )
        // })


        const cent = centroList.filter(centro => centro.id ===value)[0]
        setCentro(cent)
        props.setCentro(cent)
        sessionStorage.setItem('centro', JSON.stringify(cent))
    }


    return (
        <SelectSearch
            className="select-search"
            value={centro ? centro.id : ""}
            options={centroList.map(cent =>
                ({name: cent.nombre, value: cent.id})
            )}
            search
            printOptions="on-focus"
            placeholder="Seleccione Centro"
            onChange={handle_centro}
        />
    )
}

export default SelectCentro