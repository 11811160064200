import moment from "moment";
import {APIPATH, TOKEN_REFRESH_MINUTES} from "../settings";

export async function authHeaders() {
    // return authorization header with jwt token
    const userToken = JSON.parse(sessionStorage.getItem('userToken'))
    let {access, refresh} = JSON.parse(sessionStorage.getItem('userToken'))
    const refreshTimeOut = new Date(sessionStorage.getItem('refreshTimeOut'))
    const now = new Date()
    if (access) {
        if (now.getTime() > refreshTimeOut.getTime()) {
            const endpoint = APIPATH + 'api/accounts/jwt/refresh/'
            const data = {"refresh": refresh}
            let ok = true

            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                credentials: 'include',
            }
            return fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    return response.json()
                }
            ).then(
                response => {
                    if (ok) {
                        const now = new Date()
                        const refreshTimeOut = moment(now).add(TOKEN_REFRESH_MINUTES - 1, 'm').toDate()
                        const newToken = {...userToken, access: response.access}
                        sessionStorage.setItem('userToken', JSON.stringify(newToken))
                        sessionStorage.setItem('refreshTimeOut', refreshTimeOut)
                        return {Authorization: `Bearer ${response.access}`};
                    } else {
                        sessionStorage.removeItem('userToken')
                        sessionStorage.removeItem('refreshTimeOut')
                        window.location.assign('/')
                    }

                }
            ).catch(
                error => {
                    console.log("error", error)
                    sessionStorage.removeItem('userToken')
                    window.location.assign('/')
                }
            )
        } else {
            return {Authorization: `Bearer ${access}`};
        }
    } else {
        window.location.assign('/')
        return {};
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                sessionStorage.removeItem('userToken')
                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}