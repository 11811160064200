import React, {useEffect, useState} from 'react';
import {authHeaders} from "../../Utils/authUtils";
import {APIPATH} from "../../settings";


import "./Anamnesis.css"
import InformeAnamnesis from "./InformeAnamnesis";

function Familiar(props) {

    const [anamnesis, setAnamnesis] = useState({
        paciente: "",
        tipo_padre: "",
        padre: "",
        tipo_madre: "",
        madre: "",
        tipo_hermanos: "",
        hermanos: "",
        tipo_hijos: "",
        hijos: "",
        tipo_otros: "",
        otros: "",
        tipo_pareja: "",
        pareja: false,
        noviazgos: "",
        matrimonios: "",
        vida_matrimonial: "",

    })
    // const [formErrors, setFormErrors] = useState({})
    const [tipoRelacion, setTipoRelacion] = useState({})


    useEffect(() => {

        if (Object.keys(props.anamnesis).length !== 0) {
            setAnamnesis(props.anamnesis)
        }
        //
        // Carga acontecimientos
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/relacion/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setTipoRelacion(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [props])


    function handle_input(event) {

        let {name, value} = event.target
        // if (name === 'cambio') {
        //     value = checked
        // }
        setAnamnesis(previous => {
            return {...previous, [name]: value}
        })
    }

    function handle_blur(event) {
        let {name, value} = event.target
        props.update({paciente: anamnesis.paciente, [name]: value})
    }


    return (
        <form className="text-small">
            <div className='row'>
                <div className='col-2 mt-5'>
                    <div className="form-group">
                        Padre
                        <select className="form-control text-small bg-white " id="tipo_padre"
                                name="tipo_padre"
                                value={anamnesis.tipo_padre} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoRelacion).map(key => {
                                return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                            })}
                        </select>
                    </div>
                    <div className="form-group mt-4">
                        Madre
                        <select className="form-control text-small bg-white " id="tipo_madre"
                                name="tipo_madre"
                                value={anamnesis.tipo_madre} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(tipoRelacion).map(key => {
                                return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                            })}
                        </select>
                    </div>

                    <div className="form-group mt-4">
                            Hermanos
                            <select className="form-control text-small bg-white " id="tipo_hermanos"
                                    name="tipo_hermanos"
                                    value={anamnesis.tipo_hermanos} onChange={handle_input} onBlur={handle_blur}>
                                <option value=""></option>
                                {Object.keys(tipoRelacion).map(key => {
                                    return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                                })}
                            </select>
                        </div>
                    <div className="form-group mt-4">
                            Hijos
                            <select className="form-control text-small bg-white " id="tipo_hijos"
                                    name="tipo_hijos"
                                    value={anamnesis.tipo_hijos} onChange={handle_input} onBlur={handle_blur}>
                                <option value=""></option>
                                {Object.keys(tipoRelacion).map(key => {
                                    return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                                })}
                            </select>
                        </div>

                    <div className="form-group mt-4">
                            Otros
                            <select className="form-control text-small bg-white " id="tipo_otros"
                                    name="tipo_otros"
                                    value={anamnesis.tipo_otros} onChange={handle_input} onBlur={handle_blur}>
                                <option value=""></option>
                                {Object.keys(tipoRelacion).map(key => {
                                    return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                                })}
                            </select>
                        </div>



                    <div className="row ml-3 mt-4">
                        <input className="form-check-input" type="checkbox" name="pareja" value={anamnesis.pareja}
                               checked={anamnesis.pareja}
                               onChange={(e) => {
                                   e.preventDefault()
                                   props.update({paciente: anamnesis.paciente, pareja: e.target.checked})
                                   // props.update({paciente: anamnesis.paciente, tipo_pareja: ""})
                               }}/>
                        Pareja
                    </div>
                        <select className="form-control text-small bg-white " id="tipo_pareja"
                                name="tipo_pareja" disabled={!anamnesis.pareja}
                                value={anamnesis.tipo_pareja} onChange={handle_input} onBlur={handle_blur}>>
                            <option value=""></option>
                            {Object.keys(tipoRelacion).map(key => {
                                return (<option key={key} value={key}>{tipoRelacion[key]}</option>)
                            })}
                        </select>
                    </div>
                <div className='col-10'>
                    <InformeAnamnesis
                        anamnesis={{paciente: anamnesis.paciente, informe_familiar: anamnesis.informe_familiar}}
                        cabecera=""
                        update={anamnesis => {
                            props.update(anamnesis)
                        }}
                    />
                </div>
            </div>


        </form>
    )
}

export default Familiar