import React from 'react';
import {Modal} from "react-bootstrap";


function DatosFacturacion(props) {

    function handle_input(e) {
        const {value, name} = e.target
        let valid = true
        if (name === "factura_NIF" && value.length > 10) {
            valid=false
        }
        if (name === "factura_cp" && value.length > 5) {
            valid=false
        }
        if (valid) {
            props.setPaciente({...props.paciente, [name]: value})
        }
    }


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="md">
                <Modal.Header closeButton>
                    <h5 className="modal-title">-Datos Facturación</h5>
                </Modal.Header>
                <Modal.Body>
<div className="form-group">
                        <label htmlFor="factura_nombre">Nombre</label>
                        <input type='text' id="factura_nombre" required
                               className={`form-control `}
                               name="factura_nombre" value={props.paciente.factura_nombre} onChange={handle_input}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="factura_NIF">NIF</label>
                        <input type='text' id="factura_NIF"
                               className={`w-25 form-control `}
                               name="factura_NIF" value={props.paciente.factura_NIF} onChange={handle_input}/>
                    </div>
                    <div className=''>
                        <div className="form-group mt-5 ">
                            <label htmlFor="factura_direccion1">Dirección</label>
                            <input type='text' id="factura_direccion1"
                                   className={`w-100 form-control `}
                                   name="factura_direccion1" value={props.paciente.factura_direccion1} onChange={handle_input}/>
                            <input type='text' id="factura_direccion2"
                                   className={`mt-1 w-100 form-control `}
                                   name="factura_direccion2" value={props.paciente.factura_direccion2} onChange={handle_input}/>
                        </div>
                        <div className="row">
                            <div className="form-group col-3">
                                <label htmlFor="factura_cp">Cod.Postal</label>
                                <input type='text' id="factura_cp"
                                       className={` w-100 form-control `}
                                       name="factura_cp" value={props.paciente.factura_cp} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-5">
                                <label htmlFor="factura_localidad">Localidad</label>
                                <input type='text' id="factura_localidad"
                                       className={` w-100 form-control `}
                                       name="factura_localidad" value={props.paciente.factura_localidad} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-4 pl-0">
                                <label htmlFor="factura_provincia">Provincia</label>
                                <input type='text' id="factura_provincia"
                                       className={` w-100 form-control `}
                                       name="factura_provincia" value={props.paciente.factura_provincia} onChange={handle_input}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-10">
                                <label htmlFor="factura_email">Email</label>
                                <input type='email' id="factura_email"
                                       className={` w-100 form-control `}
                                       name="factura_email" value={props.paciente.factura_email} onChange={handle_input}/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Aceptar</button>

                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default DatosFacturacion;