import React, {useState} from 'react'
import moment from 'moment'
import {Modal} from "react-bootstrap";
import SelectSearch from "react-select-search";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";

function Traspaso(props) {
    const [traspaso, setTraspaso] = useState({
        depositoOrigen: "",
        depositoDestino: "",
        importe: "",
        fecha: moment(new Date()).format("YYYY-MM-DD")
    })

    function handle_input(e) {
        const {name, value} = e.target
        let valid = true

        if (['importe',].includes(name) && !(!isNaN(parseFloat(value)) && isFinite(value)) && value !== "") {
            valid = false
        }
        if (valid) {
            setTraspaso(prev => ({...prev, [name]: value}))
        }
    }

    function update_traspaso() {
        const mov = {
            fecha: traspaso.fecha,
            deposito: traspaso.depositoOrigen,
            concepto: "Traspaso a " + props.depositos.filter(deposito => deposito.id === traspaso.depositoDestino)[0].nombre,
            centro: props.centro.id,
            signo: "H",
            traspaso: true,
            suplido: false,
            registro: false,
            importeTotal: traspaso.importe,
            importeNeto: traspaso.importe,
            oculto: false,
        }
        let form_data = new FormData()
        Object.keys(mov).forEach(key => {
            if (key !== 'tiposIva')
                form_data.append(key, mov[key])
        })
        const mov2 = {
            fecha: traspaso.fecha,
            deposito: traspaso.depositoDestino,
            concepto: "Traspaso de " + props.depositos.filter(deposito => deposito.id === traspaso.depositoOrigen)[0].nombre,
            centro: props.centro.id,
            signo: "D",
            traspaso: true,
            suplido: false,
            registro: false,
            importeTotal: traspaso.importe,
            importeNeto: traspaso.importe,
            oculto: false,
        }
        let form_data2 = new FormData()
        Object.keys(mov2).forEach(key => {
            if (key !== 'tiposIva')
                form_data2.append(key, mov2[key])
        })


        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contabilidad/movimientos/'

            const lookupOptions = {
                method: "POST",
                headers: {
                    // 'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: form_data
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    authHeaders().then(authHeader => {
                        const endpoint = APIPATH + 'api/contabilidad/movimientos/'

                        const lookupOptions = {
                            method: "POST",
                            headers: {
                                // 'Content-Type': 'application/json',
                                ...authHeader
                            },
                            credentials: 'include',
                            body: form_data2
                        }
                        fetch(endpoint, lookupOptions).then(
                            response => response.json()
                        ).then(response => {
                                props.update()
                                props.handleClose()
                            }
                        ).catch(
                            error => {
                                console.log("error", error)
                            }
                        )
                    })

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Traspaso</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group row mb-4 ml-4 mt-2">
                        <label htmlFor="fecha">Fecha</label>
                        <input type="date" className="form-control  bg-white ml-4 col-3" id="fecha"
                               name="fecha" value={traspaso.fecha}
                               onChange={handle_input}/>
                    </div>


                    <div className="row mt-5 ml-4">
                        <label className="mr-2">Origen</label>
                        {props.depositos.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={traspaso.depositoOrigen}
                            options={props.depositos.map((deposito, index) =>
                                ({name: deposito.nombre, value: deposito.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={(value, object) => {
                                setTraspaso(prev => ({...prev, depositoOrigen: value}))
                            }}
                        />
                        }
                        <label className=" ml-5 mr-2">Destino</label>
                        {props.depositos.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={traspaso.depositoDestino}
                            options={props.depositos.map((deposito, index) =>
                                ({name: deposito.nombre, value: deposito.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={(value, object) => {
                                setTraspaso(prev => ({...prev, depositoDestino: value}))
                            }}
                        />
                        }


                    </div>
                    <div className="form-group row ml-4 mt-4">
                        <label htmlFor="importe" className="mt-2">Importe</label>
                        <input type='text' id="importe"
                               className={` col-2 form-control ml-3`}
                               name="importe" value={traspaso.importe} onChange={handle_input}/>
                        <span className="mt-2">€</span>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar</button>

                    <button type="button" className="btn btn-primary" onClick={update_traspaso} disabled={
                        traspaso.depositoOrigen === "" || traspaso.depositoDestino === "" || traspaso.importe === "0" || traspaso.importe === ""
                    }
                    >Actualizar
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )


}

export default Traspaso