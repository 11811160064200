import React, {useState, useEffect, useRef} from 'react';
import 'whatwg-fetch';
import {Modal} from "react-bootstrap";
import SearchMedicamentoInline from "./SearchMedicamentoInline";
// import Pagination from 'react-bootstrap-4-pagination'
import Pagination from 'react-js-pagination'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import ModalMsg from "../Utils/ModalMsg";
// import PropTypes from "prop-types";

// import './App.css';

function SearchMedicamento(props) {
    const [searching, setSearching] = useState(false)
    const [search, setSearch] = useState("")
    const [modalMsg, setModalMsg] = useState({
        titulo: "",
        mensaje: "",
        show: false
    })
    const [pagina, setPagina] = useState({
        totalItemsCount: 1,
        activePage: 1,
        itemsCountPerPage: 1,
        pageRangeDisplayed: 1,
        onChange: searchWeb,

    })
    const [medicamentos, setMedicamentos] = useState([])
    const [selMedicamento, setSelMedicamento] = useState()

    function handle_input(e) {
        const {value} = e.target
        setSearch(value)
        setSelMedicamento(null)


    }

    function seleccionarMedicamento() {

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/medicamentos/medicamento/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(selMedicamento)
            }
            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            props.changeLinea(response)
                            props.handleClose()
                        })
                    } else {
                        response.json().then(response => {
                            let mensaje = ""
                            Object.keys(response).forEach(key => {
                                mensaje = mensaje + " " + key + ":" + response[key]
                            })
                            setModalMsg({
                                titulo: "No se pudo guardar Medicamento",
                                mensaje: mensaje,
                                show: true
                            })
                            setModalMsg(previous => {
                                return {...previous, show: false}
                            })
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function searchWeb(pagina) {
        if (search !== "") {
            setSearching(true)
            let endpoint = ""
            if (pagina === 0) {
                endpoint = 'https://cima.aemps.es/cima/rest/medicamentos?comerc=1&nombre=' + search
            } else {
                endpoint = 'https://cima.aemps.es/cima/rest/medicamentos?comerc=1&nombre=' + search + '&pagina=' + pagina
            }

            const lookupOptions = {
                method: "GET",
                headers: {
                    // 'Content-Type': 'application/json',
                }
            }
            fetch(endpoint, lookupOptions).then(response => {
                if (response.ok) {
                    return response.json()
                }
            }).then(
                response => {
                    if (response) {
                        if (response.totalFilas > 0) {
                            setPagina({
                                totalItemsCount: response.totalFilas,
                                activePage: response.pagina,
                                itemsCountPerPage: response.tamanioPagina,
                                pageRangeDisplayed: 5,
                                itemClass: "page-item",
                                linkClass: "page-link",
                                onChange: searchWeb,
                            })

                        } else {

                            setPagina({
                                totalItemsCount: 1,
                                activePage: 1,
                                itemsCountPerPage: 1,
                                pageRangeDisplayed: 1,
                                onChange: searchWeb,
                            })
                        }

                        setMedicamentos(response.resultados.map(med => ({
                            nregistro: med.nregistro,
                            nombre: med.nombre,
                            prospecto: med.docs[1] ? med.docs[1].urlHtml : "",
                            ficha_tecnica: med.docs[0] ? med.docs[0].urlHtml : "",
                            foto: med.fotos ? med.fotos[0].url : "",
                            formaFarmaceutica: med.formaFarmaceuticaSimplificada.nombre,
                            principioActivo: med.vtm.nombre,
                            dosis: med.dosis,
                            via: med.viasAdministracion[0].nombre,


                        })))
                    }
                    setSearching(false)

                }
            ).catch(
                error => {
                    setSearching(false)
                    console.log("error", error)
                }
            )
        }
    }

    const timeoutRef = useRef(null);


    useEffect(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            timeoutRef.current = null;
            searchWeb(0);
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Buscar Medicacion</h5>
                </Modal.Header>
                <Modal.Body>
                    <input className="form-control" name="search" value={search} onChange={handle_input}/>
                    {searching ?
                        <div className="d-flex justify-content-center searching mx-auto mt-5 pt-5">
                        <div className="spinner-border  text-warning pl-5 mt-5 pt-5" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        </div>:
                        <table className={"table"}>
                            <thead>
                            </thead>
                            <tbody className="searchInline-area">

                            {medicamentos.map((med, index) => (
                                <SearchMedicamentoInline
                                    key={index}
                                    medicamento={med}
                                    selectMedicamento={(medicamento) => {
                                        setSelMedicamento(medicamento)
                                        setSearch(medicamento.nombre)
                                    }}
                                />
                            ))}
                            </tbody>
                        </table>}
                </Modal.Body>
                <Modal.Footer>
                    <Pagination {...pagina} itemClass="page-item" linkClass="page-link"/>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar</button>

                    <button type="button" className="btn btn-primary" onClick={seleccionarMedicamento}
                            disabled={!selMedicamento}>Seleccionar
                    </button>
                </Modal.Footer>
            </Modal>
            <ModalMsg
                titulo={modalMsg.titulo}
                mensaje={modalMsg.mensaje}
                show={modalMsg.show}
                modalId="msgPrescripcion"
            />
        </div>
    )
}

export default SearchMedicamento;