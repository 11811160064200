import {Editor} from '@tinymce/tinymce-react'
import React, {useEffect, useState} from 'react';

function InformeAnamnesis(props) {
    const [fieldName, setFieldName] = useState('')
    const [anamnesis, setAnamnesis] = useState('')
    useEffect(() => {
        setAnamnesis(props.anamnesis)
        setFieldName(Object.keys(props.anamnesis).pop('paciente'))

    }, [props])


    function handle_editor(content, editor) {
        setAnamnesis(previous => {
            return {...previous, [fieldName]: content}
        })
    }

    function handle_blur(editor) {

        props.update({...props.anamnesis, [Object.keys(props.anamnesis).pop('paciente')]: editor.getContent()})
    }

    return (

        <div className="mt-2 mb-0">
            <div className='row mx-2 '>
                <p> {props.cabecera}</p>
            </div>
            <div className="bg-white">
                <Editor id={fieldName}
                        name={anamnesis}
                        init={{
                            height: 780,
                            menubar: true,
                            branding: false,
                            plugins: [
                                'advlist autolink lists link image  print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help save '
                            ],
                            language: 'es_ES',
                            // inline: true,
                            elementpath: false,
                            save_onsavecallback: handle_blur,
                            toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen | save`
                        }}

                        value={anamnesis[fieldName]} onEditorChange={handle_editor}
                        onBlur={(e) => {
                            props.update(anamnesis)
                        }}/>
            </div>
        </div>
    )

}

export default InformeAnamnesis;