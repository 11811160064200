import React, {useState, useEffect} from 'react';
import 'whatwg-fetch';
import {Modal} from "react-bootstrap";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import moment from "moment";


function UpdateFactura(props) {

    const [factura, setFactura] = useState({
        id: "",
        detalle: [],
        fecha_creacion: moment(new Date()).format('YYYY-MM-DDThh::mm'),
        fecha_factura: "",
        importe_neto: "0",
        importe_total: "0",
        envio_proForma: false,
        envio_factura: false,
        anulada: false,
        anticipado: true,
        facturar: true,
        paciente: "",
        deposito_nombre: "",
    })

    const [tarifas, setTarifas] = useState([])
    const [pacientes, setPacientes] = useState([])
    useEffect(() => {
            if (props.factura.id !== undefined) {
                const fact = {...props.factura}
                delete fact.select
                setFactura(fact)
            } else setFactura({
                    id: "",
                    detalle: [],
                    fecha_creacion: moment(new Date()).format('YYYY-MM-DDThh:mm'),
                    importe_neto: "0",
                    importe_total: "0",
                    envio_proForma: false,
                    envio_factura: false,
                    anulada: false,
                    facturar: true,
                    paciente: "",
                    deposito_nombre: "",
                }
            )

            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/tarifas/query/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setTarifas(response)

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            if (props.centro.id !== undefined) {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/?centro=' + props.centro.id
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientes(response)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })

            }
        }
        ,
        [props]
    )


    function handle_paciente(value, object) {
        setFactura(prev => ({...prev, paciente: value}))
    }

    function handle_grupoTarifa(value, object, index) {
        const lineas = [...factura.detalle]
        lineas[index] = {...lineas[index], grupoTarifa: value}
        setFactura(prev => ({...prev, detalle: lineas}))
    }


    function imprime_factura(e) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/getpdf/' + factura.id + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',

            }

            fetch(endpoint, lookupOptions).then(response =>
                response.json()
            ).then(response => {
                const url = APIPATH + 'pdf/factura/' + response.token + '/'
                window.open(url);
            })
                .catch(error => {
                    console.log(error);
                });
        })
    }

    function envia_factura(id) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/getpdf/' + factura.id + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }

            fetch(endpoint, lookupOptions).then(response =>
                response.json()
            ).then(response => {
                const endpoint = APIPATH + 'pdf/factura/' + response.token + '/?email=True'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    mode: 'no-cors',
                }
                fetch(endpoint, lookupOptions).then(response => {
                    alert("Factura enviada")
                    props.refresh()
                    props.handleClose()
                }).catch(error => {
                    console.log(error);
                    alert("No se pudo enviar la Factura")
                })
            })
                .catch(error => {
                    console.log(error);
                    alert("No se pudo enviar la Factura")
                });
        })
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Factura Nº {factura.facturar ? factura.numero: 'NO'}</h5>

                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className=" ml-3 mr-2">Paciente</label>
                        {pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={factura.paciente}
                            options={[{name: "...", value: ""}, ...pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            disabled={factura.id !== ""}
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }

                        <label className="col-1 ml-4 mr-2" htmlFor="fecha_factura">Fecha</label>
                        <input type='date' id="fecha_factura"
                               className={` col-2 form-control `}
                               name="fecha_factura"
                               value={factura.fecha_factura ? factura.fecha_factura.substring(0, 10) : factura.fecha_creacion.substring(0, 10)}
                               disabled={true}/>
                        <label className="col-1 ml-4 mr-2" htmlFor="deposito">Depósito</label>
                        <input type='text' id="deposito"
                               className={` col-3 form-control `}
                               name="deposito" value={factura.deposito_nombre}
                               disabled={true}/>
                    </div>
                    <div className="form-group row ml-1 mt-4">
                        <label htmlFor="importe_neto" className="mt-2">Importe Neto</label>
                        <input type='text' id="importe_neto" disabled={true}
                               className={` col-2 form-control ml-3 mr-3`}
                               name="importe_neto" value={parseFloat(factura.importe_neto).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        })}/>
                        <label htmlFor="importe_total" className="mt-2 ml-3 ">Importe Total</label>
                        <input type='text' id="importe_total" disabled={true}
                               className={` col-2 form-control ml-3 mr-1`}
                               name="importe_total"
                               value={parseFloat(factura.importe_total).toLocaleString('de-DE', {
                                   style: 'currency',
                                   currency: 'EUR'
                               })}/>
                        <label className="ml-3 mr-2" htmlFor="fecha_cobro">Cobrada : </label>
                        <input type='text' id="fecha_cobro"
                               className={`form-control text-center  w-25 ${!factura.fecha_cobro && 'bg-warning'}`}
                               name="fecha_cobro"
                               value={factura.fecha_cobro ? moment(factura.fecha_cobro).format('DD/MM/YYYY') : 'Pendiente'}
                               disabled={true}/>
                    </div>
                    <div className="form-group row ml-1">
                        <input className="form-check-input ml-1 " type="checkbox" checked={factura.envio_factura}
                               id="envio_factura"
                               name="envio_factura"
                               disabled={true}/>
                        <label className="form-check-label ml-4 mr-5" htmlFor="envio_factura">
                            Enviada
                        </label>


                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>

                            </th>
                            <th className="text-center">
                                Tarifa
                            </th>
                            <th className="text-center">
                                concepto
                            </th>
                            <th className="text-center">
                                importe
                            </th>
                            <th className="text-center">
                                iva
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {factura.detalle.map((linea, index) =>
                            <tr key={index}>
                                <td width="5%">

                                </td>
                                <td width="25%">
                                    {tarifas.length > 0 &&
                                    <SelectSearch
                                        className="select-search ml-3"
                                        value={linea.grupoTarifa}
                                        options={[{name: "...", value: ""}, ...tarifas.map((tarifa, index) =>
                                            ({name: tarifa.nombre, value: tarifa.id})
                                        )]}
                                        search
                                        printOptions="on-focus"
                                        placeholder={" ... "}
                                        onChange={(value, object) => handle_grupoTarifa(value, object, index)}
                                        disabled={true}
                                    />
                                    }
                                </td>
                                <td>
                                    <input type='text' data-index={index}
                                           className={` form-control ml-3`} disabled={true}
                                           name="concepto" value={linea.concepto}/>
                                </td>
                                <td width="10%">
                                    <input type='text' data-index={index} disabled={true}
                                           className={` form-control ml-3`}
                                           name="importe" value={linea.importe}/>
                                </td>
                                <td width="8%">
                                    <input type='text' data-index={index} disabled={true}
                                           className={`  form-control ml-3`}
                                           name="iva" value={linea.iva}/>

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar
                    </button>

                    <button type="button" className="btn btn-outline-warning" onClick={imprime_factura}
                            disabled={!factura.facturar}>Imprimir
                    </button>
                    <button type="button" className="btn btn-outline-warning" onClick={envia_factura}
                            disabled={!factura.facturar}>Enviar
                    </button>

                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default UpdateFactura;