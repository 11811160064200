import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import ContactoInline from "./ContactoInline";
import {authHeaders} from "../Utils/authUtils";

import './Pacientes.css';
import CreateContacto from "./CreateContacto";



function Contactos(props) {
    const [contactosList, setContactosList] = useState([])


    function addContacto(contacto) {

        setContactosList(previous => {
            return [...previous, contacto]
        })
    }

    function delete_contacto(contacto) {
        setContactosList(prevState => prevState.filter(cont => contacto.id !== cont.id))
    }

    useEffect(() => {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/contactos/?q=' + props.alias
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setContactosList(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }, [props]
    );

    return (
        <div>
            <div className="row bg-primary w-100 m-0 p-2">
                <div className="col-1">
                    <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-primary text-white"
                            data-toggle="modal"
                            data-target="#createContactoModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                </div>
                <h5 className="col-11  text-white">Contactos</h5>
            </div>
            <table className="bg-white table">
                <thead>
                <tr className="">
                    <th width="25%">
                        Contacto
                    </th >
                    <th width="15%">Relación</th>
                    <th width="18%">Teléfono</th>
                    <th width="26%">Email</th>
                    <th width="12%">Alta</th>
                    <th width="4%"/>
                </tr>
                </thead>
                <tbody>
                {contactosList != null && contactosList.map(contacto =>
                    <ContactoInline
                        key={contacto.id}
                        id={contacto.id}
                        contacto={contacto}
                        delete_contacto={delete_contacto}/>
                )}
                </tbody>
            </table>
            <CreateContacto
                modalId="createContactoModal"
                addContacto={addContacto}
                paciente={props.paciente}
            />

        </div>
    );
}

export default Contactos;