import React, {useEffect, useState} from 'react';
import {useMountedState} from 'react-use';

import './Paciente.css';
import {APIPATH} from "../settings";
import {Redirect, useParams} from "react-router-dom"

import Identificacion from "./Identificacion";
import {authHeaders} from "../Utils/authUtils";
import ModalMsg from "../Utils/ModalMsg";
import Anamnesis from "./Anamnesis/Anamnesis";
import Medicacion from "../Medicaciones/Medicacion";
import TerapiaPacienteView from "./TerapiaPacienteView";
import Notas from "../Notas/Notas";
import Pautas from "../Notas/Pautas";
import ContratosPaciente from "../Contratos/ContratosPaciente"
import "./Paciente.css"

function Paciente(props) {
    let {has_historial, is_contabilidad} = sessionStorage.getItem('userToken') !== null && JSON.parse(sessionStorage.getItem('userToken'));
    const isMounted = useMountedState();
    let paciente = {}
    const [volver, setvolver] = useState(false)
    const [actualFields, setActualFields] = useState([])
    const [laboralFields, setLaboralFields] = useState([])
    const [consumoFields, setConsumoFields] = useState([])
    const [psicoFields, setPsicoFields] = useState([])
    const [familiarFields, setFamiliarFields] = useState([])
    const [navegacion, setNavegacion] = useState('')
    const [state, setState] = useState({
        paciente: paciente,
        noIden: false
    })
    const [modalMsg, setModalMsg] = useState({
        titulo: "",
        mensaje: "",
        show: false
    })
    const [actual, setActual] = useState({})
    const [laboral, setLaboral] = useState({})
    const [consumo, setConsumo] = useState({})
    const [psico, setPsico] = useState({})
    const [familiar, setFamiliar] = useState({})
    let {id} = useParams()

    useEffect(() => {
            let iniActual = ['paciente', 'enfermedades', 'alergias',
                'motivo_consulta', 'inicio_y_curso', 'episodios_previos',
                'factores_desencadenantes', 'autodescripcion', 'cambio', 'expectativas', 'informe_actual']
            let iniLaboral = ['paciente', 'tiene_secundarios', 'tiene_superiores', 'estudios_primarios_secundarios',
                'estudios_superiores', 'problemas_legales', 'profesion', 'relacciones_trabajo', 'ambiente_laboral',
                'cambios_profesion', 'sexualidad', 'inicio_conducta_sexual', 'orientacion_sexual', 'situacion_socio_economica',
                'habitos_intereses', 'alter_personalidad', 'alter_psico_fisio', 'estilo_relacional',
                'capacidad_relacional', 'conflictividad_relacional', 'informe_personal']
            let iniConsumo = ['paciente', 'tabaco', 'alcohol', 'cannabis', 'cocaina', 'heroina',
                'sintesis', 'benzodiacepinas', 'adiccion_sexo', 'adiccion_trabajo',
                'adiccion_juego', 'adiccion_compras', 'adiccion_tecnologia', 'motivacionConsumo', 'informe_consumo']
            let iniPsico = ['paciente', 'conducta_infancia', 'conducta_adolescencia', 'desarrollo_voluntad', 'persistencia_esfuerzo',
                'relacciones_interpersonales', 'jerarquia_valores', 'psicoBiografia', 'informe_psico']
            let iniFamiliar = ['paciente', 'tipo_padre', 'padre', 'tipo_madre', 'madre', 'tipo_hermanos',
                'hermanos', 'tipo_hijos', 'hijos', 'tipo_otros', 'otros', 'tipo_pareja', 'pareja',
                'noviazgos', 'matrimonios', 'vida_matrimonial', 'informe_familiar']


            if (isMounted()) {
                setActualFields(iniActual)
                setLaboralFields(iniLaboral)
                setConsumoFields(iniConsumo)
                setPsicoFields(iniPsico)
                setFamiliarFields(iniFamiliar)
            }
            let anamnesis = {}
            let paciente = {}
            if (id === undefined) {
                paciente = new Promise(resolve => {
                    resolve({})
                })

            } else {
                paciente = new Promise(resolve => {
                    authHeaders().then(authHeader => {
                        const endpoint = APIPATH + 'api/pacientes/detail/' + id + '/'
                        const lookupOptions = {
                            method: "GET",
                            headers: {
                                'Content-Type': 'application/json',
                                ...authHeader
                            },
                            credentials: 'include',

                        }

                        fetch(endpoint, lookupOptions).then(response => {
                                if (response.ok) {
                                    response.json().then(response => {
                                        resolve(response)
                                    })
                                } else {
                                    response.json().then(response => {
                                        if (isMounted()) {
                                            let mensaje = ""
                                            Object.keys(response).forEach(key => {
                                                mensaje = mensaje + "   " + response[key]
                                            })
                                            setModalMsg({
                                                titulo: "No se pudo localizar paciente",
                                                mensaje: mensaje,
                                                show: true
                                            })
                                            setModalMsg(previous => {
                                                return {...previous, show: false}
                                            })
                                        }
                                    })
                                }
                            }
                        ).catch(
                            error => {
                                console.log("error", error)
                            }
                        )
                    })
                })
                anamnesis = new Promise(resolve => {
                    authHeaders().then(authHeader => {
                            const endpoint = APIPATH + 'api/pacientes/anamnesis/detail/' + id + '/'
                            const lookupOptions = {
                                method: "GET",
                                headers: {
                                    'Content-Type': 'application/json',
                                    ...authHeader
                                },
                                credentials: 'include',

                            }

                            fetch(endpoint, lookupOptions).then(response => {
                                if (response.ok) {
                                    response.json().then(response => {
                                        resolve(response)
                                    })
                                } else {
                                    console.log(response.status)
                                    if ([401, 403, 404].indexOf(response.status) !== -1) {
                                        resolve("")
                                    }
                                }
                            }).catch(
                                error => {
                                    console.log("error", error)
                                }
                            )
                        }
                    )
                })
            }
            Promise.all([paciente, anamnesis]).then(
                ([paciente, anamnesis]) => {
                    Object.keys(anamnesis).forEach(key => {
                        if (anamnesis === null) {
                            anamnesis[key] = ""
                        }
                    })
                    if (isMounted()) {
                        setState({paciente: paciente, noIden: false})
                        inicializa_anamnesis(anamnesis, iniActual, iniLaboral, iniConsumo, iniPsico, iniFamiliar)
                    }
                })
        },
        [id, isMounted]
    )

    function inicializa_anamnesis(anamnesis, iniActual, iniLaboral, iniConsumo, iniPsico, iniFamiliar) {

        const anamnesisActual = {}
        iniActual.forEach(key => {
            anamnesisActual[key] = anamnesis[key]
        })
        setActual(anamnesisActual)
        const anamnesisLaboral = {}
        iniLaboral.forEach(key => {
            anamnesisLaboral[key] = anamnesis[key]
        })
        setLaboral(anamnesisLaboral)
        const anamnesisConsumo = {}
        iniConsumo.forEach(key => {
            anamnesisConsumo[key] = anamnesis[key]
        })
        setConsumo(anamnesisConsumo)
        const anamnesisPsico = {}
        iniPsico.forEach(key => {
            anamnesisPsico[key] = anamnesis[key]
        })
        setPsico(anamnesisPsico)
        const anamnesisFamiliar = {}
        iniFamiliar.forEach(key => {
            anamnesisFamiliar[key] = anamnesis[key]
        })
        setFamiliar(anamnesisFamiliar)
    }

    function update_paciente(paciente) {
        paciente.alias = paciente.alias.replace(/ /g, "_");
        let pacienteFetch = {}
        if (paciente.id === 0 || paciente.id === undefined) {
            if (!(paciente.foto instanceof File)) {
                delete paciente.foto
            }
            delete paciente.id
            pacienteFetch = new Promise(resolve => {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/'
                    let form_data = new FormData()
                    Object.keys(paciente).forEach(key => {
                        form_data.append(key, paciente[key])
                    })
                    const lookupOptions = {
                        method: "POST",
                        headers: {
                            // 'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        body: form_data
                    }
                    fetch(endpoint, lookupOptions).then(response => {
                            if (response.ok) {
                                response.json().then(response => {
                                    resolve(response)
                                })
                            } else {
                                response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + "   /  " + key + " : " + response[key]
                                        })
                                        setModalMsg({
                                            titulo: "Error al guardar paciente",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                    resolve("")
                                })
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            })
        } else {
            if (!(paciente.foto instanceof File)) {
                delete paciente.foto
            }
            let form_data = new FormData()
            Object.keys(paciente).forEach(key => {
                form_data.append(key, paciente[key])
            })
            pacienteFetch = new Promise(resolve => {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/detail/' + paciente.id + '/'
                    const lookupOptions = {
                        method: "PUT",
                        headers: {
                            // 'Content-Type': 'application/json',
                            // 'Content-Type': 'multipart/form-data',
                            ...authHeader
                        },
                        credentials: 'include',
                        // body: JSON.stringify(paciente)
                        body: form_data

                    }

                    fetch(endpoint, lookupOptions).then(response => {
                            if (response.ok) {
                                response.json().then(response => {
                                    resolve(response)
                                })
                            } else {

                                response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + "  /  " + key + ": " + +response[key]
                                        })
                                        setModalMsg({
                                            titulo: "No se pudo guardar paciente",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                    resolve("")
                                })
                            }

                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            })
        }

        Promise.resolve(pacienteFetch).then(pacienteRes => {
                setNavegacion("identificacion")
                if (isMounted && pacienteRes !== "") {
                    if (actual.paciente === null || actual.paciente === undefined) {
                        let anamnesis = {
                            paciente: pacienteRes.id,
                            enfermedades: "",
                            alergias: "",
                            motivo_consulta: "",
                            inicio_y_curso: "",
                            episodios_previos: "",
                            factores_desencadenantes: "",
                            autodescripcion: "",
                            cambio: "",
                            expectativas: [],
                            tipo_padre: "",
                            padre: "",
                            tipo_madre: "",
                            madre: "",
                            tipo_hermanos: "",
                            hermanos: "",
                            tipo_hijos: "",
                            hijos: "",
                            tipo_otros: "",
                            otros: "",
                            tipo_pareja: "",
                            pareja: false,
                            noviazgos: "",
                            matrimonios: "",
                            vida_matrimonial: "",
                            tiene_secundarios: true,
                            tiene_superiores: false,
                            estudios_primarios_secundarios: "",
                            estudios_superiores: "",
                            problemas_legales: "",
                            profesion: "",
                            relacciones_trabajo: "",
                            ambiente_laboral: "",
                            cambios_profesion: "",
                            sexualidad: true,
                            inicio_conducta_sexual: "",
                            orientacion_sexual: "",
                            situacion_socio_economica: "",
                            habitos_intereses: "",
                            alter_personalidad: [],
                            alter_psico_fisio: [],
                            estilo_relacional: "",
                            capacidad_relacional: "",
                            conflictividad_relacional: "",
                            conducta_infancia: "",
                            conducta_adolescencia: "",
                            desarrollo_voluntad: "",
                            persistencia_esfuerzo: "",
                            relacciones_interpersonales: "",
                            jerarquia_valores: "",
                            psicoBiografia: [],
                            tabaco: "",
                            alcohol: "",
                            cannabis: "",
                            cocaina: "",
                            heroina: "",
                            sintesis: "",
                            benzodiacepina: "",
                            adiccion_sexo: false,
                            adiccion_trabajo: false,
                            adiccion_juego: false,
                            adiccion_compras: false,
                            adiccion_tecnologia: false,
                            informe_actual: "",
                            informe_psico: "",
                            informe_consumo: "",
                            informe_familiar: "",
                            informe_personal: "",
                            informe_diagnostico: "",
                            motivacionConsumo: [],

                        }

                        authHeaders().then(authHeader => {
                            const endpoint = APIPATH + 'api/pacientes/anamnesis/create/'
                            const lookupOptions = {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    ...authHeader
                                },
                                credentials: 'include',
                                body: JSON.stringify(anamnesis)
                            }

                            fetch(endpoint, lookupOptions).then(response => {
                                    if (response.ok) {
                                        response.json().then(response => {
                                            if (isMounted()) {
                                                setState(previous => ({...previous, paciente: pacienteRes}))
                                                inicializa_anamnesis(response, actualFields, laboralFields,
                                                    consumoFields, psicoFields, familiarFields)
                                                setModalMsg({
                                                    titulo: "Paciente Guardado",
                                                    mensaje: "Paciente Guardado con éxito",
                                                    show: true
                                                })
                                                setModalMsg(previous => {
                                                    return {...previous, show: false}
                                                })

                                            }
                                        })
                                    } else {
                                        response.json().then(response => {
                                                if (isMounted()) {
                                                    setState(previous => ({...previous, paciente: pacienteRes}))
                                                    inicializa_anamnesis(response, actualFields, laboralFields,
                                                        consumoFields, psicoFields, familiarFields)
                                                    setModalMsg({
                                                        titulo: "Paciente Guardado",
                                                        mensaje: "Paciente Guardado con éxito",
                                                        show: true
                                                    })
                                                    setModalMsg(previous => {
                                                        return {...previous, show: false}
                                                    })
                                                }
                                                // if (isMounted()) {
                                                //     let mensaje = ""
                                                //     Object.keys(response).forEach(key => {
                                                //         mensaje = mensaje + "   /  " + key + " : " + response[key]
                                                //     })
                                                //     setModalMsg({
                                                //         titulo: "Error al guardar Anamnesis",
                                                //         mensaje: mensaje,
                                                //         show: true
                                                //     })
                                                //     setModalMsg(previous => {
                                                //         return {...previous, show: false}
                                                //     })
                                                // }
                                            }
                                        )

                                    }
                                }
                            ).catch(
                                error => {
                                    console.log("error", error)
                                }
                            )
                        })
                    } else {
                        if (isMounted()) {
                            setModalMsg({
                                titulo: "Paciente Guardado",
                                mensaje: "Paciente Guardado con éxito",
                                show: true
                            })
                            setModalMsg(previous => {
                                return {...previous, show: false}
                            })
                            setState(previous => ({...previous, paciente: pacienteRes}))
                        }
                    }

                }
            }
        )

    }

    function anamnesis_states(name, value) {
        if (actualFields.includes(name)) {
            setActual(previous => ({...previous, [name]: value}))
        } else if (laboralFields.includes(name)) {
            setLaboral(previous => ({...previous, [name]: value}))
        } else if (consumoFields.includes(name)) {
            setConsumo(previous => ({...previous, [name]: value}))
        } else if (psicoFields.includes(name)) {
            setPsico(previous => ({...previous, [name]: value}))
        } else if (familiarFields.includes(name)) {
            setFamiliar(previous => ({...previous, [name]: value}))
        }
    }

    function update_anamnesis(anamnesis) {

        if (anamnesis.paciente === null || anamnesis.paciente === undefined) {

            anamnesis.paciente = paciente.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/anamnesis/create/'

                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(anamnesis)
                }

                fetch(endpoint, lookupOptions).then(response => {
                        if (response.ok) {
                            response.json().then(response => {
                                if (isMounted()) {
                                    const nameUpdated = Object.keys(anamnesis).pop('paciente')
                                    anamnesis_states(nameUpdated, anamnesis[nameUpdated])
                                }
                            })
                        } else {
                            response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + "   /  " + key + " : " + response[key]
                                        })
                                        setModalMsg({
                                            titulo: "Error al guardar Anamnesis",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                }
                            )

                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/anamnesis/detail/' + state.paciente.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(anamnesis)
                }

                fetch(endpoint, lookupOptions).then(response => {
                        if (response.ok) {
                            response.json().then(response => {
                                if (isMounted()) {
                                    const nameUpdated = Object.keys(anamnesis).pop('paciente')
                                    anamnesis_states(nameUpdated, anamnesis[nameUpdated])
                                }
                            })
                        } else {
                            response.json().then(response => {
                                if (isMounted()) {
                                    let mensaje = ""
                                    Object.keys(response).forEach(key => {
                                        mensaje = mensaje + " " + key + ": " + response[key]
                                    })
                                    setModalMsg({
                                        titulo: "No se pudo guardar Anamnesis",
                                        mensaje: mensaje,
                                        show: true
                                    })
                                    setModalMsg(previous => {
                                        return {...previous, show: false}
                                    })
                                }
                            })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })

        }

    }

    function handle_noIden(value) {
        if (isMounted()) {
            setState(previous => ({...previous, noIden: value}))
        }
    }

    function handle_tabs(e) {
        setNavegacion(e.target.id)
    }


    paciente = state.paciente

    return (
        <div className="row container-fluid pestanas">
            {volver && <Redirect to="/"/>}
            <div className="paciente-icono row m-0 p-0">

                <div className="col-2 ml-3 p-0 ">
                    <img src={state.paciente.foto} className="img-fluid" alt="Foto ID"/>
                </div>
                <div className="col-9 pt-3  text-primary">
                    <h5>{state.paciente.alias}</h5>
                </div>
                <div className="col-1 ">


                </div>
            </div>

            <div className={`mr-auto mt-4 col-12`}>
                <ul className="nav nav-tabs " id="myTab" role="tablist">

                    <li>
                        <button className="border-0" onClick={(e) => {
                            e.preventDefault()
                            if (isMounted()) {
                                setNavegacion("")
                                setvolver(true)
                            }
                        }}>
                            <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-box-arrow-in-left"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"/>
                                <path fillRule="evenodd"
                                      d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"/>
                            </svg>
                        </button>
                    </li>
                    <li className="nav-item " role="presentation">
                        <button className="nav-link  border-right border-left border-top active" id="identificacion"
                                data-toggle="tab" role="tab"
                                aria-selected="true" onClick={handle_tabs}>Identificación
                        </button>
                    </li>
                    {has_historial && state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className="nav-link  border-right border-left border-top " id="anamnesis"
                                data-toggle="tab" role="tab"
                                aria-selected="true" onClick={handle_tabs}>Anamnesis
                        </button>
                    </li>
                    }
                    {has_historial && state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className={`nav-link border-right border-left border-top`}
                                id="terapias" data-toggle="tab" role="tab"
                                aria-selected="false" onClick={handle_tabs}>Terapias
                        </button>
                    </li>}
                    {state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className="nav-link border-right border-left border-top " id="medicacion"
                                data-toggle="tab" role="tab"
                                aria-selected="false" onClick={handle_tabs}>Medicación
                        </button>
                    </li>
                    }
                    {state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className="nav-link border-right border-left border-top " id="notas" data-toggle="tab"
                                role="tab"
                                aria-selected="false" onClick={handle_tabs}>Intervenciones
                        </button>
                    </li>
                    }
                    {state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className="nav-link border-right border-left border-top " id="pautas" data-toggle="tab"
                                role="tab"
                                aria-selected="false" onClick={handle_tabs}>Pautas
                        </button>
                    </li>

                    }
                    {is_contabilidad && state.paciente.id !== undefined &&
                    <li className="nav-item " role="presentation">
                        <button className={`nav-link border-right border-left border-top`}
                                id="contratos" data-toggle="tab" role="tab"
                                aria-selected="false" onClick={handle_tabs}>Contratos
                        </button>
                    </li>}
                    {/*{state.paciente.id !== undefined &&*/}
                    {/*<li className="nav-item " role="presentation">*/}
                    {/*    <button className="nav-link border-right border-left border-top " id="calendario"*/}
                    {/*            data-toggle="tab" role="tab"*/}
                    {/*            aria-selected="false" onClick={handle_tabs}>Calendario*/}
                    {/*    </button>*/}
                    {/*</li>*/}
                    {/*}*/}
                    {/*{state.paciente.id !== undefined &&*/}
                    {/*<li className="nav-item " role="presentation">*/}
                    {/*    <button className="nav-link border-right border-left border-top " id="contabilidad"*/}
                    {/*            data-toggle="tab" role="tab"*/}
                    {/*            aria-selected="false" onClick={handle_tabs}>Contabilidad*/}
                    {/*    </button>*/}
                    {/*</li>*/}
                    {/*}*/}
                </ul>


                <div className="tab-content " id="myTabContent">
                    {/*{has_historial && state.paciente.id !== undefined &&*/}
                    {navegacion === 'anamnesis' &&
                    <div
                        className={`tab-pane fade ${has_historial && state.paciente.id !== undefined ? "show active" : ""} bg-white  h-100 p-0`}>
                        <Anamnesis
                            alias={state.paciente.alias}
                            actual={actual}
                            laboral={laboral}
                            consumo={consumo}
                            psico={psico}
                            familiar={familiar}
                            updateAnamnesis={update_anamnesis}
                        />
                    </div>}

                    {navegacion === 'identificacion' &&
                    <div className={` bg-white  h-100 p-0`}>
                        <Identificacion
                            paciente={state.paciente}
                            update={update_paciente}
                            noIden={handle_noIden}
                        />
                    </div>
                    }
                    {navegacion === 'terapias' &&
                    <div
                        className={`tab-pane fade ${has_historial && state.paciente.id !== undefined ? "show active" : ""} bg-white  h-100 p-0`}>
                        <TerapiaPacienteView
                            paciente={state.paciente.id}
                        />
                    </div>
                    }
                    {navegacion === 'medicacion' &&
                    <div className={`  bg-white  h-100 p-0`}><Medicacion
                        alias={state.paciente.alias}
                        paciente={state.paciente.id}
                        control_medicacion={state.paciente.control_medicacion}
                    /></div>
                    }
                    {navegacion === 'notas' &&
                    <div className={`bg-white  h-100 p-0`}>
                        <Notas
                            centro={state.paciente.centro}
                            is_max={true}
                            paciente={state.paciente.id}
                        />
                    </div>
                    }
                    {navegacion === 'pautas' &&
                    <div className={`bg-white  h-100 p-0`}>
                        <Pautas
                            centro={state.paciente.centro}
                            is_max={true}
                            paciente={state.paciente.id}
                        />
                    </div>
                    }
                    {navegacion === 'contratos' &&
                    <div className={`bg-white  h-100 p-0`}>
                        <ContratosPaciente
                            paciente={state.paciente}
                        />
                    </div>
                    }
                    {navegacion === 'calendario' &&
                    <div className={` bg-white  h-100 p-0`}>calendario</div>
                    }
                    {navegacion === 'contabilidad' &&
                    <div className={`  bg-white  h-100 p-0`}>contabilidad</div>
                    }
                    {navegacion === '' &&
                    <div>
                        <img src={`${APIPATH}static/media/paciente.jpg`} alt='' className="imagen-fondo"/>
                    </div>
                    }

                </div>
            </div>

            <ModalMsg
                titulo={modalMsg.titulo}
                mensaje={modalMsg.mensaje}
                show={modalMsg.show}
                modalId="msgPaciente"
            />
        </div>
    )

}

export default Paciente;
