import React, {useState, useEffect} from "react";
import moment from "moment";
import "./Contratos.css"
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";

function ContratoPacienteInline(props) {
    const [contrato, setContrato] = useState({
        id: "",
        contrato: "",
        paciente: "",
        fecha: moment(new Date()).format('YYYY-MM-DD'),
        importe: 0,
        suplidos: 0,
    })

    useEffect(() => {
        setContrato(props.contrato)
    }, [props])

    function handle_input(e) {
        let {name, value} = e.target
        let valid = true
        if (['importe', 'suplidos'].includes(name) && !(!isNaN(parseFloat(value)) && isFinite(value)) && value !== "") {
            valid = false
        }
        if (valid) {
            setContrato(prev => ({...prev, [name]: value}))
        }
    }

    function save_contrato() {

        const data = {...contrato}
        if (data.contacto === 0) {
            data.contacto = ""
        }
        if (contrato.id === "") {
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contratos/paciente/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data),
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        console.log(response)
                        setContrato(response)
                        props.reload()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contratos/paciente/detail/' + contrato.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data),
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setContrato(response)
                        props.reload()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    function imprime(e) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contratos/paciente/getpdf/' + contrato.id + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',

            }

            fetch(endpoint, lookupOptions).then(response =>
                response.json()
            ).then(response => {
                const url = APIPATH + 'pdf/contrato/' + response.token + '/'
                window.open(url);
            })
                .catch(error => {
                    console.log(error);
                });
        })
    }

    return (
        <tr>
            <td>
                <select value={contrato.contrato} onChange={handle_input} name="contrato" className="form-control ">
                    <option value="">----</option>
                    {props.contratos.map(contrato =>
                        <option key={contrato.id} value={contrato.id}>{contrato.nombre}</option>
                    )}
                </select>
            </td>
            <td>
                <input type='date' id="fecha" required
                       className="form-control "
                       name="fecha"
                       value={contrato.fecha}
                       onChange={handle_input}/>
            </td>
            <td>
                <select value={contrato.contacto ? contrato.contacto : 0} onChange={handle_input} name="contacto"
                        className="form-control ">
                    <option value={0}>---</option>
                    {props.contactos.map(contacto =>
                        <option key={contacto.id} value={contacto.id}>{contacto.nombre}</option>
                    )}
                </select>
            </td>

            <td>
                <input type='text' required
                       className=" form-control "
                       name="importe"
                       value={contrato.importe}
                       onChange={handle_input}/>
            </td>
            <td>
                <input type='text' required
                       className="form-control "
                       name="suplidos"
                       value={contrato.suplidos}
                       onChange={handle_input}/>
            </td>
            <td>
                <button type="button" className="link-button text-success" disabled={contrato.contrato === ""} onClick={save_contrato}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-pencil" viewBox="0 0 16 16">
                        <path
                            d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                </button>
            </td>
            <td>
                {contrato.id !== "" &&
                <button type="button" className="link-button text-success"
                        onClick={imprime}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-printer" viewBox="0 0 16 16">
                        <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                        <path
                            d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                    </svg>
                </button>
                }
            </td>
        </tr>
    )
}

export default ContratoPacienteInline