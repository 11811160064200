import React, {useState, useEffect} from "react";
import "./Terapias.css"

function AsistenteInline(props) {
    const [asistente, setAsistente] = useState({
        nombre: "",
        asistente: false,
        programado: false,
        participacion: 0
    })

    useEffect(() => {
        setAsistente(props.asistente)
    }, [props])

    function handle_check(e) {
        const {name, checked} = e.target
        props.update({...asistente, [name]: checked})
        setAsistente({...asistente, [name]: checked})
    }

    function handle_input(e) {
        const {name, value} = e.target
        /* eslint eqeqeq: 0 */
        if (value === "" || (value == parseInt(value, 10) && parseInt(value) < 6)) {

            setAsistente(prev => ({...prev, [name]: value}))
        }
    }
    function handle_blur(e) {
        props.update(asistente)
    }

    return (
        <div className={`row asistente-inline border-bottom border-secondary ${props.selected && 'asistente-selected'}`}>
            <div className="col-2">
                {asistente.img !== undefined && asistente.img !== null &&
                <img src={`${asistente.img}`} className="img-fluid" alt=""/>}
            </div>
            <button className={`text-primary col-4 text-medium btn-nofocus ${props.selected && 'asistente-selected'}`}
                    onClick={() => {props.select(asistente)}}>{asistente.nombre}</button>
            <input type="checkbox" className="mt-2 mr-2" name="asistente" checked={asistente.asistente}
                   onChange={handle_check}/>
            Asiste?
            <div className="col-2">
                Participa
                <input type="text" className="w-50" name="participacion" value={asistente.participacion}
                       onChange={handle_input} onBlur={handle_blur}/>
            </div>
            {!asistente.programado &&
            <div className="col-1 mt-2">
              <button className="btn btn-warning" onClick={() => {props.delete(asistente)}}>
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fillRule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                </svg>
              </button>

            </div>}
        </div>
    )
}

export default AsistenteInline