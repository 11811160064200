import React, {useEffect, useState} from 'react'
import Fichero from "../Utils/Fichero";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import PacienteFileInline from "./PacienteFileInline";

function PacienteIdFiles(props) {
    const [fileList, setFileList] = useState([])

    function delete_file(pacienteFile) {
        setFileList(prevState => prevState.filter(pfile => pfile.id !== pacienteFile.id))
    }

    function uploadFile(imageBase64, pacienteFile) {
        let form_data = new FormData()
        form_data.append('paciente', props.paciente.id)
        form_data.append('tipo', 'ID')
        form_data.append('fichero', pacienteFile)
        form_data.append('descripcion', pacienteFile.name)


        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/ficheros/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    // 'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data',
                    ...authHeader
                },
                credentials: 'include',
                // body: JSON.stringify(paciente)
                body: form_data

            }

            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            setFileList(prevState => [response, ...prevState])
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    useEffect(() => {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/ficheros/?alias=' + props.paciente.alias
                    + '&tipo=ID'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setFileList(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }, [props]
    );


    return (
        <div className="">
            <div className="row bg-primary w-100 m-0 p-2">
            <button type="button" className=" mr-1 mt-0 pt-1 bg-primary border-0 text-white"
                    data-toggle="modal"
                    data-target="#ficheroIdModal">
                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path fillRule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
            </button>
                <h5 className="text-white ml-5">Ficheros Adjuntos</h5>
            </div>
            <div>
                {fileList != null && fileList.map(pacienteFile =>
                    <PacienteFileInline
                        key={pacienteFile.id}
                        pacienteFile={pacienteFile}
                        delete_file={delete_file} />
                )}
            </div>
            <Fichero
                modalId="ficheroIdModal"
                uploadFile={uploadFile}
            />
        </div>
    )
}

export default PacienteIdFiles;