import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export const PrivateRoute = ({ component: Component, privateProps,...rest }) => {
    const access = JSON.parse(sessionStorage.getItem('userToken'))

    return <Route {...rest} render={props => {

        if (!access) {
            // not logged in so redirect to login page with the return url
            return <Redirect to={{ pathname: '/tera/login', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} {...privateProps} />
    }} />
}