import React, {useState} from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {APIPATH} from "../settings";
import {authHeaders} from "../Utils/authUtils";
import NuevaTerapia from "./NuevaTerapia";

function Calendario(props) {
    const [currentEvents, setCurrentEvents] = useState([])
    const [showNuevaTerapia, setShowNuevaTerapia] = useState(false);
    const [fechaNueva, setFechaNueva] = useState("")
    const handleDateSelect = (selectInfo) => {
        setFechaNueva(selectInfo.startStr+'T00:00')
        setShowNuevaTerapia(true)
        setShowNuevaTerapia(false)
    };

    const handleEventClick = (clickInfo) => {
        clickInfo.jsEvent.preventDefault()
        const {startStr, url} = clickInfo.event
        const tipo = url.substr(0, 1)
        const id = parseInt(url.substr(1))
        if (tipo === "p") {
            props.setProgramacion(id, startStr)
        } else {
            props.setTerapia(id)
        }

    };

    const handleEvents = (events) => {
        // setCurrentEvents(events
        // );
    };

    function viewRender(dates) {
        const {start, end} = dates
        const data = {
            centro: props.centro.id,
            start: moment(start).format('YYYY-MM-DD'),
            end: moment(end).format('YYYY-MM-DD')
        }
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/query/'
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    const eventos = response.eventos.map((evento, index) =>
                        ({
                            id: String(index),
                            groupId: evento.programacion_dia,
                            url: evento.url,
                            start: evento.date,
                            title: evento.nombre,
                            // backgroundColor: evento.url.substring(0,1) === 'p' ? '#a3c4d7' : '#000000',
                            color: 'purple'
                        })
                    )
                    setCurrentEvents(eventos)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })


    }

    function renderEventContent(eventInfo) {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <i>{eventInfo.event.title}</i>
            </>
        );
    }

    return (
        <div className="">
            <div className="calendario">
                {props.centro.id !== undefined &&
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "dayGridMonth,dayGridWeek,dayGridDay"
                    }}
                    initialView="dayGridWeek"
                    locale={esLocale}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    datesSet={viewRender}
                    events={currentEvents} // alternatively, use the `events` setting to fetch from a feed
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
                    eventAdd={function(){}}
                    eventChange={function(){}}
                    eventRemove={function(){}}
                    */
                />}
            </div>
            <NuevaTerapia
                fecha={fechaNueva}
                centro={props.centro.id}
                show={showNuevaTerapia}
                modalId="NuevaTerapia"
                setProgramacion = {props.setProgramacion}
            />
        </div>
    );


}

export default Calendario