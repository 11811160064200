import React, {useEffect, useState} from 'react';
import {APIPATH} from "../settings";
import SelectSearch from 'react-select-search';
import moment from 'moment';
import {authHeaders} from "../Utils/authUtils";
import Foto from "./Foto";
import Contactos from "./Contactos";
import PacienteIdFiles from "./PacienteIdFiles"
import DatosFacturacion from "./DatosFacturacion";
import './Paciente.css';
import "../Utils/select.css"


function Identificacion(props) {
    let cent = JSON.parse(sessionStorage.getItem('centro'));
    const [foto, setFoto] = useState()
    const [userList, setUserList] = useState([])
    const [showFacturacion, setShowFacturacion] = useState(false)
    const [centros, setCentros] = useState([])
    const [accesos, setAccesos] = useState([])
    const [paciente, setPaciente] = useState({
        id: 0,
        alias: "",
        nombre: "",
        apellido1: "",
        apellido2: "",
        fecha_nacimiento: "",
        alta: moment(new Date()).format('YYYY-MM-DD'),
        lugar_nacimiento: "",
        foto: null,
        NIF: "",
        sexo: "",
        estado_civil: "",
        direccion1: "",
        direccion2: "",
        cp: "",
        centro: cent.id,
        localidad: "",
        provincia: "",
        email: "",
        activo: true,
        movil: "",
        fijo: "",
        monitor: "",
        control_medicacion: false,
        registro: true,
        factura_paciente: true,
        factura_nombre: "",
        factura_NIF: "",
        factura_direccion1: "",
        factura_direccion2: "",
        factura_cp: "",
        factura_localidad: "",
        factura_provincia: "",
        factura_email: "",
    })
    // const [formErrors, setFormErrors] = useState({})

    const tipo_estado = {
        soltero: 'Soltero/a',
        casado: 'Casado/a',
        separado: 'Separado/a',
        divorciado: 'Divorciado/a',
        viudo: 'Viudo/a'
    }


    useEffect(() => {

        if (Object.keys(props.paciente).length !== 0) {
            Object.keys(props.paciente).forEach(key => {
                if (props.paciente[key] === null) {
                    props.paciente[key] = ""
                }

            })
            setFoto(props.paciente.foto)
            setPaciente(props.paciente)


            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/acceso/?q=' + props.paciente.alias
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setAccesos(response.map(acceso => acceso.user))
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })

        }
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setUserList(response.filter(profile => profile.user.is_active).map((profile) =>
                        ({id: profile.user.id, name: profile.user.username})
                    ))
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/centro/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setCentros(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [props])


    function handle_input(event) {

        let {name, value, checked} = event.target
        if (['activo', 'control_medicacion', 'factura_paciente', 'registro'].includes(name)) {
            value = checked
        }
        setPaciente(previous => {
            return {...previous, [name]: value}
        })

    }

    function submitForm(event) {
        event.preventDefault()
        props.update(paciente)


    }

    function handle_accesos(value, object) {
        const data = {paciente: paciente.alias, users: value}
        let ok = true
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/acceso/detail/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }

            fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    if (response.ok) {
                        return response.json()
                    } else {
                        return ''
                    }
                }
            ).then(
                response => {
                    if (!ok) {

                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function uploadImage(imageBase64, fotoFile) {
        setFoto(imageBase64)
        setPaciente(previous => {
            return {...previous, foto: fotoFile}
        })
    }

    return (
        <div className="p-4">
            <form onSubmit={submitForm}>
                <div className='row '>
                    <div className="col-2 p-0">
                        <h3>
                            <input type='text' required placeholder="alias"
                                   className={` text-primary col-12 w-100 form-control font-weight-bold text-medium mt-1`}
                                   name="alias" value={paciente.alias} onChange={handle_input}/>

                        </h3>
                        <button type="button" className="border bg-white w-100 p-0 m-0 "
                                data-toggle="modal"
                                data-target="#fotoModal">
                            <img src={foto} className="img-fluid w-100" alt="Foto ID"/>
                        </button>


                    </div>
                    <div className="col-5 px-5 mt-3 border-right">
                        <div className="row">
                            <div className="form-group col-6">
                                <label htmlFor="nombre">Nombre</label>
                                <input type='text' id="nombre" required
                                       className={`w-100 form-control `}
                                       name="nombre" value={paciente.nombre} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-5 ">
                                <label htmlFor="fecha_alta">Fecha alta</label>
                                <input type='date' id={"fecha_alta"} required
                                       className={` w-100 form-control `}
                                       name="alta"
                                       value={paciente.alta}
                                       onChange={handle_input}/>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="form-group col-6">
                                <label htmlFor="apellido1">Primer Apellido</label>
                                <input type='text' id="apellido1" required
                                       className={` w-100 form-control `}
                                       name="apellido1" value={paciente.apellido1} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="apellido2">Segundo Apellido</label>
                                <input type='text' id="apellido2" required
                                       className={`w-100 form-control `}
                                       name="apellido2" value={paciente.apellido2} onChange={handle_input}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-5">
                                <label htmlFor="fecha_nacimiento">Fecha Nacimiento</label>
                                <input type='date' id={"fecha_nacimiento"} required
                                       className={` w-100 form-control `}
                                       name="fecha_nacimiento"
                                       value={paciente.fecha_nacimiento}
                                       onChange={handle_input}/>
                            </div>
                            <div className="form-group col-7">
                                <label htmlFor="lugar_nacimiento">Lugar Nacimiento</label>
                                <input type='text' id="lugar_nacimiento"
                                       className={`w-100 form-control `}
                                       name="lugar_nacimiento" value={paciente.lugar_nacimiento}
                                       onChange={handle_input}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-4">
                                <label htmlFor="NIF">NIF</label>
                                <input type='text' id="NIF"
                                       className={`w-100 form-control `}
                                       name="NIF" value={paciente.NIF} onChange={handle_input}/>
                            </div>
                            <div className="col-4 mt-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="sexo" id="sexo1"
                                           value="hombre" checked={paciente.sexo === "hombre"} onChange={handle_input}/>
                                    <label className="form-check-label" htmlFor="sexo1">
                                        Hombre
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="sexo" id="sexo2"
                                           value="mujer" checked={paciente.sexo === "mujer"} onChange={handle_input}/>
                                    <label className="form-check-label" htmlFor="sexo2">
                                        mujer
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="estado_civil">Estado Civil</label>
                                <select className="form-control " id="estado_civil"
                                        name="estado_civil"
                                        value={paciente.estado_civil} onChange={handle_input}>
                                    <option value=""></option>
                                    {Object.keys(tipo_estado).map(estado => {
                                        return (<option key={estado} value={estado}>{tipo_estado[estado]}</option>)
                                    })}

                                </select>
                            </div>

                        </div>

                    </div>
                    <div className='col-5 px-5'>
                        <div className="form-group  ">
                            <label htmlFor="direccion1">Dirección</label>
                            <input type='text' id="direccion1"
                                   className={`w-100 form-control `}
                                   name="direccion1" value={paciente.direccion1} onChange={handle_input}/>
                            <input type='text' id="direccion2"
                                   className={`mt-1 w-100 form-control `}
                                   name="direccion2" value={paciente.direccion2} onChange={handle_input}/>
                        </div>
                        <div className="row">
                            <div className="form-group col-3">
                                <label htmlFor="cp">Cod.Postal</label>
                                <input type='text' id="cp"
                                       className={` w-100 form-control `}
                                       name="cp" value={paciente.cp} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-5">
                                <label htmlFor="localidad">Localidad</label>
                                <input type='text' id="localidad"
                                       className={` w-100 form-control `}
                                       name="localidad" value={paciente.localidad} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-4 pl-0">
                                <label htmlFor="provincia">Provincia</label>
                                <input type='text' id="provincia"
                                       className={` w-100 form-control `}
                                       name="provincia" value={paciente.provincia} onChange={handle_input}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-5">
                                <label htmlFor="movil">Teléfono móvill</label>
                                <input type='text' id="movil"
                                       className={` w-100 form-control `}
                                       name="movil" value={paciente.movil} onChange={handle_input}/>
                            </div>
                            <div className="form-group col-5">
                                <label htmlFor="fijo">Teléfono fijo</label>
                                <input type='text' id="fijo"
                                       className={` w-100 form-control `}
                                       name="fijo" value={paciente.fijo} onChange={handle_input}/>
                            </div>
                        </div>
                            <div className="row form-group mx-1">

                                <label htmlFor="email">Email</label>
                                <input type='email' id="email"
                                       className={` col-9 w-100 ml-2 form-control `}
                                       name="email" value={paciente.email} onChange={handle_input}/>

                        </div>
                        <div className="row mx-1">
                            <div className="form-check">
                                {/*<input className="form-check-input" type="checkbox" name="registro"*/}
                                {/*       id="registro"*/}
                                {/*       checked={paciente.registro} onChange={handle_input}/>*/}
                                {/*<label className="form-check-label mr-5" htmlFor="registro">*/}
                                {/*    Registrar Suplidos*/}
                                {/*</label>*/}
                                <input className="form-check-input" type="checkbox" name="factura_paciente"
                                       id="factura_paciente"
                                       checked={paciente.factura_paciente} onChange={handle_input}/>
                                <label className="form-check-label" htmlFor="factura_paciente">
                                    Facturar al Paciente
                                </label>
                                {!paciente.factura_paciente &&
                                <button type="button" className="btn btn-outline-primary ml-5"
                                        onClick={() => setShowFacturacion(true)}>
                                    Datos Facturación
                                </button>
                                }
                            </div>
                        </div>
                    </div>


                </div>
                <div className='row'>
                    <div className='col-12'>
                        <hr/>
                    </div>
                </div>


                <div className="row">
                    <div className="col-2 border-right">
                        <div className="form-group row mt-3">
                            <label className="col-3" htmlFor="monitor">Monitor </label>
                            <select className="form-control  bg-white ml-2 col-8" id="monitor" name="monitor"
                                    value={paciente.monitor} onChange={handle_input}>
                                <option value=""></option>
                                {userList.map(user => {
                                    return (<option key={user.id} value={user.id}>{user.name}</option>)
                                })}

                            </select>
                        </div>
                        <div className="form-check col-12 mt-1 border-bottom">
                            <div className="row pl-3">
                                <input className="form-check-input col-1" type="checkbox" checked={paciente.activo}
                                       id="activo"
                                       name="activo"
                                       onChange={handle_input}/>
                                <label className="form-check-label col-3 mr-5" htmlFor="activo">
                                    Activo
                                </label>
                                <div className="col-3 text-primary font-weight-bold mb-2">
                                    {paciente.prospecto ? 'En fase Informativa' : 'Paciente'}
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            Usuarios con Acceso
                            {userList.length > 0 &&
                            <SelectSearch
                                className="select-search mt-2"
                                value={accesos}
                                options={userList.map(user =>
                                    ({name: user.name, value: user.id})
                                )}
                                multiple
                                search
                                printOptions="on-focus"
                                placeholder="Seleccione usuarios"
                                onChange={handle_accesos}
                            />
                            }
                        </div>
                        <div className="form-check col-12 mt-2">
                            <div className="row pl-3">
                                <input className="form-check-input col-1" type="checkbox"
                                       checked={paciente.control_medicacion}
                                       id="control_medicacion"
                                       name="control_medicacion"
                                       onChange={handle_input}/>
                                <label className="form-check-label col-10 mr-5" htmlFor="control_medicacion">
                                    Ctrl. Medicacion
                                </label>

                            </div>
                        </div>
                        <div className="form-group row mt-3">
                            <label className="col-3" htmlFor="centro">Centro</label>
                            <select className="form-control  bg-white ml-2 col-8" id="centro" name="centro" required
                                    value={paciente.centro} onChange={handle_input}>
                                {centros.map(centr => {
                                    return (<option key={centr.id} value={centr.id}>{centr.nombre}</option>)
                                })}

                            </select>
                        </div>
                        <div className="row mt-2">
                            <div className="col-12 mt-1">
                                <button className='btn btn-primary mb-2 w-100 rounded-pill mt-1'>Guardar</button>
                            </div>

                        </div>
                    </div>
                    <div className='col-7 area-contactos border-right'>
                        {props.paciente.id !== undefined &&
                        <Contactos
                            alias={paciente.alias}
                            paciente={paciente.id}
                        />
                        }
                    </div>

                    <div className="col-3 pl-4 area-contactos">
                        {props.paciente.id !== undefined &&
                        <PacienteIdFiles
                            paciente={paciente}
                        />}
                    </div>

                </div>

            </form>
            <Foto
                modalId="fotoModal"
                uploadImage={uploadImage}
            />
            <DatosFacturacion
                show={showFacturacion}
                handleClose={() => setShowFacturacion(false)}
                paciente={paciente}
                setPaciente={(paciente) => setPaciente(paciente)}

            />
        </div>
    )
}

export default Identificacion