import React, {useEffect} from 'react';

function ModalMsg(props) {

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        modal.style.display = "none";
        modal.className="modal fade";
    }

    useEffect(() => {
        if (props.show === true) {
            const saveModal = document.getElementById(props.modalId);
            saveModal.style.display = "block";
            saveModal.style.paddingRight = "17px";
            saveModal.className = "modal fade show";
        }
    })

    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{props.titulo}</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {props.mensaje}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ModalMsg;