import React, {useState, useEffect} from 'react';
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import ConfirmDeleteContacto from "./ConfirmDeleteContacto";
import moment from "moment";
import "./Paciente.css"

function ContactoInline(props) {
    const [contacto, setContacto] = useState({
        nombre: "",
        email: "",
        relacion:"",
        telefono: ""
    })

    useEffect(() => {
        const contacto_clean = {...props.contacto}
        if (Object.keys(props.contacto) > 0) {
            Object.keys(props.contacto).forEach(value => {
                if (contacto_clean[value] === null) {
                    contacto_clean[value] = ""
                }
            })
        }
        setContacto(contacto_clean)
    }, [props])

    function change_contacto(event) {
        let {name, value} = event.target
        const contacto_data = {...contacto, [name]: value}
        setContacto(contacto_data)
        let ok = true
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/contactos/detail/' + contacto_data.id + '/'
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(contacto_data)
            }

            fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    if (response.ok) {
                        return response.json()
                    } else {
                        return ''
                    }
                }
            ).then(
                response => {
                    if (ok) {
                        setContacto(previous => {
                            return {...previous, ...response}
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    function delete_contacto(contacto) {
        props.delete_contacto(contacto)
    }

    return (
        <tr>
            <td><input type='text'
                       className={` bg-light w-100 form-control`} name='nombre'
                       value={contacto.nombre} onChange={change_contacto}/></td>
            <td>
                <input type='text'
                       className={` bg-light w-100 form-control`} name='relacion'
                       value={contacto.relacion} onChange={change_contacto}/>
            </td>
            <td >
                <input type='text'
                       className={` bg-light w-100 form-control`} name='telefono'
                       value={contacto.telefono} onChange={change_contacto}/>
            </td>
            <td >
                <input type='email' name='email'
                       className={` bg-light w-100 form-control`}
                       value={contacto.email} onChange={change_contacto}/>
            </td>
            <td >
                {moment(props.contacto.alta).format('D-MM-YY')}
            </td>
            <td width="1%">

                <div>
                    <button type="button" className={`border-0 bg-white text-danger`} data-toggle="modal"
                            data-target={`#deleteContacto-${props.id}`}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </button>
                    <ConfirmDeleteContacto
                        modalId={`deleteContacto-${props.id}`}
                        contacto={contacto}
                        delete_contacto={delete_contacto}
                    />
                </div>

            </td>

        </tr>
    )
}

export default ContactoInline;

