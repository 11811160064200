import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import PacienteInline from "./PacienteInline";
import {authHeaders} from "../Utils/authUtils";

import './Pacientes.css';


function Pacientes(props) {
    const [pacientesList, setPacientesList] = useState([])
    const [is_max, setIs_max] = useState(false)

    function loadPacientes(inActivos) {
        authHeaders().then(authHeader => {
            let endpoint = ""
            if (props.centro) {
                endpoint = APIPATH + 'api/pacientes/?centro=' + props.centro
                if (!inActivos) {
                    endpoint = endpoint + '&activo=True'
                }
            } else {
                endpoint = APIPATH + 'api/pacientes/'
                if (!inActivos) {
                    endpoint = endpoint + '?activo=True'
                }
            }
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setPacientesList(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function addPaciente(paciente) {

        setPacientesList(previous => {
            return [...previous, paciente]
        })
    }

    // function delete_paciente(paciente) {
    //     setUserList(prevState => prevState.filter(profile => profile.user.username !== user.user.username))
    // }

    useEffect(() => {
            loadPacientes(false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props]
    );

    return (
        <div className="row">
            <div className={`${is_max ? 'col-9 medicamentosArea-max' : 'col-12 medicamentosArea'} bg-white px-0`}>
                <div className="col-12 bg-white m-0">
                    <div className="row">
                        <div className="col-1">
                            <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-white text-success"
                                    onClick={() => {
                                        window.location.assign("/tera/paciente/detail/")
                                    }}>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                    <path fillRule="evenodd"
                                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg>
                            </button>
                        </div>
                        <h3 className="col-4 text-primary">Pacientes</h3>
                        <div className="col-3">

                            <input className="mt-2" type="checkbox" onChange={(e) => {
                                loadPacientes(e.target.checked)
                            }} />
                        <label className="ml-2 mt-2">Inactivos </label>
                        </div>
                        <div className="col-1 ml-auto">
                            <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-white link-button text-success"
                                    onClick={() => {
                                        props.maximizar("pacientes", !is_max)
                                        setIs_max(prev => !prev)
                                    }}>
                                {is_max ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                    </svg> :
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                        <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                        <path
                                            d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <table className="bg-white table ">
                    <thead>


                    <tr>
                        <th colSpan="2" className="text-primary bg-white text-center">
                            Paciente
                        </th>
                        <th className="text-primary bg-white" width={`${is_max ? "5%" : "20%"}`}>Nombre</th>
                        {/*<th className="text-primary bg-white">Monitor</th>*/}
                        <th className="text-center text-primary bg-white">Alta</th>
                        <th colSpan={"2"} className="text-primary bg-white">Activo</th>

                    </tr>
                    </thead>
                    <tbody>
                    {pacientesList != null && pacientesList.map(paciente =>
                        <PacienteInline
                            key={paciente.id}
                            id={paciente.id}
                            paciente={paciente}
                            // delete_paciente={delete_paciente}
                            add_paciente={addPaciente}
                            is_max={is_max}
                            foto_width={props.foto_width}
                        />
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Pacientes;
