import React, {useState} from 'react';
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";


function GruposMovimientoInline(props) {
    const [grupo, setGrupo] = useState(props.grupo)

    function change_grupo(event) {
        let {name, value, checked} = event.target
        if (name === 'activa') {
            value = checked
        }
        const data = {...grupo, [name]: value}
        delete data.id
        let ok = true
        if (grupo.id === "") {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/grupos/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }

                fetch(endpoint, lookupOptions).then(
                    response => {
                        ok = response.ok
                        return response.json()
                    }
                ).then(
                    response => {
                        if (ok) {
                            setGrupo(response)
                            if (props.ultimo) {props.alta()}
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/grupos/detail/' + grupo.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }

                fetch(endpoint, lookupOptions).then(
                    response => {
                        ok = response.ok
                        return response.json()
                    }
                ).then(
                    response => {
                        if (ok) {
                            setGrupo(response)
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    function handle_input(event) {
        let {name, value} = event.target
        setGrupo(previous =>
            ({...previous, [name]: value}))

    }


    return (
        <tr>

            <td>
                <input type='text'
                       className={` bg-light w-100 form-control`}
                       name="nombre"
                       value={grupo.nombre} onChange={handle_input} onBlur={change_grupo}/>
            </td>
            <td width="5%">
                {/*<button*/}
                {/*    className={`border-0 bg-white`}*/}
                {/*    onClick={change_grupo}>*/}
                {/*    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-pencil" fill="currentColor"*/}
                {/*         xmlns="http://www.w3.org/2000/svg">*/}
                {/*        <path fillRule="evenodd"*/}
                {/*              d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>*/}
                {/*    </svg>*/}
                {/*</button>*/}
            </td>

            <td className='text-center'><input type="checkbox" name="activo"
                                               checked={grupo.activo} onChange={change_grupo}/></td>
        </tr>
    )
}

export default GruposMovimientoInline;

