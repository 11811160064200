import React, {useState, useEffect} from 'react';
import ConfirmDeletePrescripcion from "./ConfirmDeletePrescripcion";
import moment from "moment";
import "./Medicaciones.css"

function PrescripcionInline(props) {
    const [prescripcion, setPrescripcion] = useState({
        id: "",
        medicamentos: [],
        nombre_doctor: "",
        fecha:"",
        notas: "",
        paciente: "",
        doctor: ""
    })

    useEffect(() => {

        setPrescripcion(props.prescripcion)
    }, [props])


    return (
        <tr>
            <td className="link-td" onClick={() => {
                props.setPrescripcion(prescripcion)
            }}>

                {moment(prescripcion.fecha).format('D MMM YY')}</td>
            <td>
                {prescripcion.nombre_doctor}
            </td>
            <td>
                <ul>
                {/*{prescripcion.medicamentos.map(med => med.nombre_medicamento.split(' ').slice(0,2).join(' ')).join(", ")}*/}
                {prescripcion.medicamentos.map((med, index) => <li key={index}>{med.nombre_medicamento.substring(0,30)}</li>)}
                </ul>
            </td>

            <td>

                <div>
                    <button type="button" className={`border-0 bg-white text-danger`} data-toggle="modal"
                            data-target={`#deletePrescripcion-${prescripcion.id}`}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                            <path fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                        </svg>
                    </button>
                    <ConfirmDeletePrescripcion
                        modalId={`deletePrescripcion-${prescripcion.id}`}
                        prescripcion={prescripcion}
                        deletePrescripcion={props.deletePrescripcion}
                    />
                </div>

            </td>

        </tr>
    )
}

export default PrescripcionInline;

