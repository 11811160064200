import React, {useState} from 'react';
import 'whatwg-fetch';
import {useDropzone} from "react-dropzone";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import {image64toCanvasRef, extractImageFileExtensionFromBase64, base64StringtoFile} from "../Utils/imageUtils";


import './Foto.css';

function Foto(props) {


    function close_modal() {
        const modal = document.getElementById(props.modalId)
        setImgSrc("")
        setRecortado(false)
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }


    const imageMaxSize = 10000000
    const [imgSrc, setImgSrc] = useState("")
    const [crop, setCrop] = useState({
        aspect: 3 / 2,
        width: 800,

    })
    const [recortado, setRecortado] = useState(false)

    const [fileName, setFileName] = useState()
    const {getRootProps, getInputProps, isDragActive, rejectedFiles, isDragReject} = useDropzone(
        {
            maxSize: imageMaxSize,
            onDrop: handleOnDrop,
            multiple: false,
            accept: 'image/*'
        }
    )
    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > imageMaxSize;
    const imagPreviewCanvas = React.createRef()


    function handleOnDrop(acceptedFiles, rejectedFiles) {

        const resize_width = 800
        if (acceptedFiles && acceptedFiles.length > 0) {
            const currentFile = acceptedFiles[0]
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                    var img = new Image();//create a image
                    img.src = reader.result;//result is base64-encoded Data URI
                    img.onload = function (el) {
                        var elem = document.createElement('canvas');//create a canvas

                        //scale the image to  (width) and keep aspect ratio
                        var scaleFactor = resize_width / el.target.width;
                        elem.width = resize_width;
                        elem.height = el.target.height * scaleFactor;

                        //draw in canvas
                        var ctx = elem.getContext('2d');
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                        //get the base64-encoded Data URI from the resize image
                        setImgSrc(ctx.canvas.toDataURL(el.target, 'image/jpeg', 0));

                    }
                }, false
            )
            reader.readAsDataURL(currentFile)
            setFileName(currentFile.name)
        }
    }

    function handleOnCrop(crop) {
        setCrop(crop)
    }

    function handleImageLoaded(image) {

    }

    function handleOnCropComplete(crop, pixelCrop) {


    }

    function recortar(event) {
        event.preventDefault()
        image64toCanvasRef(imagPreviewCanvas.current, imgSrc, crop)
        setRecortado(true)
    }

    function handleUpload(event) {
        event.preventDefault()
        const canvasRef = imagPreviewCanvas.current
        const fileExtension = extractImageFileExtensionFromBase64(imgSrc)
        const myFilename = fileName
        const imageData64 = canvasRef.toDataURL('image/' + fileExtension)
        const fotoFile = base64StringtoFile(imageData64, myFilename)
        props.uploadImage(imageData64, fotoFile)
        close_modal()
    }

    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title row w-100" id="modalLabel">
                            <h5 className="ml-4">Foto</h5>

                        </div>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <div {...getRootProps({className: `dropArea mx-auto`})} >

                            {imgSrc !== "" ?
                                <div>
                                    {!recortado &&
                                    <ReactCrop
                                        src={imgSrc}
                                        crop={crop}
                                        onChange={handleOnCrop}
                                        onImageLoaded={handleImageLoaded}
                                        onComplete={handleOnCropComplete}
                                    />
                                    }
                                    <canvas ref={imagPreviewCanvas} className={recortado ? "d-block" : "d-none"}/>

                                </div>
                                :

                                    <div>
                                        <input {...getInputProps()} />
                                        <p className="m-3">
                                            {!isDragActive && 'Presione aquí o arrastre una Imagen'}
                                            {isDragActive && !isDragReject && "Puede soltar el fichero"}
                                            {isDragReject && "Fichero no es válido, pruebe con una Imagen"}
                                            {isFileTooLarge && (
                                                <div className="text-danger mt-2">
                                                    Demasiado Grande
                                                </div>
                                            )}
                                        </p>
                                    </div>
                            }


                        </div>


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>

                        {imgSrc !== "" &&
                        (recortado ?
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        setRecortado(false)
                                    }}>Volver a Imagen
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2"
                                            onClick={handleUpload}>Guardar
                                    </button>
                                </div>
                                :
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        setImgSrc("")

                                    }}>Cambiar Imagen
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2"
                                            onClick={recortar}>Recortar
                                        Area
                                    </button>
                                </div>
                        )}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Foto