import React, {useState} from 'react';


import Actual from "./Actual";
import Laboral from "./Laboral";
import Psico from "./Psico";
import Familiar from "./Familiar"
import Consumo from "./Consumo";
import InformeAnamnesis from "./InformeAnamnesis";


import './Anamnesis.css';


function Anamnesis(props) {
    const [navegacion, setNavegacion] = useState('actual')

    function handle_navigation(e) {
        setNavegacion(e.target.id)
    }

    return (
        <div className="row h-100 m-0">
            <div className="col-2 mx-0 px-1 h-100 border-right">
                <div className="nav flex-column nav-pills bg-white " id="v-pills-tab" role="tablist"
                     aria-orientation="vertical">
                    <button
                        className={`border-0 nav-link py-2 mt-5 text-left ${navegacion === 'actual' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="actual" data-toggle="pill"
                        onClick={handle_navigation}>Situacion Actual
                    </button>
                    <ul>
                        <li className="text-small ml-4">
                    <button
                        className={`border-0 nav-link py-2 mt-2 ml-0 pl-1 ${navegacion === 'informeActual' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="informeActual" data-toggle="pill"
                        onClick={handle_navigation}>Informe Actual
                    </button>
                        </li>
                    </ul>
                    <button
                        className={`border-0 nav-link py-2 mt-4 text-left ${navegacion === 'laboral' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="laboral" data-toggle="pill"
                        role="tab" aria-selected="false" onClick={handle_navigation}>Personal / Laboral
                    </button>
                     <ul>
                        <li className="text-small ml-4">
                    <button
                        className={`border-0 nav-link py-2 mt-2 ml-0 pl-1 ${navegacion === 'informePersonal' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="informePersonal" data-toggle="pill"
                        onClick={handle_navigation}>Informe Personal
                    </button>
                        </li>
                    </ul>
                    <button
                        className={`border-0 nav-link py-2 mt-4 text-left ${navegacion === 'consumo' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="consumo" data-toggle="pill"
                        role="tab" aria-selected="false" onClick={handle_navigation}>Consumo/Adicción
                    </button>
                     <ul>
                        <li className="text-small ml-4">
                    <button
                        className={`border-0 nav-link py-2 mt-2 ml-0 pl-1 ${navegacion === 'informeConsumo' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="informeConsumo" data-toggle="pill"
                        onClick={handle_navigation}>Informe Consumo
                    </button>
                        </li>
                    </ul>
                    <button
                        className={`border-0 nav-link py-2 mt-4 text-left ${navegacion === 'psico' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="psico" data-toggle="pill"
                        role="tab" aria-selected="false" onClick={handle_navigation}>Psico-Biografía
                    </button>
                     <ul>
                        <li className="text-small ml-4">
                    <button
                        className={`border-0 nav-link py-2 mt-2 ml-0 pl-1 ${navegacion === 'informePsico' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="informePsico" data-toggle="pill"
                        onClick={handle_navigation}>Informe Psico-Bio
                    </button>
                        </li>
                    </ul>
                    <button
                        className={`border-0 nav-link py-2 mt-4 mb-3 text-left ${navegacion === 'familiar' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="familiar" data-toggle="pill"
                        role="tab" aria-selected="false" onClick={handle_navigation}>Familiar
                    </button>

                    <button
                        className={`border-0 nav-link py-2 mt-4 text-left ${navegacion === 'diagnostico' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="diagnostico" data-toggle="pill"
                        role="tab" aria-selected="false" onClick={handle_navigation}>Diagnóstico
                    </button>
                     <ul>
                        <li className="text-small ml-4">
                    <button
                        className={`border-0 nav-link py-2 mt-2 ml-0 pl-1 ${navegacion === 'informeDiagnostico' ? 'bg-primary text-white' : 'bg-white text-dark'}`}
                        id="informeDiagnostico" data-toggle="pill"
                        onClick={handle_navigation}>Informe Diagnostico
                    </button>
                        </li>
                    </ul>

                </div>
            </div>
            <div className="col-10 area-opciones">
                <div className="tab-content " id="v-pills-tabContent">
                    {navegacion === 'actual' &&
                    <Actual
                        alias={props.alias}
                        anamnesis={props.actual}
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'informeActual' &&
                    <InformeAnamnesis
                        anamnesis={{paciente:props.actual.paciente, informe_actual:props.actual.informe_actual}}
                        cabecera='motivo de la consulta, inicio y curso, episodios previos, factores desencadenantes, autodescripcion, enfermedades y alergias'
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'informePersonal' &&
                    <InformeAnamnesis
                        anamnesis={{paciente:props.actual.paciente, informe_personal:props.laboral.informe_personal}}
                        cabecera='Estudios, hábitos e intereses, situación socio-económica, sexualidad, profesión, ambiente laboral y cambios de profesión'
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                     {navegacion === 'informeConsumo' &&
                    <InformeAnamnesis
                        anamnesis={{paciente:props.actual.paciente, informe_consumo:props.consumo.informe_consumo}}
                        cabecera='consumo'
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                     {navegacion === 'informePsico' &&
                    <InformeAnamnesis
                        anamnesis={{paciente:props.actual.paciente, informe_psico:props.psico.informe_psico}}
                        cabecera='conducta en infancia y adolescencia, desarrollo de la voluntad, persistencia ene l esfuerzo, relaciones interpersonales y jerarquía de valores'
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}

                    {navegacion === 'psico' &&
                    <Psico
                        alias={props.alias}
                        anamnesis={props.psico}
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'consumo' &&
                    <Consumo
                        alias={props.alias}
                        anamnesis={props.consumo}
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'laboral' &&
                    <Laboral
                        alias={props.alias}
                        anamnesis={props.laboral}
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'familiar' &&
                    <Familiar
                        alias={props.alias}
                        anamnesis={props.familiar}
                        update={anamnesis => {
                            props.updateAnamnesis(anamnesis)
                        }}
                    />}
                    {navegacion === 'diagnostico' &&
                    <p>diagnostico</p>
                    }
                </div>
            </div>
        </div>

    )
}

export default Anamnesis