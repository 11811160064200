import React, {useState, useEffect} from 'react';
import {APIPATH} from "../settings";
import {authHeaders} from "../Utils/authUtils";
import {Editor} from "@tinymce/tinymce-react";
import SelectSearch from "react-select-search";
import "../Utils/select.css"
import './Terapias.css'
import AsistenteInline from "./AsistenteInline";
import ModalMsg from "../Utils/ModalMsg";
import TerapiaPaciente from "./TerapiaPaciente";
import TerapiaFiles from "./TerapiaFiles";

function Terapia(props) {
    const [terapia, setTerapia] = useState({
        id: "",
        nombre: "",
        asistentes: [],
        fecha: "",
        comentarios: "",
        programacion: "",
        centro: "",
        terapeuta: ""
    })
    const [userList, setUserList] = useState([])
    const [fileExpanded, setFileExpanded] = useState(false)
    const [viewExternos, setViewExternos] = useState(false)
    const [modalMsg, setModalMsg] = useState({
        titulo: "",
        mensaje: "",
        show: false
    })
    const [externos, setExternos] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [listaPacientes, setListaPacientes] = useState([])
    const [paciente, setPaciente] = useState(null)
    useEffect(() => {
        if (props.terapia.id !== "") {
            setDisabled(false)
        }
        setTerapia(props.terapia)
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setUserList(response.filter(profile => profile.user.is_active).map((profile) =>
                        ({id: profile.user.id, name: profile.user.username})
                    ))
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    const actPacientes = props.terapia.asistentes.map(asistente => asistente.paciente)
                    setExternos(response.filter(paciente => paciente.centro !== props.centro.id))
                    setPacientes(response.filter(paciente => paciente.centro === props.centro.id))
                    setListaPacientes(response.filter(paciente => paciente.centro === props.centro.id).filter(paciente => !actPacientes.includes(paciente.id)).map(paciente => ({
                            id: paciente.id,
                            alias: paciente.alias,
                            foto: paciente.foto
                        })
                    ))

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }, [props])


    function handle_blur(event) {
        let newTerapia = {...terapia}
        if (event.target.name !== undefined) {
            let {name, value} = event.target
            newTerapia = {...terapia, [name]: value}
        }
        setTerapia(newTerapia)
        update_terapia(newTerapia)
    }

    function handle_new_paciente(value, object) {

        update_terapia({
            ...terapia,
            asistentes: [...terapia.asistentes, {
                paciente: value,
                participacion: 3,
                programado: false,
                terapia: terapia.id,
            }]
        })
    }

    function update_asistente(asistente) {
        update_terapia({
            ...terapia,
            asistentes: [...terapia.asistentes.filter(asist => asist.paciente !== asistente.paciente), asistente]
        })
    }


    function delete_asistente(asistente) {
        if (paciente.paciente === asistente.paciente) {
            setPaciente(pacientes[0])
        }
        update_terapia({
            ...terapia,
            asistentes: [...terapia.asistentes.filter(asist => asist.paciente !== asistente.paciente)]
        })
        authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/paciente/?terapia=' + terapia.id + '&paciente=' + asistente.paciente
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        if (response.length > 0) {
                                            authHeaders().then(authHeader => {
                                                const endpoint = APIPATH + 'api/terapias/paciente/detail/' + response[0].id + "/"
                                                const lookupOptions = {
                                                    method: "DELETE",
                                                    headers: {
                                                        'Content-Type': 'application/json',
                                                        ...authHeader
                                                    },
                                                    credentials: 'include',
                                                }
                                                fetch(endpoint, lookupOptions).catch(
                                                    error => {
                                                        console.log("error", error)
                                                    }
                                                )
                                            })
                                        }
                                    })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            }
        )
    }

    function update_terapia(terapia) {

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/detail/' + terapia.id + '/'
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(terapia)
            }

            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            setTerapia({...response, fecha: response.fecha.substr(0, 16)})
                        })
                    } else {

                        response.json().then(response => {
                            let mensaje = ""
                            Object.keys(response).forEach(key => {
                                mensaje = mensaje + "   " + response[key]
                            })
                            setModalMsg({
                                titulo: "No se pudo guardar terapia",
                                mensaje: mensaje,
                                show: true
                            })
                            setModalMsg(previous => {
                                return {...previous, show: false}
                            })

                        })
                    }

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function handle_editor(content, editor) {
        setTerapia(previous => ({...previous, comentarios: content}))
    }

    function handle_terapeuta(value, object) {
        setTerapia(prevState => ({
            ...prevState, terapeuta: value
        }))
        update_terapia({
            ...terapia, terapeuta: value
        })
    }

    return (
        <div>
            <div className="row bg-primary text-white">
                {terapia.id !== "" &&
                <button type="button" className="bg-primary text-white ml-3" onClick={(e) => props.setMax()}>
                    {props.max ?
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-calendar-date"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                            <path
                                d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                        </svg>

                        :
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-caret-up-square"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                            <path fillRule="evenodd"
                                  d="M3.544 10.705A.5.5 0 0 0 4 11h8a.5.5 0 0 0 .374-.832l-4-4.5a.5.5 0 0 0-.748 0l-4 4.5a.5.5 0 0 0-.082.537z"/>
                        </svg>
                    }
                </button>}
                <h5 className="pt-1 ml-5"> Terapia {terapia.nombre}</h5>
            </div>
            <div className="row px-3 mt-4">
                <div className="col-2">
                    <div className="row">
                        <label htmlFor="fecha">Fecha</label>
                        <input type='datetime-local' id="fecha" required
                               className=" col-8 ml-5 form-control "
                               name="fecha"
                               disabled={disabled}
                               value={terapia.fecha}
                               onChange={handle_blur}/>
                    </div>
                    <div className="row mt-2">
                        <label className="">Terapeuta</label>
                        {userList.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={terapia.terapeuta}
                            options={userList.map(user =>
                                ({name: user.name, value: user.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            disabled={disabled}
                            onChange={handle_terapeuta}
                        />
                        }
                    </div>
                </div>

                <div className={`${!disabled && 'bg-white'} comentarios col-9`}>
                    <Editor id='comentarios'
                            name='comentarios'
                            init={{
                                placeholder: "Comentarios",
                                menubar: false,
                                branding: false,
                                plugins: [
                                    'advlist autolink lists link image  print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help  '
                                ],
                                language: 'es_ES',
                                inline: true,
                                elementpath: false,
                                toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                            }}
                            value={terapia.comentarios}
                            onEditorChange={handle_editor}
                            onBlur={handle_blur}
                            disabled={disabled}
                    />
                </div>
                <div className="col-1 px-4">
                    {paciente !== null && paciente.img !== undefined && paciente.img !== null &&
                    <img src={`${paciente.img}`} className="img-fluid w-75" alt=""/>}
                </div>
            </div>
            <div className="row">
                <div className="col-8 mx-auto">
                    {paciente !== null &&
                    <TerapiaPaciente
                        terapia={terapia}
                        paciente={paciente}
                    />
                    }
                </div>
                <div className="ml-auto col-3 mr-4 ">
                    <div className="subtitulo py-1 px-4 ">

                        <h5 className="pt-1 mx-auto"> Asistentes </h5>
                    </div>
                    <div className="mt-2 mb-2 row ml-3 mr-3">
                        <label htmlFor="paciente">Paciente</label>

                        <SelectSearch
                            className="select-search ml-3"
                            options={listaPacientes.map(paciente =>
                                ({name: paciente.alias, value: paciente.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            disabled={disabled}
                            onChange={handle_new_paciente}
                        />
                        <input type="radio" checked={viewExternos} className="ml-4 mt-1 mr-1"
                               disabled={disabled}
                               onClick={(e) => {
                                   const actPacientes = terapia.asistentes.map(asistente => asistente.paciente)
                                   if (viewExternos) {
                                       setListaPacientes(pacientes.filter(paciente => !actPacientes.includes(paciente.id)))
                                   } else {
                                       setListaPacientes(externos.filter(paciente => !actPacientes.includes(paciente.id)))
                                   }
                                   setViewExternos(prev => !prev)
                               }
                               }/> Externos
                    </div>
                    <div className={`${fileExpanded ? 'asistentes-area' : 'asistentes-area-expanded'}`}>
                        {terapia.asistentes.map((asistente, index) =>
                            <AsistenteInline
                                key={index}
                                asistente={asistente}
                                update={update_asistente}
                                delete={delete_asistente}
                                select={(paciente) => setPaciente(paciente)}
                                selected={paciente !== null ? asistente.paciente === paciente.paciente : false}
                            />
                        )}
                    </div>
                    <div className={`col-12 ${fileExpanded && 'asistentes-area'} px-0`}>
                        {terapia.id !== undefined && terapia.id !== "" &&
                        <TerapiaFiles
                            terapia={terapia}
                            expanded={(ex => setFileExpanded(ex))}
                        />}
                    </div>

                </div>
            </div>
            <ModalMsg
                titulo={modalMsg.titulo}
                mensaje={modalMsg.mensaje}
                show={modalMsg.show}
                modalId="msgPaciente"
            />
        </div>
    )


}

export default Terapia

