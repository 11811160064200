import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import moment from "moment";
import {addDays} from "date-fns";
import SelectSearch from "react-select-search";
import DateRange from "react-date-range/dist/components/DateRange";
import {es} from "react-date-range/dist/locale";
import "./Notas.css"
import AltaNota from "./AltaNota";


function Notas(props) {
    const [notas, setNotas] = useState([])
    const [users, setUsers] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [user, setUser] = useState("")
    const [paciente, setPaciente] = useState("")
    const [search, setSearch] = useState("")
    const [showAltaNota, setShowAltaNota] = useState(false)
    const [is_max, setIs_max] = useState(false)
    const [dateSelection, setDateSelection] = useState([
        {
            startDate: addDays(new Date(), -30),
            endDate: new Date(),
            key: 'selection'
        }
    ]);


    useEffect(() => {
            if (props.is_max !== undefined) {
                setIs_max(props.is_max)
            }
            if (props.paciente !== undefined) {
                setPaciente(props.paciente)
            }
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/accounts/profile/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUsers(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/?activo=True&centro=' + props.centro
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setPacientes(response)

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            query_Notas()
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function query_Notas() {
        authHeaders().then(authHeader => {
                let endpoint = APIPATH + 'api/notas/?centro=' + props.centro + '&startDate=' + moment(dateSelection[0].startDate).format('YYYY-MM-DD 00:00')
                endpoint = endpoint + '&endDate=' + moment(dateSelection[0].endDate).format('YYYY-MM-DD 23:59')

                if (search !== "") {
                    endpoint = endpoint + '&search=' + search
                }
                if (props.paciente !== undefined) {
                    endpoint = endpoint + "&paciente=" + props.paciente
                } else if (paciente !== "") {
                    endpoint = endpoint + "&paciente=" + paciente
                }

                if (user !== "") {
                    endpoint = endpoint + "&user=" + user
                }
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    // body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        setNotas(response)
                                    }
                                )
                        } else {
                            setNotas([])
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            }
        )
    }


    function handle_paciente(value, object) {
        setPaciente(value)
    }

    function handle_user(value, object) {
        setUser(value)
    }

    function alta_nota(nota) {
        setNotas(prev => [nota, ...prev])
    }

    function handle_input(e) {
        const {value} = e.target;
        setSearch(value)
    }

    function restablece_notas(e) {
        setSearch("")
        setDateSelection([{
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }])

        setPaciente("")
        setUser("")

    }

    return (
        <div>
            <div className="row mt-4">
                <div className="col-12 bg-white m-0">
                    <div className="row">

                        <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={() => {
                                    setShowAltaNota(true)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <h3 className="col-4 text-primary">Intervenciones</h3>
                        <div className="col-1 ml-auto">
                            <button type="button" className=" mr-1 mt-0 pt-1 border-0 btn-link bg-white text-success"
                                    onClick={() => {
                                        props.maximizar("notas", !is_max)
                                        setIs_max(prev => !prev)
                                    }}>
                                {is_max ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                    </svg> :
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                        <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                        <path
                                            d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {is_max &&
                <div className="col-3 pb-4 border-right border-secondary px-0">
                    <div className=" mb-3 subtitulo">
                        <h5 className="py-2 px-3"> Búsqueda</h5>
                    </div>
                    {props.paciente === undefined &&
                    <div className="row">

                        <label className=" ml-5 mr-2">Paciente</label>
                        {pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={paciente}
                            options={[{name: "...", value: ""}, ...pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }

                    </div>
                    }
                    <div className="row">
                        <label className=" ml-5 mr-1">Usuario</label>
                        {users.length > 0 &&
                        <SelectSearch
                            className="select-search ml-4"
                            value={user}
                            options={[{name: "...", value: ""}, ...users.map((user, index) =>
                                ({name: user.user.username, value: user.user.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_user}
                        />
                        }
                    </div>


                    <textarea rows="3" className=" ml-3 mt-2 mr-3 w-75 form-control" name="search" value={search}
                              placeholder={"Texto a buscar"} onChange={handle_input}/>
                    <div className=" mt-4 mb-4">
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setDateSelection([item.selection])}
                            moveRangeOnFirstSelection={false}
                            locale={es}
                            direction="vertical"
                            scroll={{enabled: true}}
                            ranges={dateSelection}
                        />
                    </div>
                    <button type="button" className="btn btn-primary ml-5 mr-5" onClick={query_Notas}>Buscar
                    </button>
                    <button type="button" className="btn btn-secondary ml-5"
                            onClick={restablece_notas}>Restablecer
                    </button>

                </div>}
                <div className={`${is_max ? 'col-9 lista-notas-max' : 'col-12 lista-notas'} bg-white px-4`}>
                    {notas.map((nota, index) =>
                        <div className=" row border"
                             key={index}
                        >
                            <div className="col-12 mx-1 nota-header">
                                <div className="row">
                                    <div className={`${is_max ? 'col-1' : 'col-2'} px-0 mx-0`}>
                                        <div className={`${is_max ? 'col-6' : props.foto_width} px-0 mx-0`}>
                                            {nota.img &&
                                            <img className="img-fluid mx-0" src={`${nota.img}`} alt=""/>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-10">
                                        <div className="row">
                                            <span className="text-medium ml-1">{nota.nombrePaciente}</span>
                                            <span className="mr-auto ml-2 mt-1">({nota.nombreUser})</span>

                                            <span
                                                className="mr-2">{moment(nota.fecha).format('ddd D MMM YYYY,h:mm:ss a')}</span>
                                            <h5 className=" col-12">{nota.titulo}</h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {is_max ?
                                <div className="ml-5 col-11" dangerouslySetInnerHTML={{__html: nota.texto}}/>
                                :
                                <div className="ml-5 col-11"
                                     dangerouslySetInnerHTML={{__html: nota.texto.substring(0, 600)}}/>
                            }

                        </div>
                    )}

                </div>
            </div>
            <AltaNota
                show={showAltaNota}
                handleClose={() => setShowAltaNota(false)}
                pacientes={pacientes}
                alta={alta_nota}

            />
        </div>
    )
}

export default Notas