import React, {useState, useEffect, useRef} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import {Editor} from '@tinymce/tinymce-react'
import MedicacionInline from "./MedicacionInline";
import ModalMsg from "../Utils/ModalMsg";
import {useMountedState} from "react-use";
import PrescripcionFiles from "./PrescripcionFiles";


import "./Medicaciones.css"


function Prescripcion(props) {
    const [prescrip, setPrescrip] = useState({
        id: "",
        medicamentos: [],
        adjuntos: [],
        doctor: "",
        fecha: "",
        notas: "",
        paciente: "",
        activa: true,
    })
    const [modalMsg, setModalMsg] = useState({
        titulo: "",
        mensaje: "",
        show: false
    })
    const isMounted = useMountedState();
    const [medicamentos, setMedicamentos] = useState([])
    const [selMedList, setSelMedList] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [fileExpanded, setFileExpanded] = useState(false)
    const [doctores, setDoctores] = useState([])
    const firstUpdate = useRef(true)


    useEffect(() => {
            if (props.prescripcion !== undefined) {
                if (isMounted) {
                    setPrescrip(props.prescripcion)
                }
            }

            if (firstUpdate.current) {
                firstUpdate.current = false
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/medicamentos/doctores/'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            if (isMounted()) {
                                setDoctores(response)
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/medicamentos/'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            if (isMounted()) {
                                setMedicamentos(response)
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })


            }

// eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props]
    )

    useEffect(() => {
        if (isMounted()) {
            setSelMedList(prescrip.medicamentos.map(m => m.medicamento))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prescrip])

    function handleInput(e) {
        let {name, value, checked} = e.target
        if (name === 'activa') {
            value = checked
        }
        setPrescrip(prev => ({...prev, [name]: value}))
    }


    function handle_editor(content, editor) {
        setPrescrip(previous => ({...previous, notas: content}))
    }

    function updateMedList(index, medi, med) {
        const medList = prescrip.medicamentos
        medList[index] = medi
        if (isMounted()) {
            if (medicamentos.filter(m => m.id === med.id).length === 0) {
                setMedicamentos(prev => ([...prev, med]))
            }
            setPrescrip(prev => ({...prev, medicamentos: medList}))
        }
    }

    function updateMed(index, med) {
        const medList = prescrip.medicamentos
        medList[index] = med
        setPrescrip(prev => ({...prev, medicamentos: medList}))
    }

    function deleteMed(index) {
        let medList = prescrip.medicamentos
        medList.splice(index, 1)
        setPrescrip(prev => ({...prev, medicamentos: medList}))
    }


    function addMedicacion(e) {
        setPrescrip(prevState => ({
            ...prevState, medicamentos: [...prevState.medicamentos, {
                id: 1,
                nombre_medicamento: "",
                manana: "1",
                mediodia: "1",
                tarde: "1",
                noche: "1",
                dias: "1",
                unidades: "1",
                notas: "",
                prescripcion: "",
                medicamento: ""
            }]
        }))
    }


    function submitForm(e) {
        e.preventDefault()
        let form_data = new FormData()
        Object.keys(prescrip).forEach(key => {
            form_data.append(key, prescrip[key])
        })
        let prescripcionFetch = {}
        if (prescrip.id === "") {
            form_data.append('paciente', props.paciente)
            prescripcionFetch = new Promise(resolve => {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/medicamentos/prescripcion/'
                    const lookupOptions = {
                        method: "POST",
                        headers: {
                            // 'Content-Type': 'application/json',
                            // 'Content-Type': 'multipart/form-data',
                            ...authHeader
                        },
                        credentials: 'include',
                        // body: JSON.stringify(paciente)
                        body: form_data
                    }
                    fetch(endpoint, lookupOptions).then(response => {
                            if (response.ok) {
                                response.json().then(response => {
                                    resolve(response)
                                })
                            } else {
                                response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + " " + key + ":" + response[key]
                                        })
                                        setModalMsg({
                                            titulo: "No se pudo guardar Prescripcion",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                    resolve("")
                                })
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            })
        } else {
            prescripcionFetch = new Promise(resolve => {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/medicamentos/prescripcion/detail/' + prescrip.id + '/'
                    const lookupOptions = {
                        method: "PUT",
                        headers: {
                            // 'Content-Type': 'application/json',
                            // 'Content-Type': 'multipart/form-data',
                            ...authHeader
                        },
                        credentials: 'include',
                        // body: JSON.stringify(paciente)
                        body: form_data
                    }
                    fetch(endpoint, lookupOptions).then(response => {
                            if (response.ok) {
                                response.json().then(response => {
                                    resolve(response)
                                })
                            } else {
                                response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + " " + key + ":" + response[key]
                                        })
                                        setModalMsg({
                                            titulo: "No se pudo guardar Prescripcion",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                    resolve("")
                                })
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            })
        }
        Promise.resolve(prescripcionFetch).then(prescripRes => {

            if (prescripRes !== '') {
                setDisabled(true)
                const data = {
                    prescripcion: prescripRes.id,
                    medicamentos: prescrip.medicamentos
                }
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/medicamentos/prescripcion/medicamentos/'
                    const lookupOptions = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                        body: JSON.stringify(data)
                    }
                    fetch(endpoint, lookupOptions).then(response => {
                            if (response.ok) {
                                response.json().then(response => {
                                    if (isMounted()) {
                                        props.refreshPrescripciones()
                                        props.prescripcionDetail(response)
                                    }
                                })
                            } else {
                                response.json().then(response => {
                                    if (isMounted()) {
                                        let mensaje = ""
                                        Object.keys(response).forEach(key => {
                                            mensaje = mensaje + " " + key + ":" + response[key]
                                        })
                                        setModalMsg({
                                            titulo: "No se pudo guardar Prescripcion",
                                            mensaje: mensaje,
                                            show: true
                                        })
                                        setModalMsg(previous => {
                                            return {...previous, show: false}
                                        })
                                    }
                                })
                            }
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
            }


        })


    }


    return (
        <div>
            <form onSubmit={submitForm}>
                <div className="row mb-2">
                    <h5 className="col-10 bg-primary text-white mb-0 pt-2 pb-3 pl-5">Prescripcion</h5>
                    {!disabled ?
                        <button type="submit" className="btn btn-outline-primary py-1 ml-2 "> Guardar</button>
                        :
                        <button type="button" className="btn btn-outline-primary py-1 ml-2 "
                                onClick={e => {
                                    e.preventDefault()
                                    setDisabled(false)
                                }}
                        >Modificar</button>}
                </div>
                <div className="form-group row ">
                    <label htmlFor="fecha" className="col-1">Fecha</label>
                    <input type="date" className="form-control col-3 ml-3 pl-1" id="fecha" value={prescrip.fecha}
                           name="fecha" onChange={handleInput} disabled={disabled} required/>
                    <div className="form-check col-1 ml-3 mr-0 pr-0 mt-2">
                        <input className="form-check-input " type="checkbox"
                               checked={prescrip.activa}
                               id="activa"
                               name="activa"
                               onChange={handleInput} disabled={disabled}/>
                        <label className="form-check-label  mr-0 pr-0" htmlFor="activa">
                            Activa
                        </label>

                    </div>
                    <label htmlFor="doctor" className="col-1 ml-auto">Doctor</label>
                    <select className="form-control col-4 ml-4 mr-2" id="doctor"
                            name="doctor" required
                            value={prescrip.doctor} onChange={handleInput} disabled={disabled}>
                        <option value=""></option>
                        {doctores.map(doctor => {
                            return (<option key={doctor.id} value={doctor.id}>{doctor.nombre}</option>)
                        })}

                    </select>
                </div>
                <div className="form-control row notas-area ml-1 pl-4">
                    <Editor id='notas'
                            name='notas'
                            init={{

                                menubar: false,
                                branding: false,
                                plugins: [
                                    'advlist autolink lists link image  print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help  '
                                ],
                                language: 'es_ES',
                                inline: true,
                                elementpath: false,
                                toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                            }}

                            value={prescrip.notas} onEditorChange={handle_editor} disabled={disabled}
                    />
                </div>
                <div className="col-12 border-bottom py-2 titulos-prescripcion">
                    <div className="row">{!disabled &&

                    <button type="button" className="border-0 col-1 titulos-prescripcion"
                            onClick={addMedicacion}
                    >

                        <svg width="1.3em" height="1.3em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>

                    </button>
                    }

                        <h6 className={`text-center mt-2  ${disabled ? 'col-12' : 'col-10'}`}>Medicamentos</h6>

                    </div>
                </div>
                <div className={`${fileExpanded ? 'medicamentos-area' : 'medicamentos-area-expanded'}`}>
                    {prescrip.medicamentos && prescrip.medicamentos.map((med, index) => (
                        <MedicacionInline
                            key={index}
                            id={index}
                            medicacion={med}
                            medicamentos={medicamentos}
                            updateMed={updateMed}
                            updateMedList={updateMedList}
                            deleteMed={deleteMed}
                            selMedList={selMedList}
                            disabled={disabled}
                            addCompra={props.addCompra}
                            last={props.last}

                        />
                    ))}
                </div>
                <div className={`col-12 ${fileExpanded && 'medicamentos-area'} px-0`}>
                    {prescrip.id !== undefined && prescrip.id !== "" &&
                    <PrescripcionFiles
                        prescripcion={prescrip}
                        expanded={(ex => setFileExpanded(ex))}
                    />}
                </div>

            </form>
            <ModalMsg
                titulo={modalMsg.titulo}
                mensaje={modalMsg.mensaje}
                show={modalMsg.show}
                modalId="msgPrescripcion"
            />


        </div>
    )

}

export default Prescripcion;