import React, {useState, useEffect} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import moment from "moment";
import {addDays} from "date-fns";
import SelectSearch from "react-select-search";
import MovimientoUpdate from "./MovimientoUpdate"
import Traspaso from "./Traspaso";
import XLSX from 'xlsx'

function Movimientos(props) {
    let {is_contabilidad_all, is_contabilidad} = sessionStorage.getItem('userToken') !== null && JSON.parse(sessionStorage.getItem('userToken'));
    const [pacientes, setPacientes] = useState([])
    const [paciente, setPaciente] = useState()
    const [movimientos, setMovimientos] = useState()
    const [movimiento, setMovimiento] = useState({})
    const [debe, setDebe] = useState()
    const [is_max, setIs_max] = useState(true)
    const [haber, setHaber] = useState()
    const [saldoIni, setSaldoIni] = useState()
    const [saldoFin, setSaldoFin] = useState()
    const [fechas, setFechas] = useState({
        desde: moment(addDays(new Date(), -7)).format('YYYY-MM-DD'),
        hasta: moment(new Date()).format('YYYY-MM-DD'),
    })
    const [depositos, setDepositos] = useState([])
    const [deposito, setDeposito] = useState()
    const [registro, setRegistro] = useState("T")
    const [tipoMovimiento, setTipoMovimiento] = useState("T")
    const [etiquetas, setEtiquetas] = useState([])
    const [etiqueta, setEtiqueta] = useState()
    const [grupos, setGrupos] = useState([])
    const [centroList, setCentroList] = useState([])
    const [grupo, setGrupo] = useState()
    const [usuarios, setUsuarios] = useState([])
    const [usuario, setUsuario] = useState()
    const [order, setOrder] = useState('-fecha')
    const [listaMov, setListaMov] = useState(false)
    const [fotoMov, setFotoMov] = useState(false)
    const [qcentro, setQcentro] = useState(false)
    const [showTraspaso, setShowTraspaso] = useState(false)
    useEffect(() => {
        if (props.centro.id !== undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/centro/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        let lista = response.filter(centro => {
                            return centro.personal.map(value => value.user).includes(props.user.id)
                        })
                        setCentroList(lista)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/accounts/profile/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUsuarios(response.filter(profile => profile.user.is_active).map((profile) =>
                            ({value: profile.user.id, name: profile.user.username})
                        ))
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/depositos/?usuario=True&centro=' + props.centro.id
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setDepositos(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/?activo=True&centro=' + props.centro.id
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setPacientes(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/movimientos/etiquetas/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setEtiquetas(response.etiquetas)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/grupos/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setGrupos(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, usuario, qcentro)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function query_movimientos(paciente, fechas, deposito, tipo, registro, grupo, etiqueta, order, usuario, qcentro) {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/contabilidad/movimientos/saldo/?usuario=True'
            if (paciente !== undefined) {
                endpoint = endpoint + "&paciente=" + paciente
            }
            if (fechas.desde !== undefined) {
                endpoint = endpoint + "&desde=" + fechas.desde
            }
            if (fechas.hasta !== undefined) {
                endpoint = endpoint + "&hasta=" + fechas.hasta
            }
            if (deposito !== undefined && deposito !== 0) {
                endpoint = endpoint + "&deposito=" + deposito
            }
            if (grupo !== undefined && grupo !== 0) {
                endpoint = endpoint + "&grupo=" + grupo
            }
            if (etiqueta !== undefined && etiqueta !== 0) {
                endpoint = endpoint + "&etiqueta=" + etiqueta
            }
            if (tipo !== undefined) {
                endpoint = endpoint + "&tipo=" + tipo
            }
            if (registro !== undefined) {
                endpoint = endpoint + "&registro=" + registro
            }
            if (usuario !== undefined && usuario !== "T") {
                endpoint = endpoint + "&user=" + usuario
            }

            if (order !== undefined) {
                endpoint = endpoint + "&order=" + order
            }
            if (qcentro) {
                endpoint = endpoint + "&centro=" + props.centro.id
            }

            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setMovimientos(response.movimientos)
                    setSaldoIni(response.saldoIni)
                    setDebe(response.debe)
                    setHaber(response.haber)
                    setSaldoFin(response.saldoFin)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

        setMovimiento({})
    }

    function handle_fechas(e) {
        const {name, value} = e.target
        setFechas(prev => ({...prev, [name]: value}))
        query_movimientos(paciente, {
            ...fechas,
            [name]: value
        }, deposito, tipoMovimiento, registro, grupo, etiqueta, order, usuario, qcentro)
    }

    function handle_paciente(e) {
        const {dataset} = e.currentTarget
        let tempPaciente = undefined
        if (paciente === parseInt(dataset.id)) {
            setPaciente()
        } else {
            tempPaciente = parseInt(dataset.id)
            setPaciente(tempPaciente)
        }


        // const {name, value} = e.target
        // setFechas(prev => ({...prev, [name]: value}))
        query_movimientos(tempPaciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, usuario, qcentro)
    }

    function handle_deposito(value, object) {
        setDeposito(value)
        query_movimientos(paciente, fechas, value, tipoMovimiento, registro, grupo, etiqueta, order, usuario, qcentro)
    }

    function handle_tipoMovimiento(value, object) {
        setTipoMovimiento(value)
        query_movimientos(paciente, fechas, deposito, value, registro, grupo, etiqueta, order, usuario, qcentro)
    }

    function handle_registro(value, object) {
        setRegistro(value)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, value, grupo, etiqueta, order, usuario, qcentro)
    }

    function handle_usuarios(value, object) {
        setUsuario(value)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, value, qcentro)
    }

    function handle_grupo(value, object) {
        setGrupo(value)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, value, etiqueta, order, usuario, qcentro)
    }

    function handle_etiqueta(value, object) {
        setEtiqueta(value)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, value, order, usuario, qcentro)
    }

    function handle_order(e) {
        const id = e.currentTarget.dataset.id
        let tempOrder = id
        if (order === id) {
            tempOrder = "-" + id

        }
        setOrder(tempOrder)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, tempOrder, usuario, qcentro)
    }

    function handle_qcentro(e) {
        const {checked} = e.target

        setQcentro(checked)
        query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, usuario, checked)
    }

    function excel_export() {
        let data = [[
            "Fecha", "Deposito", "Concepto", "GrupoMovimiento", "Centro",
            "Paciente", "Factura", "Signo", "Etiqueta", "Suplido", "Traspaso", "Registro", "Factura_suplido", "ImporteTotal",
            "ImporteNeto", "Usuario", "Proveedor", "FacturaProveedor", "Oculto"]]
        movimientos.forEach(mov => {
            const movArray = [moment(mov.fecha).format('DD-MM-YY'), mov.deposito_nombre, mov.concepto,
                mov.grupo_nombre, mov.centro, mov.paciente_alias, mov.factura, mov.signo, mov.etiqueta, mov.suplido,
                mov.traspaso, mov.registro, mov.factura_suplido, mov.importeTotal, mov.importeNeto, mov.user, mov.proveedor, mov.factura_proveedor, mov.oculto]
            data.push(movArray)
        })
        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, wsAll, "Movimientos")
        XLSX.writeFile(wb, "movimientos.xlsx")
    }

    return (
        <div className={!listaMov && !fotoMov ? "no-overflow" : ""}>
            <div className="row px-5 py-2 bg-white">
                <button type="button" onClick={(e) => {
                    setListaMov(prev => !prev)
                    setFotoMov(false)
                }} className="link-button"
                        data-container="true"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Imprimir Movimientos"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-card-list" viewBox="0 0 16 16">
                        <path
                            d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"/>
                        <path
                            d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                    </svg>
                </button>
                <button type="button" onClick={(e) => {
                    setFotoMov(prev => !prev)
                    setListaMov(false)
                }} className="link-button ml-4"
                        data-container="true"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Imprimir Tickets"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-camera-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path
                            d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                    </svg>
                </button>

                <button type="button" onClick={excel_export} className="link-button ml-4"
                        data-container="true"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Exportar a Excel"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                        <path
                            d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                </button>


                <label className="ml-3">Deposito</label>
                {props.deposito === undefined &&
                <SelectSearch
                    className="select-search select-1 col-2  "
                    value={deposito}
                    options={[{name: "...", value: 0}, ...depositos.map((deposito, index) =>
                        ({name: deposito.nombre, value: deposito.id})
                    )]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_deposito}
                />}
                <label className=" ml-3 mr-2" htmlFor="desde"> Desde </label>
                <input type='date' id="desde" style={{width: "160px"}}
                       className={`  form-control `}
                       name="desde" value={fechas.desde} onChange={handle_fechas}
                />
                <label className="ml-4 mr-2" htmlFor="desde">Hasta </label>
                <input type='date' id="desde" style={{width: "160px"}}
                       className={`form-control`}
                       name="hasta" value={fechas.hasta} onChange={handle_fechas}
                />

                <label className="ml-3 mr-2">Tipo</label>
                <SelectSearch
                    className="select-search select-1 col-1"
                    value={tipoMovimiento}
                    options={[{name: "Todos", value: "T"}, {name: "Gastos", value: "G"}, {
                        name: "Suplidos",
                        value: "S"
                    }]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_tipoMovimiento}
                />

                {is_contabilidad_all &&
                <label className="ml-3 mr-2">Libro</label>
                }
                {is_contabilidad_all &&
                <SelectSearch
                    className="select-search select-1 col-1 "
                    value={registro}
                    options={[{name: "Todos", value: "T"}, {name: "No", value: "N"}, {
                        name: "SI",
                        value: "S"
                    }]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_registro}
                />
                }
                {is_contabilidad_all &&
                <div className="col-2">
                    <div className="row">
                        <label className="ml-1 col-2 mr-2">Usuario</label>
                        <SelectSearch
                            className="select-search select-1 col-6"
                            value={usuario}
                            options={[{name: "___", value: "T"}, ...usuarios]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_usuarios}
                        />

                    </div>
                </div>}
            </div>
            <div className="row">
                {!listaMov && !fotoMov &&
                <div className="col-2 bg-white  pacientes border-top border-right">
                    {pacientes.map(pac => (
                        <div className="row border-bottom ml-2 px-0" key={pac.id}>
                            <div className="col-2 px-0">
                                <button type="button" data-id={pac.id} className="link-button"
                                        onClick={handle_paciente}>
                                    {pac.foto &&
                                    <img src={`${pac.foto}`} alt="" className="img-fluid"/>
                                    }
                                </button>
                            </div>
                            <div className={`col-10 mx-0 px-0 ${pac.id === paciente ? 'bg-warning' : 'bg-white'}`}>
                                <button type="button" data-id={pac.id} className={` mx-0 link-button w-100 `}
                                        onClick={handle_paciente}>
                                    {pac.alias}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>}
                <div className={`${!fotoMov && !listaMov ? 'col-10' : 'col-12'} bg-white`}>
                    <div className="row border-bottom  py-2 ">
                        <div className=" ml-5">
                            Saldo Inicial : <span
                            className="font-weight-bold text-primary">{new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        }).format(saldoIni)}</span>
                        </div>
                        <div className="ml-5">
                            Total Debe : <span
                            className="font-weight-bold text-primary">{new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        }).format(debe)}</span>
                        </div>
                        <div className="ml-5">
                            Total Haber : <span
                            className="font-weight-bold text-primary">{new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        }).format(haber)}</span>
                        </div>
                        <div className="ml-5">
                            Saldo final : <span
                            className="font-weight-bold text-primary">{new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR"
                        }).format(saldoFin)}</span>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <label className="ml-3 col-2 mr-2">Grupo</label>
                                <SelectSearch
                                    className="select-search select-2 col-8"
                                    value={grupo}
                                    options={[{name: "...", value: 0}, ...grupos.map((grupo, index) =>
                                        ({name: grupo.nombre, value: grupo.id}))]}
                                    search
                                    printOptions="on-focus"
                                    placeholder={" ... "}
                                    onChange={handle_grupo}
                                />

                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <label className="col-3">Etiqueta</label>
                                <SelectSearch
                                    className="select-search select-2 col-8"
                                    value={etiqueta}
                                    options={[{name: "...", value: 0}, ...etiquetas.map((etiqueta, index) => {
                                        return {name: etiqueta, value: etiqueta}
                                    })]}
                                    search
                                    printOptions="on-focus"
                                    placeholder={" ... "}
                                    onChange={handle_etiqueta}
                                />

                            </div>
                        </div>
                        <div className="col-1">
                            <input type='checkbox' id="qcentro" className="mr-2"
                                   name="qcentro" value={qcentro} onClick={handle_qcentro}
                            />
                            <label>Centro</label>


                        </div>
                    </div>
                    <div className={`${!listaMov && !fotoMov ? is_max ? 'tabla-mov-min' : 'tabla-mov-max' : ''}`}>
                        {fotoMov ?
                            <div className="row mx-5">
                                {movimientos.map(movimiento => {
                                    if (movimiento.foto) {
                                        return (<div className="col-4" key={movimiento.id}>
                                            <div className="row">
                                                {moment(movimiento.fecha).format('DD-MM-YY')}
                                                <span className="ml-3">{movimiento.concepto}</span>
                                            </div>
                                            <img src={movimiento.foto} className="img-fluid" alt=""/>
                                        </div>)
                                    } else {
                                        return ""
                                    }
                                })}
                            </div>
                            :
                            <table className={`${!listaMov && !fotoMov && 'tabla-mov-min'} table`}>
                                <thead>
                                <tr>
                                    <th className="bg-white">
                                        <button type="button" data-id="fecha"
                                                className={`btn-link bg-white border-0 ${order === 'fecha' || order.substr(1, order.length) === 'fecha' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-fecha" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill " viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Fecha
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="deposito__nombre"
                                                className={`btn-link bg-white border-0 ${order === 'deposito__nombre' || order.substr(1, order.length) === 'deposito__nombre' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-deposito__nombre" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Depósito
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="concepto"
                                                className={`btn-link bg-white border-0 ${order === 'concepto' || order.substr(1, order.length) === 'concepto' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-concepto" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Concepto
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="grupoMovimiento__nombre"
                                                className={`btn-link bg-white border-0 ${order === 'grupoMovimiento__nombre' || order.substr(1, order.length) === 'grupoMovimiento__nombre' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-grupoMovimiento__nombre" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Grupo
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="etiqueta"
                                                className={`btn-link bg-white border-0 ${order === 'etiqueta' || order.substr(1, order.length) === 'etiqueta' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-etiqueta" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Etiqueta
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="paciente__alias"
                                                className={`btn-link bg-white border-0 ${order === 'paciente__alias' || order.substr(1, order.length) === 'paciente__alias' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-paciente__alias" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Paciente
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="suplido"
                                                className={`btn-link bg-white border-0 ${order === 'suplido' || order.substr(1, order.length) === 'suplido' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-suplido" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Suplido
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="registro"
                                                className={`btn-link bg-white border-0 ${order === 'registro' || order.substr(1, order.length) === 'registro' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-registro" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Libro
                                    </th>
                                    <th className="bg-white">
                                        <button type="button" data-id="importeTotal"
                                                className={`btn-link bg-white border-0 ${order === 'importeTotal' || order.substr(1, order.length) === 'importeTotal' ? 'text-primary' : 'text-secondary'}`}
                                                onClick={handle_order}>
                                            {order !== "-importeTotal" ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                    <path
                                                        d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                                </svg>}
                                        </button>
                                        Debe
                                    </th>
                                    <th className="bg-white"> Haber</th>
                                </tr>
                                </thead>
                                <tbody>
                                {movimientos && movimientos.map(mov =>
                                    <tr key={mov.id} className="clickable-tr" onClick={() => {
                                        setMovimiento(mov)
                                    }}>
                                        <td> {moment(mov.fecha).format('DD-MM-YY')} </td>
                                        <td> {mov.deposito_nombre}</td>
                                        <td> {mov.concepto}</td>
                                        <td> {mov.grupo_nombre} </td>
                                        <td> {mov.etiqueta}</td>
                                        <td>{mov.paciente_alias}</td>
                                        <td><label className="check-container">
                                            <input type="checkbox" checked={mov.suplido} disabled={true}/>
                                            <span className="checkmark"></span>
                                        </label></td>
                                        <td><label className="check-container">
                                            <input type="checkbox" checked={mov.registro} disabled={true}/>
                                            <span className="checkmark"></span>
                                        </label></td>
                                        <td> {mov.signo === "D" &&
                                        <span
                                            className="text-success font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "EUR"
                                        }).format(parseFloat(mov.importeTotal))}</span>}
                                        </td>
                                        <td> {mov.signo === "H" &&
                                        <span className="text-danger font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                            style: "currency",
                                            currency: "EUR"
                                        }).format(parseFloat(mov.importeTotal))}</span>}
                                        </td>
                                    </tr>
                                )}
                                </tbody>

                            </table>
                        }
                    </div>
                    {!listaMov && !fotoMov &&
                    <div className="row px-5 py-2 bg-white border-bottom border-top">
                        <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={(e) => {
                                    setMovimiento({})
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <h5 className="pt-2">Movimiento {Object.keys(movimiento).length === 0 && movimiento.constructor === Object ? ' (ALTA)' : ' (MODIFICACION)'}</h5>
                        {is_contabilidad &&
                        <button type="button" className="btn btn-outline-primary ml-5" onClick={() => {
                            setShowTraspaso(true)
                        }}>
                            Traspaso
                        </button>
                        }


                        <button type="button" className=" mr-1 ml-auto mt-0 border-0 btn-link bg-white text-success"
                                onClick={() => {
                                    setIs_max(prev => !prev)
                                }}>
                            {is_max ?
                                <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                </svg> :
                                <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                    <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                    <path
                                        d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                </svg>
                            }
                        </button>
                    </div>
                    }
                    {is_max && !listaMov && !fotoMov &&
                    <MovimientoUpdate
                        key={movimiento.id}
                        centro={props.centro}
                        pacientes={pacientes}
                        depositos={depositos}
                        centros={centroList}
                        grupos={grupos}
                        etiquetas={etiquetas}
                        movimiento={movimiento}
                        update={() => {
                            query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, qcentro)
                            setMovimiento({})
                        }}
                    />
                    }
                </div>
            </div>
            <Traspaso
                show={showTraspaso}
                handleClose={() => setShowTraspaso(false)}
                depositos={depositos}
                centro={props.centro}
                update={() => {
                    query_movimientos(paciente, fechas, deposito, tipoMovimiento, registro, grupo, etiqueta, order, qcentro)
                    setMovimiento({})
                }}

            />
        </div>
    )
}

export default Movimientos