import React, {useState, useEffect} from "react";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import "./Contratos.css"
import moment from "moment";
import {Editor} from "@tinymce/tinymce-react";

function Contratos(props) {
    const [contratos, setContratos] = useState([])
    const nuevoContrato = {
        id: "",
        nombre: "",
        texto: "",
        activo: true,
    }
    const [contrato, setContrato] = useState(nuevoContrato)

    useEffect(() => {
        loadContratos()

    }, [props])

    function loadContratos() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/contratos/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setContratos(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function handle_input(e) {
        let {name, value, checked} = e.target;
        if (name === "activo") {
            value = checked
        }

        setContrato(prev => ({...prev, [name]: value}))
    }

    function handle_editor(content, editor) {
        setContrato(previous => ({...previous, texto: content}))
    }

    function handle_blur(e) {
        const data = {...contrato}
        if (contrato.id === "") {
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contratos/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data),
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setContrato(response)
                        loadContratos()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contratos/detail/' + contrato.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data),
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setContrato(response)
                        loadContratos()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    return (
        <div className="row mx-3">
            <div className="col-12 bg-white m-0">
                <div className="row">
                    <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                            onClick={() => {
                                setContrato(nuevoContrato)
                            }}>
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                    <h3 className="col-4 text-primary">Contratos</h3>
                </div>
            </div>
            <div className="col-5 bg-white contrato-area">
                <table className="table">
                    <thead>
                    <tr>
                        <th width="70%">
                            Contrato
                        </th>
                        <th>
                            Fecha
                        </th>
                        <th>
                            Activo
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {contratos.map(contrato =>
                        <tr className="clickable-tr" onClick={() => {
                            setContrato(contrato)
                        }}
                            key={contrato.id}
                        >
                            <td width="70%">{contrato.nombre}</td>
                            <td>{moment(contrato.fecha).format('DD-MM-YY')}</td>
                            <td><label className="check-container">
                                <input type="checkbox" checked={contrato.activo} disabled={true}/>
                                <span className="checkmark"></span>
                            </label></td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
            <div className="col-6 mt-4 pt-3 ml-5 bg-white">
                <div className="row">
                    <label className="ml-2 mt-1 mr-1">Nombre</label>
                    <input type="text" className="form-control col-8" name="nombre" value={contrato.nombre}
                           onChange={handle_input} />
                    <label className="check-container mt-2 ml-3">
                        <input type="checkbox" checked={contrato.activo} name="activo" onBlur={handle_blur}
                               onChange={handle_input}/>
                        <span className="checkmark"></span>

                    </label>
                    <span className="mt-2"> Activo </span>

                    <span className="ml-auto mr-4 text-primary"
                          data-container="true"
                          data-toggle="tooltip"
                          data-placement="left"
                            data-html="true"
                      title="{nombre} = Nombre y apellidos del paciente /
                             {domicilio} = Domicilio Paciente /
                                     {fecha} = Fecha del contrato /
                                     {NIF} = Nif Paciente /
                                     {nombreC} = Nombre y apellidos Contacto /
                                     {NIFC} = NIF Contacto /
                                     {relacion} /
                                     {importe} / {suplidos}"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                             className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412l-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                    </span>
                </div>
                <div className="Row">
                    <div className="col-2 ml-auto">
                     <button className="btn btn-primary my-2 ml-auto" onClick={handle_blur} disabled={contrato.nombre === ""}>
                            Actualizar
                        </button>
                    </div>
                </div>
                <div className={`bg-white texto-contrato mt-2`}>
                    <Editor id='texto'
                            name='texto'
                            init={{
                                placeholder: "Texto",
                                menubar: true,
                                branding: false,
                                plugins: [
                                    'advlist autolink lists link image  print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help  '
                                ],
                                language: 'es_ES',
                                inline: false,
                                elementpath: false,
                                height: 700,
                                toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                            }}
                            value={contrato.texto}
                            onEditorChange={handle_editor}
                            // onBlur={handle_blur}

                    />
                </div>

            </div>
        </div>
    )

}

export default Contratos