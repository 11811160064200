import React, {useEffect, useState} from 'react';
import SelectSearch from "react-select-search";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";

function NuevaTerapia(props) {

    const [programacionList, setProgramacionList] = useState([])
    const [dias, setDias] = useState([])
    const [programacion, setProgramacion] = useState("")

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        modal.style.display = "none";
        modal.className = "modal fade";
    }

     function handle_dia(value, object) {
        setProgramacion(value)
     }
    function handle_programacion(value, object) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/terapias/programaciones/detail/' + value + '/'
            const diasSemana = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setDias(response.dias.filter(dia => dia.hora !== "").map(dia => ({name: diasSemana[dia.dia - 1], value: dia.id})))
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    useEffect(() => {
        if (props.show === true) {
            const saveModal = document.getElementById(props.modalId);
            saveModal.style.display = "block";
            saveModal.style.paddingRight = "17px";
            saveModal.className = "modal fade show";

            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/programaciones/?centro=' + props.centro
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setProgramacionList(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }, [props])

    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nueva Terapia</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SelectSearch
                            className="select-search "
                            options={programacionList.map(prog =>
                                ({name: prog.nombre, value: prog.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder="Seleccione Programacion"
                            onChange={handle_programacion}
                        />
                        <SelectSearch
                            className="select-search mt-4 mb-5"
                            options={dias}
                            search
                            printOptions="on-focus"
                            placeholder="Seleccione Dia"
                            onChange={handle_dia}
                        />
                    </div>
                    <div className="modal-footer mt-5">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>
                        <button type="button" className="btn btn-primary" onClick={() => {
                             props.setProgramacion(programacion, props.fecha)
                        }}>Crear</button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default NuevaTerapia;