import React, {useEffect, useState} from 'react';

import './Login.css';
import {APIPATH} from "../settings";
import {Redirect, useParams} from "react-router-dom"

function Password(props) {
    const [formFields, setFormFields] = useState({
        password1: '',
        password2: '',
    })
    const [formErrors, setFormErrors] = useState({})
    const [username, setUsername] = useState("")
    const [updated, setUpdated] = useState(false)
    const {token} = useParams()


    function handleInput(event) {

        let {name, value} = event.target
        setFormFields(previous => {
            return {
                ...previous,
                [name]: value,
            };
        });

    }

    function submitForm(event) {
        event.preventDefault()
        const endpoint = APIPATH + 'api/accounts/password/'
        const data = {"password": formFields.password1, username: username, token: token}
        let status = 0
        if (formFields.password1 !== formFields.password2) {
            setFormErrors({form: 'Las contraseñas no coinciden'})
        } else {

            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    status = response.status
                    return response.json()
                }
            ).then(
                response => {
                    if ([401, 403].indexOf(status) !== -1) {
                        setFormErrors({form: 'Error al registrar la contraseña'})
                    } else {
                        setUpdated(true)
                    }

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )

        }
    }

    useEffect(() => {
            const endpoint = APIPATH + 'api/accounts/confirm/user/' + token + '/'
            let ok = false

            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    ok = response.ok
                    return response.json()
                }
            ).then(
                response => {
                    if (ok) {
                        setUsername(response.username)
                    }

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )

        }, [token]
    );

    if (updated) {
        return <Redirect to='/tera/login'/>
    } else {
        return (
            <div className="container mt-5 pt-5 mx-auto col-lg-3 col-md-4">
                {username === "" ?
                    <h3> CODIGO NO ES VALIDO </h3> :
                    <div>
                        <form onSubmit={submitForm}>
                            <h3 className="text-center mb-4">Contraseña para {username}</h3>
                            <input type="password" name='password1' id='username'
                                   className="form-control mb-3 border-0 rounded-pill "
                                   value={formFields.password1} placeholder='Contraseña' onChange={handleInput}
                                   required="required"/>
                            <input type="password" name='password2' id='password'
                                   className="form-control mb-3 border-0 rounded-pill"
                                   onChange={handleInput}
                                   required="required" placeholder='Repetir contraseña'
                                   value={formFields.password2}
                            />
                            <button className='btn btn-primary mb-5 w-100 rounded-pill'>Acceder</button>
                        </form>
                        {formErrors.form !== undefined ?
                            <span className="text-danger mt-5 w-100 text-center"> {formErrors.form}</span>
                            : ""
                        }

                    </div>
                }
            </div>)
    }

}

export default Password;
