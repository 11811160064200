import React, {useEffect, useState} from 'react';
import {authHeaders} from "../../Utils/authUtils";
import {APIPATH} from "../../settings";


import "./Anamnesis.css"

function Psico(props) {

    const [anamnesis, setAnamnesis] = useState({
        paciente: "",
        conducta_infancia: "",
        conducta_adolescencia: "",
        desarrollo_voluntad: "",
        persistencia_esfuerzo: "",
        relacciones_interpersonales: "",
        jerarquia_valores: "",
        psicoBiografia: [],
    })
    // const [formErrors, setFormErrors] = useState({})
    const [acontecimientos, setAcontecimientos] = useState({})


    useEffect(() => {

        if (Object.keys(props.anamnesis).length !== 0) {
            Object.keys(props.anamnesis).forEach(key => {
                if (props.anamnesis[key] === null) {
                    props.anamnesis[key] = ""
                }
            })
            setAnamnesis(props.anamnesis)
        }
        //
        // Carga acontecimientos
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/acontecimientos/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setAcontecimientos(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [props])


    // function handle_input(event) {
    //
    //     let {name, value} = event.target
    //     // if (name === 'cambio') {
    //     //     value = checked
    //     // }
    //     setAnamnesis(previous => {
    //         return {...previous, [name]: value}
    //     })
    // }

    function handle_acontecimiento(event) {
        const {name, value} = event.target
        const acontecimiento = event.currentTarget.dataset.acontecimiento
        let psicoList = [...anamnesis.psicoBiografia]


        if (anamnesis.psicoBiografia.filter(acon => acon.acontecimiento === acontecimiento).length === 0) {

            psicoList.push({acontecimiento: acontecimiento, corto: "", medio: "", largo: "", [name]: value})
        } else
            psicoList = anamnesis.psicoBiografia.map(acon => {
                if (acon.acontecimiento === acontecimiento) {
                    return {...acon, [name]: value}
                } else {
                    return acon
                }

            })

        setAnamnesis(previous => ({
            paciente: anamnesis.paciente, psicoBiografia: psicoList
        }))

    }

    // function handle_blur(event) {
    //     let {name, value} = event.target
    //     props.update({paciente: anamnesis.paciente, [name]: value})
    // }


    return (
        <form className="text-small">
            {/*<div className="row mt-4">*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="conducta_infancia">Conducta infancia</label>*/}
            {/*        <textarea id="conducta_infancia"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="conducta_infancia" value={anamnesis.conducta_infancia} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="conducta_adolescencia">Conducta adolescencia</label>*/}
            {/*        <textarea id="conducta_adolescencia"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="conducta_adolescencia" value={anamnesis.conducta_adolescencia}*/}
            {/*                  onChange={handle_input} onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="desarrollo_voluntad">Desarrollo de la Voluntad</label>*/}
            {/*        <textarea id="desarrollo_voluntad"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="desarrollo_voluntad" value={anamnesis.desarrollo_voluntad} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="row">*/}

            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="persistencia_esfuerzo">Persistencia Esfuerzo</label>*/}
            {/*        <textarea id="persistencia_esfuerzo"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="persistencia_esfuerzo" value={anamnesis.persistencia_esfuerzo}*/}
            {/*                  onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}


            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="relacciones_interpersonales">Relacciones Interpersonales</label>*/}
            {/*        <textarea id="relacciones_interpersonales"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="relacciones_interpersonales" value={anamnesis.relacciones_interpersonales}*/}
            {/*                  onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="jerarquia_valores">Jerarquia de Valores</label>*/}
            {/*        <textarea id="jerarquia_valores"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="jerarquia_valores" value={anamnesis.jerarquia_valores}*/}
            {/*                  onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <table className="table text-small mt-4">
                <thead>
                <tr className="bg-primary text-white">
                    <th width="19%">Acontecimientos Significativos</th>
                    <th className="text-center border-left" width="27%">3 Últimos Meses</th>
                    <th className="text-center border-left" width="27%">Último año</th>
                    <th className="text-center border-left" width="27%">A lo largo de la vida</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(acontecimientos).map(key => (
                    <tr key={key}>
                        <td>
                            {acontecimientos[key]}
                        </td>
                        <td>
                            <textarea data-acontecimiento={key}
                                      className={`w-100 form-control text-small
                                      ${anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 &&
                                      anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].corto !== "" && "bg-warning"} 
                                      `} rows={2}
                                      name="corto"
                                      value={anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 ?
                                          anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].corto : ""
                                      }
                                      onChange={handle_acontecimiento}
                                      onBlur={(e) => {
                                          props.update({paciente:anamnesis.paciente, psicoBiografia: anamnesis.psicoBiografia})
                                      }}/>
                        </td>
                        <td>
                            <textarea data-acontecimiento={key}
                                      className={`w-100 form-control text-small
                                          ${anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 &&
                                      anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].medio !== "" && "bg-warning"} 
                                      \`}
                                      `} rows={2}
                                      name="medio"
                                      value={anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 ?
                                          anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].medio : ""
                                      }
                                      onChange={handle_acontecimiento}
                                      onBlur={(e) => {
                                          props.update({paciente:anamnesis.paciente, psicoBiografia: anamnesis.psicoBiografia})
                                      }}/>
                        </td>
                        <td>
                            <textarea data-acontecimiento={key}
                                      className={`w-100 form-control text-small
                                          ${anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 &&
                                      anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].largo !== "" && "bg-warning"} 
                                      \`}
                                      `} rows={2}
                                      name="largo"
                                      value={anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key)).length > 0 ?
                                          anamnesis.psicoBiografia.filter(psico => (psico.acontecimiento === key))[0].largo : ""
                                      }
                                      onChange={handle_acontecimiento}
                                      onBlur={(e) => {
                                          props.update({paciente:anamnesis.paciente, psicoBiografia: anamnesis.psicoBiografia})
                                      }}/>
                        </td>

                    </tr>
                ))}
                </tbody>
            </table>
        </form>

    )

}

export default Psico