import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import "./Facturacion.css"
import GeneracionFactura from "./GeneracionFactura"
import Proformas from "./Proformas"
import Facturas from "./Facturas";
import {addDays} from "date-fns";
import moment from "moment";

function Facturacion(props) {
    const [pacientes, setPacientes] = useState([])
    const [paciente, setPaciente] = useState()
    const [fechas, setFechas] = useState({
        desde: moment(addDays(new Date(), -30)).format('YYYY-MM-DD'),
        hasta: moment(new Date()).format('YYYY-MM-DD'),
    })
    const [reload, setReload] = useState(false)
    useEffect(() => {
            if (props.paciente !== undefined) {
                setPaciente(props.paciente)
            }
            if (props.centro.id !== undefined) {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/?centro=' + props.centro.id
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientes(response)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })

            }
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function handle_paciente(value, object) {
        setPaciente(value)
    }

    function handle_fechas(e) {
        const {name, value} = e.target
        setFechas(prev => ({...prev, [name]: value}))
    }

    return (
        <div className="no-overflow">>
            <div className="row no-overflow">
                <div className="col-12 bg-white px-5 py-3 pt-2">
                    <div className="row">
                        <h3 className="col-1 text-primary">Facturación</h3>

                        {props.paciente === undefined &&
                        <div className="col-3">
                            <div className="row">
                                <label className="ml-5 col-1 mr-3">Paciente</label>
                                {pacientes.length > 0 &&
                                <SelectSearch
                                    className="select-search ml-3 col-8"
                                    value={paciente}
                                    options={[{name: "...", value: 0}, ...pacientes.map((paciente, index) =>
                                        ({name: paciente.alias, value: paciente.id})
                                    )]}
                                    search
                                    printOptions="on-focus"
                                    placeholder={" ... "}
                                    onChange={handle_paciente}
                                />
                                }
                            </div>
                        </div>}
                        <label className=" ml-4 mr-2" htmlFor="desde">Fecha Desde </label>
                        <input type='date' id="desde" style={{width: "200px"}}
                               className={`  form-control `}
                               name="desde" value={fechas.desde} onChange={handle_fechas}
                        />
                        <label className="ml-4 mr-2" htmlFor="desde">Hasta </label>
                        <input type='date' id="desde" style={{width: "200px"}}
                               className={` form-control `}
                               name="hasta" value={fechas.hasta} onChange={handle_fechas}
                        />

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-4">
                    <GeneracionFactura
                        centro={props.centro}
                        paciente={paciente}
                        refresh={() => setReload(prev => !prev)}
                        reload={reload}
                    />
                </div>
                <div className="col-4">
                    <Proformas
                        centro={props.centro}
                        paciente={paciente}
                        refresh={() => setReload(prev => !prev)}
                        reload={reload}
                    />
                </div>
                <div className="col-4">
                    <Facturas
                        centro={props.centro}
                        paciente={paciente}
                        refresh={() => setReload(prev => !prev)}
                        reload={reload}
                        fechas={fechas}
                    />
                </div>
            </div>
        </div>
    )
}

export default Facturacion