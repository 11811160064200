import React, {useState, useEffect} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import moment from "moment";
import {addDays} from "date-fns";
import SelectSearch from "react-select-search";

import XLSX from 'xlsx'

function Movimientos(props) {
    const [movimientos, setMovimientos] = useState()


    const [fechas, setFechas] = useState({
        desde: moment(addDays(new Date(), -7)).format('YYYY-MM-DD'),
        hasta: moment(new Date()).format('YYYY-MM-DD'),
    })
    const [depositos, setDepositos] = useState([])
    const [deposito, setDeposito] = useState()

    const [etiquetas, setEtiquetas] = useState([])
    const [etiqueta, setEtiqueta] = useState()
    const [grupos, setGrupos] = useState([])
    const [grupo, setGrupo] = useState()

    const [order, setOrder] = useState('-fecha')


    useEffect(() => {
        if (props.centro.id !== undefined) {

            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/depositos/?usuario=True&centro=' + props.centro.id
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setDepositos(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })

            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/movimientos/etiquetas/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setEtiquetas(response.etiquetas)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/grupos/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setGrupos(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            query_movimientos(fechas, deposito, grupo, etiqueta, order)
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function query_movimientos(fechas, deposito, grupo, etiqueta, order) {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/contabilidad/movimientos/?registro=S&factura_ventas=False'

            if (fechas.desde !== undefined) {
                endpoint = endpoint + "&desde=" + fechas.desde
            }
            if (fechas.hasta !== undefined) {
                endpoint = endpoint + "&hasta=" + fechas.hasta
            }
            if (deposito !== undefined && deposito !== 0) {
                endpoint = endpoint + "&deposito=" + deposito
            }
            if (grupo !== undefined && grupo !== 0) {
                endpoint = endpoint + "&grupo=" + grupo
            }
            if (etiqueta !== undefined && etiqueta !== 0) {
                endpoint = endpoint + "&etiqueta=" + etiqueta
            }


            if (order !== undefined) {
                endpoint = endpoint + "&order=" + order
            }

            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setMovimientos(response)

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    function handle_fechas(e) {
        const {name, value} = e.target
        setFechas(prev => ({...prev, [name]: value}))
        query_movimientos({
            ...fechas,
            [name]: value
        }, deposito, grupo, etiqueta, order)
    }


    function handle_deposito(value, object) {
        setDeposito(value)
        query_movimientos(fechas, value, grupo, etiqueta, order)
    }


    function handle_grupo(value, object) {
        setGrupo(value)
        query_movimientos(fechas, deposito, value, etiqueta, order)
    }

    function handle_etiqueta(value, object) {
        setEtiqueta(value)
        query_movimientos(fechas, deposito, grupo, value, order)
    }

    function handle_order(e) {
        const id = e.currentTarget.dataset.id
        let tempOrder = id
        if (order === id) {
            tempOrder = "-" + id

        }
        setOrder(tempOrder)
        query_movimientos(fechas, deposito, grupo, etiqueta, tempOrder)
    }

    function excel_export() {
        let data = [[
            "Fecha", "Proveedor", "CIF", "Factura", "Base",
            "Tipo", "Cuota", "Total"]]
        movimientos.forEach(mov => {
            const movArray = [moment(mov.fecha).format('DD-MM-YY'), mov.proveedor_nombre, mov.proveedor_cif,
                mov.factura_proveedor, mov.signo==='H' ? parseFloat(mov.importeNeto) : - parseFloat(mov.importeNeto),"","",mov.signo==='H' ? parseFloat(mov.importeTotal): - parseFloat(mov.importeTotal)]
            data.push(movArray)
            mov.tiposIva.forEach(tipoiva => {
                    const tipoArray = ["","","","",
                        parseFloat(tipoiva.base),
                        tipoiva.tipo_iva,
                       parseFloat(tipoiva.base) * tipoiva.tipo_iva / 100,
                        parseFloat(tipoiva.base) * (1 + tipoiva.tipo_iva / 100)]
                data.push(tipoArray)
            }
                            )
        })




        const wb = XLSX.utils.book_new()
        const wsAll = XLSX.utils.aoa_to_sheet(data)
        XLSX.utils.book_append_sheet(wb, wsAll, "Movimientos")
        XLSX.writeFile(wb, "movimientos.xlsx")
    }

    return (
        <div className="">
            <div className="row px-5 py-2 bg-white">


                <button type="button" onClick={excel_export} className="link-button ml-4"
                        data-container="true"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Exportar a Excel"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                        <path
                            d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                </button>


                <label className="ml-3">Deposito</label>
                {props.deposito === undefined &&
                <SelectSearch
                    className="select-search select-1 col-1  "
                    value={deposito}
                    options={[{name: "...", value: 0}, ...depositos.map((deposito, index) =>
                        ({name: deposito.nombre, value: deposito.id})
                    )]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_deposito}
                />}
                <label className=" ml-3 mr-2" htmlFor="desde"> Desde </label>
                <input type='date' id="desde" style={{width: "160px"}}
                       className={`  form-control `}
                       name="desde" value={fechas.desde} onChange={handle_fechas}
                />
                <label className="ml-4 mr-2" htmlFor="desde">Hasta </label>
                <input type='date' id="desde" style={{width: "160px"}}
                       className={`form-control`}
                       name="hasta" value={fechas.hasta} onChange={handle_fechas}
                />


                <div className="col-2">
                    <div className="row">
                        <label className="ml-3 col-2 mr-2">Grupo</label>
                        <SelectSearch
                            className="select-search select-2 col-8"
                            value={grupo}
                            options={[{name: "...", value: 0}, ...grupos.map((grupo, index) =>
                                ({name: grupo.nombre, value: grupo.id}))]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_grupo}
                        />

                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <label className="col-3">Etiqueta</label>
                        <SelectSearch
                            className="select-search select-2 col-8"
                            value={etiqueta}
                            options={[{name: "...", value: 0}, ...etiquetas.map((etiqueta, index) => {
                                return {name: etiqueta, value: etiqueta}
                            })]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_etiqueta}
                        />


                    </div>
                </div>
            </div>
            <div className='tabla-mov-max'>
                <table className='table'>
                    <thead>
                    <tr>
                        <th className="bg-white">
                            <button type="button" data-id="fecha"
                                    className={`btn-link bg-white border-0 ${order === 'fecha' || order.substr(1, order.length) === 'fecha' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-fecha" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill " viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            Fecha
                        </th>
                        <th className="bg-white">
                            <button type="button" data-id="proveedor__nombre"
                                    className={`btn-link bg-white border-0 ${order === 'deposito__nombre' || order.substr(1, order.length) === 'deposito__nombre' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-proveedor__nombre" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            Proveedor
                        </th>
                        <th className="bg-white">
                            <button type="button" data-id="proveedor__cif"
                                    className={`btn-link bg-white border-0 ${order === 'concepto' || order.substr(1, order.length) === 'concepto' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-proveedor__cif" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            CIF
                        </th>
                        <th className="bg-white">
                            <button type="button" data-id="factura_proveedor"
                                    className={`btn-link bg-white border-0 ${order === 'grupoMovimiento__nombre' || order.substr(1, order.length) === 'grupoMovimiento__nombre' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-factura_proveedor" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            Factura
                        </th>
                        <th className="bg-white">
                            <button type="button" data-id="importeNeto"
                                    className={`btn-link bg-white border-0 ${order === 'importeTotal' || order.substr(1, order.length) === 'importeTotal' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-importeNeto" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            Base
                        </th>
                        <th className="bg-white">
                            Tipo
                        </th>
                        <th className="bg-white">
                            Cuota
                        </th>
                        <th className="bg-white">
                            <button type="button" data-id="importeTotal"
                                    className={`btn-link bg-white border-0 ${order === 'importeTotal' || order.substr(1, order.length) === 'importeTotal' ? 'text-primary' : 'text-secondary'}`}
                                    onClick={handle_order}>
                                {order !== "-importeTotal" ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor"
                                         className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>}
                            </button>
                            Total
                        </th>

                    </tr>
                    </thead>

                    {movimientos && movimientos.map(mov =>
                        <tbody key={mov.id}>
                        <tr className="bg-white" >
                            <td> {moment(mov.fecha).format('DD-MM-YY')} </td>
                            <td> {mov.proveedor_nombre}</td>
                            <td> {mov.proveedor_cif}</td>
                            <td> {mov.foto ? <a href={mov.foto} rel="noopener noreferrer" target="_blank">{mov.factura_proveedor}</a> : mov.factura_proveedor}</td>
                            <td> {mov.signo === "H" ?
                                <span
                                    className=" font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(parseFloat(mov.importeNeto))}</span>
                                :
                                <span
                                    className=" font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(-parseFloat(mov.importeNeto))}</span>
                            }
                            </td>
                            <td colSpan="2"></td>
                            <td> {mov.signo === "H" ?
                                <span
                                    className=" font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(parseFloat(mov.importeTotal))}</span>
                                :
                                <span
                                    className=" font-weight-bold">{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(-parseFloat(mov.importeTotal))}</span>
                            }
                            </td>

                        </tr>
                        {mov.tiposIva.map((tipoiva, index) =>
                            <tr className="bg-white" key={index}>
                                <td colSpan="4"></td>
                                <td>{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(parseFloat(tipoiva.base))}</td>
                                <td>{tipoiva.tipo_iva}%</td>
                                <td>{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(parseFloat(tipoiva.base) * tipoiva.tipo_iva / 100)}</td>

                                <td>{new Intl.NumberFormat("de-DE", {
                                    style: "currency",
                                    currency: "EUR"
                                }).format(parseFloat(tipoiva.base) * (1 + tipoiva.tipo_iva / 100))}</td>
                            </tr>)}
                        </tbody>
                    )}


                </table>

            </div>
        </div>

    )
}

export default Movimientos