import React, {useState} from 'react';
import 'whatwg-fetch';
import {useDropzone} from "react-dropzone";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import {image64toCanvasRef, extractImageFileExtensionFromBase64, base64StringtoFile} from "../Utils/imageUtils";
import Webcam from "react-webcam";

import './Foto.css';

function Foto(props) {


    function close_modal() {
        const modal = document.getElementById(props.modalId)
        setImgSrc("")
        setRecortado(false)
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }


    const imageMaxSize = 1000000
    const [imgSrc, setImgSrc] = useState("")
    const [crop, setCrop] = useState({
        aspect: 1 / 1,
        width: 400,
    })
    const [recortado, setRecortado] = useState(false)
    const [webcam, setWebcam] = useState(false)

    const {getRootProps, getInputProps, isDragActive, rejectedFiles, isDragReject} = useDropzone(
        {
            maxSize: imageMaxSize,
            onDrop: handleOnDrop,
            multiple: false,
            accept: 'image/*'
        }
    )
    const isFileTooLarge = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].size > imageMaxSize;
    const imagPreviewCanvas = React.createRef()
    const videoConstraints = {
        width: 1920,
        height: 1080,
        facingMode: "user"
    };


    const webcamRef = React.useRef(null);

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setImgSrc(imageSrc);
        },
        [webcamRef]
    );

    function handleOnDrop(acceptedFiles, rejectedFiles) {
        const resize_width = 400
        if (acceptedFiles && acceptedFiles.length > 0) {
            const currentFile = acceptedFiles[0]
            const reader = new FileReader()
            reader.addEventListener("load", () => {
                    var img = new Image();//create a image
                    img.src = reader.result;//result is base64-encoded Data URI
                    img.onload = function (el) {
                        var elem = document.createElement('canvas');//create a canvas

                        //scale the image to  (width) and keep aspect ratio
                        var scaleFactor = resize_width / el.target.width;
                        elem.width = resize_width;
                        elem.height = el.target.height * scaleFactor;

                        //draw in canvas
                        var ctx = elem.getContext('2d');
                        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

                        //get the base64-encoded Data URI from the resize image
                        setImgSrc(ctx.canvas.toDataURL(el.target, 'image/jpeg', 0));

                    }
                }, false
            )
            reader.readAsDataURL(currentFile)
        }
    }

    function handleOnCrop(crop) {
        setCrop(crop)
    }

    function handleImageLoaded(image) {

    }

    function handleOnCropComplete(crop, pixelCrop) {


    }

    function recortar(event) {
        event.preventDefault()
        image64toCanvasRef(imagPreviewCanvas.current, imgSrc, crop)
        setRecortado(true)
    }

    function handleUpload(event) {
        event.preventDefault()
        const canvasRef = imagPreviewCanvas.current
        const fileExtension = extractImageFileExtensionFromBase64(imgSrc)
        const myFilename = "fotoId." + fileExtension
        const imageData64 = canvasRef.toDataURL('image/' + fileExtension)
        const fotoFile = base64StringtoFile(imageData64, myFilename)
        props.uploadImage(imageData64, fotoFile)
        close_modal()
    }

    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title row w-100" id="modalLabel">
                            <h5 className="ml-4">Foto</h5>
                            {!webcam ?
                                <button type="button" className="border-0 bg-white ml-auto mr-5" onClick={event => {
                                    setWebcam(true)
                                    setRecortado(false)
                                    setImgSrc("")
                                }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16"
                                         className="bi bi-camera-video-off"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l.714 1H9.5a1 1 0 0 1 1 1v6a1 1 0 0 1-.144.518l.605.847zM1.428 4.18A.999.999 0 0 0 1 5v6a1 1 0 0 0 1 1h5.014l.714 1H2a2 2 0 0 1-2-2V5c0-.675.334-1.272.847-1.634l.58.814zM15 11.73l-3.5-1.555v-4.35L15 4.269v7.462zm-4.407 3.56l-10-14 .814-.58 10 14-.814.58z"/>
                                    </svg>
                                </button> :
                                <button type="button" className="border-0 ml-auto mr-5 bg-white" onClick={event => {
                                    setWebcam(false)
                                    setRecortado(false)
                                    setImgSrc("")
                                }}>
                                    <svg width="2em" height="2em" viewBox="0 0 16 16"
                                         className="bi bi-camera-video-fill"
                                         fill="green" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"/>
                                    </svg>
                                </button>
                            }
                        </div>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body ">
                        <div {...getRootProps({className: `${webcam ? "" : "dropArea"} mx-auto`})} >

                            {imgSrc !== "" ?
                                <div>
                                    {!recortado &&
                                    <ReactCrop
                                        src={imgSrc}
                                        crop={crop}
                                        onChange={handleOnCrop}
                                        onImageLoaded={handleImageLoaded}
                                        onComplete={handleOnCropComplete}
                                    />
                                    }
                                    <canvas ref={imagPreviewCanvas} className={recortado ? "d-block" : "d-none"}/>

                                </div>
                                :
                                (webcam ?
                                    <div>
                                        <Webcam
                                            audio={false}
                                            height={564}
                                            ref={webcamRef}
                                             // forceScreenshotSourceSize
                                            screenshotFormat="image/jpeg"
                                            width={960}
                                            videoConstraints={videoConstraints}
                                        />

                                    </div>
                                    :
                                    <div>
                                        <input {...getInputProps()} />
                                        <p className="m-3">
                                            {!isDragActive && 'Presione aquí o arrastre una Imagen'}
                                            {isDragActive && !isDragReject && "Puede soltar el fichero"}
                                            {isDragReject && "Fichero no es válido, pruebe con una Imagen"}
                                            {isFileTooLarge && (
                                                <div className="text-danger mt-2">
                                                    Demasiado Grande
                                                </div>
                                            )}
                                        </p>
                                    </div>)
                            }


                        </div>


                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>
                        {imgSrc === "" && webcam &&
                                                            <button type="button" className="btn btn-primary" onClick={capture}>Foto
                                    </button>
                        }
                        {imgSrc !== "" &&
                        (recortado ?
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        setRecortado(false)
                                    }}>Volver a Imagen
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2"
                                            onClick={handleUpload}>Guardar
                                    </button>
                                </div>
                                :
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => {
                                        setImgSrc("")

                                    }}>Cambiar Imagen
                                    </button>
                                    <button type="button" className="btn btn-primary ml-2"
                                            onClick={recortar}>Recortar
                                        Area
                                    </button>
                                </div>
                        )}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Foto