import React, {useState} from 'react';
import 'whatwg-fetch';
import {Modal} from "react-bootstrap";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import "./Notas.css"


function AltaPauta(props) {

    const newPauta = {
        paciente: "",
        usuario: "",
        texto: "",
    }

    const [pauta, setPauta] = useState(newPauta)

    function handle_input(e) {

        const {value} = e.target
        setPauta(prev => ({...prev, texto: value}))
    }

    function handle_paciente(value, object) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/notas/pauta/?paciente=' + value
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',

            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    if (response.length > 0) {
                        setPauta(response[0])
                    } else
                    setPauta({...newPauta, paciente:value})
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        setPauta(prev => ({...prev, paciente: value}))
    }

    function crear_nota(e) {
        let {user} = sessionStorage.getItem('userToken') !== null && JSON.parse(sessionStorage.getItem('userToken'));
        const data = {...pauta, usuario: user.id}
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/notas/pauta/'
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setPauta(newPauta)
                    props.alta(response)
                    props.handleClose()

                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Alta Pauta</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className=" ml-3 mr-2 ">Paciente</label>
                        {props.pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3 select-1"
                            value={pauta.paciente}
                            options={[{name: "...", value: ""}, ...props.pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }
                    </div>

                    <div className="form-group ">
                        <label htmlFor="texto">Texto</label>
                        <textarea id="textoPauta" rows="10"
                                  className={` w-100 form-control `}
                                  name="textoPauta" value={pauta.texto} onChange={handle_input}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar</button>

                    <button type="button" className="btn btn-primary" onClick={crear_nota}
                            disabled={pauta.texto === "" || pauta.paciente === ""}>Crear
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default AltaPauta;