import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import "./Facturacion.css"
import UpdateServicio from "./UpdateServicio"
import moment from "moment"

// import AltaNota from "./AltaNota";


function Servicios(props) {
    const [tarifas, setTarifas] = useState([])
    const [tarifasTabla, setTarifasTabla] = useState([])
    const [showServicio, setShowServicio] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [pacientesTabla, setPacientesTabla] = useState([])
    const [paciente, setPaciente] = useState()
    const [showUpdate, setShowUpdate] = useState(false)
    const [servicio, setServicio] = useState({})
    const [searchActivo, setSearchActivo] = useState("True")
    const [showTabla, setShowTabla] = useState(true)

    useEffect(() => {

            if (props.paciente !== undefined) {
                setPaciente(props.paciente)
            }
            if (props.centro.id !== undefined) {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/?activo=True&centro=' + props.centro.id
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientes(response)

                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })

                query_tarifas(paciente, searchActivo)
            }
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function query_tarifas(paciente, activo) {
        authHeaders().then(authHeader => {
                let endpoint = APIPATH + 'api/facturacion/tarifas/?centro=' + props.centro.id

                if (props.paciente !== undefined) {
                    endpoint = endpoint + "&paciente=" + props.paciente
                } else if (paciente !== undefined) {
                    endpoint = endpoint + "&paciente=" + paciente
                }
                if (activo !== undefined && activo !== "") {
                    endpoint = endpoint + "&activo=" + activo
                }
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    // body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        if (response.ok) {
                            response.json()
                                .then(
                                    response => {
                                        setTarifas(response)
                                        setShowServicio(Array(response.length).fill(true))
                                    }
                                )
                        } else {
                            setTarifas([])
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            }
        )
        authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/facturacion/servicios/tabla/?centro=' + props.centro.id
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientesTabla(response.pacientes)
                            setTarifasTabla(response.tarifas)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
    }


    function handle_paciente(value, object) {
        if (value === 0) {
            query_tarifas()
        } else {
            query_tarifas(value, searchActivo)
        }
        setPaciente(value)

    }

    function alta_servicio(servicio) {
        query_tarifas(paciente, searchActivo)
    }

    function cambia_vista_servicio(pos) {
        const vista = [...showServicio]
        vista[pos] = !showServicio[pos]
        setShowServicio(vista)
    }


    return (
        <div>
            <div className="row mt-4">
                <div className="col-12 bg-white px-5 pt-2">
                    <div className="row">

                        <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={() => {
                                    setServicio("")
                                    setShowUpdate(true)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <h3 className="col-4 text-primary">Servicios</h3>
                        <div className="col-5">
                            {!showTabla &&
                            <div className="row">
                                {props.paciente === undefined &&
                                <div className="row">
                                    <label className=" ml-5 col-1 mr-3">Paciente</label>
                                    {pacientes.length > 0 &&
                                    <SelectSearch
                                        className="select-search ml-3 col-8"
                                        value={paciente}
                                        options={[{name: "...", value: 0}, ...pacientes.map((paciente, index) =>
                                            ({name: paciente.alias, value: paciente.id})
                                        )]}
                                        search
                                        printOptions="on-focus"
                                        placeholder={" ... "}
                                        onChange={handle_paciente}
                                    />
                                    }
                                </div>}
                                <select name="search_activo" className="form-control col-2"
                                        onChange={(e) => {
                                            setSearchActivo(e.target.value)
                                            query_tarifas(paciente, e.target.value)
                                        }}
                                        value={searchActivo}
                                >
                                    <option value="">Todos</option>
                                    <option value="True">Activos</option>
                                    <option value="False">Inactivos</option>

                                </select>
                            </div>}
                        </div>
                        <div className="ml-auto col-1">
                            <button type="button" className="btn-link bg-white border-0" onClick={() => {
                                setShowTabla(prev => !prev)
                            }}>
                                {showTabla ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-list"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                    </svg> :
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-table"
                                         fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {!showTabla ?
                    <div className={` col-11 px-4 mx-5 mt-4`}>
                        {tarifas.map((tarifa, index) => {
                                if (tarifa.servicios.length > 0) {
                                    return (
                                        <div
                                            key={index}
                                        >
                                            <div className="row tarifa-header py-2 px-4 ">
                                                <h4 className="col-10">{tarifa.nombre}</h4>
                                                <button type="button" className="border-0 col-2 btn-link tarifa-header"
                                                        onClick={() => cambia_vista_servicio(index)}>
                                                    {!showServicio[index] ?
                                                        <svg width="2em" height="2em" viewBox="0 0 16 16"
                                                             className="bi bi-caret-down" fill="currentColor"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd"
                                                                  d="M3.204 5L8 10.481 12.796 5H3.204zm-.753.659l4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z"/>
                                                        </svg> :
                                                        <svg width="2em" height="2em" viewBox="0 0 16 16"
                                                             className="bi bi-caret-up" fill="currentColor"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd"
                                                                  d="M3.204 11L8 5.519 12.796 11H3.204zm-.753-.659l4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z"/>
                                                        </svg>}
                                                </button>
                                            </div>
                                            {showServicio[index] && tarifa.servicios.map((servicio, index) =>
                                                <div className="row bg-white pt-2 " key={index}>
                                                    <div className={`col-1 `}>
                                                        {servicio.img &&
                                                        <img className="img-fluid" src={`${servicio.img}`}
                                                             alt=""/>
                                                        }
                                                    </div>

                                                    <div
                                                        className="font-weight-bold text-medium col-2">{servicio.alias}</div>
                                                    <div className="col-3">
                                                        <button type="button" className="link-button bg-white" onClick={
                                                            (e) => {
                                                                setServicio(servicio)
                                                                setShowUpdate(true)
                                                            }
                                                        }>
                                                            {servicio.concepto}
                                                        </button>
                                                    </div>
                                                    <div
                                                        className="col-1">{Number(servicio.importe).toLocaleString('de-DE', {
                                                        style: 'currency',
                                                        currency: 'EUR'
                                                    })}</div>
                                                    <div
                                                        className="col-1">{servicio.periodicidad.replace(/^\w/, (c) => c.toUpperCase())}</div>
                                                    <div className="col-2">Inicio
                                                        : {moment(servicio.fecha_inicio).format('DD [de] MMMM YYYY')}</div>
                                                    {servicio.facturar ?
                                                        <span
                                                            className="col-1 mb-5 mt-3 pl-5 bg-light border border-secondary">Factura</span> :
                                                        <span
                                                            className="col-1 mb-5 mt-3 pl-5 "></span>
                                                    }
                                                    <div className="col-1">Activo : <input type="checkbox"
                                                                                           checked={servicio.activo}
                                                                                           disabled={true}/></div>


                                                </div>
                                            )}
                                        </div>)
                                } else {
                                    return ""
                                }
                            }
                        )
                        }
                    </div>
                    :
                    <div className={` col-11 px-4 mx-5 mt-4`}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    Paciente
                                </th>
                                {tarifasTabla.map(tarifa =>
                                    <th key={tarifa.id} className="text-center">
                                        {tarifa.nombre}
                                    </th>
                                )}
                            </tr>
                            </thead>
                            <tbody>
                            {pacientesTabla.map(paciente =>
                                <tr key={paciente.id}>
                                    <td width="5%">
                                        {paciente.img &&
                                        <img className="img-fluid" src={`${paciente.img}`}
                                             alt=""/>
                                        }
                                    </td>
                                    <td><button type="button" className="link-button " onClick={
                                                            (e) => {
                                                                query_tarifas(paciente.id, searchActivo)
                                                                setPaciente(paciente.id)
                                                                setShowTabla(false)
                                                            }
                                                        }>
                                                            {paciente.alias}
                                                        </button>
                                        </td>
                                    {paciente.servicios.map((check, index) =>
                                        <td key={`${paciente.id}-${index}`} className="text-center">
                                        <input type="checkbox" disabled={true} checked={check} />
                                        </td>
                                    )}
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>}
            </div>
            <UpdateServicio
                show={showUpdate}
                handleClose={() => setShowUpdate(false)}
                pacientes={pacientes}
                servicio={servicio}
                alta={alta_servicio}

            />
        </div>
    )
}

export default Servicios