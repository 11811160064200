import React, {useState, useEffect} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import SelectSearch from "react-select-search";

// import XLSX from 'xlsx'

function Movimientos(props) {
    const [pacientes, setPacientes] = useState([])
    const [paciente, setPaciente] = useState()
    const [meses, setMeses] = useState([])
    const [depositos, setDepositos] = useState([])
    const [deposito, setDeposito] = useState()
    const [registro, setRegistro] = useState()
    const [anno, setAnno] = useState(new Date().getFullYear())
    const [centros, setCentros] = useState([])
    const [centro, setCentro] = useState()
    const [tarifas, setTarifas] = useState([])
    const [pacientesExp, setPacientesExp] = useState([])
    const [gruposExp, setGruposExp] = useState([])
    const [facturacion, setFacturacion] = useState([])
    const [gastos, setGastos] = useState([])
    const [annos, setAnnos] = useState()
    const [showPacientes, setShowPacientes] = useState(false)

    useEffect(() => {

        setAnnos([...Array.from(Array(5)).map((x, i) => (
            {value: anno - i, name: (anno - i).toString()}))])

            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/depositos/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setDepositos(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setPacientes(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/centro/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setCentros(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            query_movimientos(paciente, anno, deposito, registro, centro)

// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function query_movimientos(paciente, anno, deposito, registro, centro) {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/contabilidad/explotacion/?usuario=True'
            if (paciente !== undefined) {
                endpoint = endpoint + "&paciente=" + paciente
            }
            if (anno !== undefined) {
                endpoint = endpoint + "&anno=" + anno
            }
            if (deposito !== undefined && deposito !== 0) {
                endpoint = endpoint + "&deposito=" + deposito
            }
            if (registro !== undefined) {
                endpoint = endpoint + "&registro=" + registro
            }
            if (centro !== undefined) {
                endpoint = endpoint + "&centro=" + centro
            }


            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setMeses(response.meses)
                    setTarifas(response.tarifas)
                    setPacientesExp(response.pacientes)
                    setGruposExp(response.grupos)
                    setFacturacion(response.facturacion)
                    setGastos(response.gastos)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    function handle_anno(value, object) {
        setAnno(value)
        query_movimientos(paciente, value, deposito, registro, centro)

    }

    function handle_paciente(value, object) {
        if (value === 0) {
            setPaciente()
            query_movimientos(undefined, anno, deposito, registro, centro)
        } else {
            setPaciente(value)
            query_movimientos(value, anno, deposito, registro, centro)
        }
    }

    function handle_deposito(value, object) {
        if (value === 0) {
            setDeposito()
            query_movimientos(paciente, anno, undefined, registro, centro)
        } else {
            setDeposito(value)
            query_movimientos(paciente, anno, value, registro, centro)
        }
    }


    function handle_registro(value, object) {
        if (value === 0) {
            setRegistro()
            query_movimientos(paciente, anno, deposito, undefined, centro)
        } else {
            setRegistro(value)
            query_movimientos(paciente, anno, deposito, value, centro)
        }
    }

    function handle_centro(value, object) {
        if (value === 0) {
            setCentro()
            query_movimientos(paciente, anno, deposito, registro, undefined)
        } else {
            setCentro(value)
            query_movimientos(paciente, anno, deposito, registro, value)
        }
    }


    function excel_export() {
        // let data = [[
        //     "Fecha", "Deposito", "Concepto", "GrupoMovimiento", "Centro",
        //     "Paciente", "Factura", "Signo", "Etiqueta", "Suplido", "Traspaso", "Registro", "Factura_suplido", "ImporteTotal",
        //     "ImporteNeto", "Usuario", "Proveedor", "FacturaProveedor", "Oculto"]]
        // movimientos.forEach(mov => {
        //     const movArray = [moment(mov.fecha).format('DD-MM-YY'), mov.deposito_nombre, mov.concepto,
        //         mov.grupo_nombre, mov.centro, mov.paciente_alias, mov.factura, mov.signo, mov.etiqueta, mov.suplido,
        //         mov.traspaso, mov.registro, mov.factura_suplido, mov.importeTotal, mov.importeNeto, mov.user, mov.proveedor, mov.factura_proveedor, mov.oculto]
        //     data.push(movArray)
        // })
        // const wb = XLSX.utils.book_new()
        // const wsAll = XLSX.utils.aoa_to_sheet(data)
        // XLSX.utils.book_append_sheet(wb, wsAll, "Movimientos")
        // XLSX.writeFile(wb, "movimientos.xlsx")
    }

    return (
        <div className="no-overflow">
            <div className="row px-5 py-2 bg-white">

                <label className="ml-3">Año</label>
                {annos !== undefined && anno !== undefined &&
                <SelectSearch
                    className="select-search select-1 col-1"
                    value={anno}
                    options={annos}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_anno}
                />}
                <label className="ml-3">Deposito</label>
                <SelectSearch
                    className="select-search select-1 col-2  "
                    value={deposito}
                    options={[{name: "...", value: 0}, ...depositos.map((deposito, index) =>
                        ({name: deposito.nombre, value: deposito.id})
                    )]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_deposito}
                />
                <label className="ml-3">Paciente</label>
                <SelectSearch
                    className="select-search select-1 col-2  "
                    value={paciente}
                    options={[{name: "...", value: 0}, ...pacientes.map((paciente, index) =>
                        ({name: paciente.alias, value: paciente.id})
                    )]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_paciente}
                />
                <label className="ml-3">Centro</label>
                <SelectSearch
                    className="select-search select-1 col-1  "
                    value={centro}
                    options={[{name: "...", value: 0}, ...centros.map((centro, index) =>
                        ({name: centro.nombre, value: centro.id})
                    )]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_centro}
                />
                <label className="ml-3 mr-2">Libro</label>
                <SelectSearch
                    className="select-search select-1 col-1 "
                    value={registro}
                    options={[{name: "Todos", value: 0}, {name: "No", value: "N"}, {
                        name: "SI",
                        value: "S"
                    }]}
                    search
                    printOptions="on-focus"
                    placeholder={" ... "}
                    onChange={handle_registro}
                />
                <button type="button" onClick={excel_export} className="link-button ml-auto mr-4"
                        data-container="true"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Exportar a Excel"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                        <path
                            d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"/>
                        <path
                            d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                </button>


            </div>
            <div className="bg-white row pt-3 px-3" style={{minHeight: '810px'}}>
                <div className="col-2">
                    <div style={{height: "40px"}}></div>
                    <div className="row bg-light border-top border-bottom py-0 mr-1 ">
                        <h5 className="text-primary my-0 py-2 px-3">INGRESOS</h5>
                        <button className="link-button ml-auto mr-3" onClick={() => setShowPacientes(prev => !prev)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                 className="bi bi-arrow-counterclockwise" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                      d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"/>
                                <path
                                    d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"/>
                            </svg>
                        </button>


                    </div>
                    {showPacientes ?
                        pacientesExp.map((paciente, index) =>
                            <div className="text-medium border-bottom" key={index}
                                 style={{height: "26px"}}>{paciente}</div>
                        )
                        :
                        tarifas.map((tarifa, index) =>
                            <div className="text-medium border-bottom" key={index}>{tarifa}</div>
                        )}
                    <div className="text-medium border-bottom py-2 font-weight-bold bg-light">Total</div>
                    <h5 className="text-primary border-top bg-light border-bottom my-0 py-2 px-3">GASTOS</h5>
                    {
                        gruposExp.map((grupo, index) =>
                            <div className="text-medium border-bottom" key={index}
                                 style={{height: "26px"}}>{grupo}</div>
                        )
                    }
                    <div className="text-medium border-bottom py-2 bg-light">Total</div>
                    <div className="text-medium border-bottom py-2 text-primary bg-light">Resultado</div>
                </div>


                <div className="col-9">
                    <div className="row" style={{height: "40px"}}>
                        {meses.map((m, index) =>
                            <div key={index} className="col-1 pt-2 border text-center">{m}</div>
                        )}
                    </div>
                    <div className="row bg-light border-bottom" style={{height: "42px"}}></div>

                    <div className="row">
                        {facturacion.map((mes, index) =>
                            <div key={index} className="col-1 ">
                                {showPacientes ?
                                 mes.pacientes.map((paciente, index) =>
                                    <div key={index} className="row border-right border-bottom px-2"
                                         style={{height: "26px"}}>
                                        <p className="text-right w-100">{paciente && parseFloat(paciente).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                            {paciente && "€"}</p>
                                    </div>
                                )
                                    :
                                    mes.grupos.map((grupo, index) =>
                                    <div key={index} className="row border-right border-bottom px-2"
                                         style={{height: "26px"}}>
                                        <p className="text-right w-100">{grupo && parseFloat(grupo).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                            {grupo && "€"}</p>
                                    </div>
                                )}
                                <div className="row bg-light border-bottom font-weight-bold border-right px-2 pt-2"
                                     style={{height: "42px"}}><p
                                    className="text-right w-100">{mes.total && parseFloat(mes.total).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                    {mes.total && "€"}</p>
                                </div>
                                <div className="row bg-light border-bottom" style={{height: "42px"}}></div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        {gastos.map((mes, index) =>
                            <div key={index} className="col-1 ">
                                {mes.grupos.map((grupo, index) =>
                                    <div key={index} className="row border-right border-bottom px-2"
                                         style={{height: "26px"}}>
                                        <p className="text-right w-100">{grupo && parseFloat(grupo).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                            {grupo && "€"}</p>
                                    </div>
                                )}
                                <div className="row bg-light border-bottom font-weight-bold border-right px-2 pt-2"
                                     style={{height: "42px"}}><p
                                    className="text-right w-100">{mes.total && parseFloat(mes.total).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                    {mes.total && "€"}</p>
                                </div>

                            </div>
                        )}
                    </div>
                    <div className="row bg-light border-bottom pt-2" style={{height: "42px"}}>
                        {facturacion.map((factMes, index) =>
                            <div className="col-1" key={index}>
                                <p className="text-right w-100 text-primary font-weight-bold">
                                    {factMes.total && gastos.length > 0 && (parseFloat(factMes.total) - parseFloat(gastos[index].total)).toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                    {factMes.total && "€"}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Movimientos