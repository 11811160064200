import React, {useEffect, useState} from 'react';
import SelectSearch from 'react-select-search';
import "./select.css"
import SearchMedicamento from "./SearchMedicamento";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import {useMountedState} from "react-use";
import {useDrag} from 'react-dnd'
import {ItemTypes} from "./ItemTypes";
import "./Medicaciones.css"

function MedicacionInline(props) {
    const isMounted = useMountedState();
    const [medicacion, setMedicacion] = useState({
        nombre_medicamento: "",
        dias: "1",
        manana: "1",
        mediodia: "1",
        tarde: "1",
        noche: "1",
        notas: "",
        unidades: "1",
        medicamento: "",
    })
    const [medicamento, setMedicamento] = useState(
        {
            "nregistro": "",
            "nombre": "",
            "prospecto": "",
            "ficha_tecnica": "",
            "foto": "",
            "formaFarmaceutica": "",
            "principioActivo": "",
            "via": "",
            "dosis": ""
        });
    const [medicamentos, setMedicamentos] = useState([])
    const [showSearchMedicamento, setShowSearchMedicamento] = useState(false)
    const [{isDragging}, drag] = useDrag({
        item: {id: medicacion.medicamento, type: ItemTypes.MEDICAMENTO},
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
                props.addCompra(item.id)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });


    useEffect(() => {
        if (isMounted()) {
            setMedicacion(props.medicacion)
            setMedicamentos(props.medicamentos)
            loadMedicamento(props.medicacion.medicamento)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    function loadMedicamento(med) {
        if (med !== "") {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/medicamentos/medicamento/detail/' + med + "/"
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        if (isMounted()) {
                            setMedicamento(response)
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    function changeLinea(med) {
        if (isMounted()) {
            if (medicamentos.filter(m => m.id === med.id).length === 0) {
                setMedicamentos(prev => ([...prev, med]))
            }
            const medi = {...medicacion, medicamento: med.id}
            setMedicacion(medi)
            props.updateMedList(props.id, medi, med)
        }
    }

    function handleInput(e) {
        let {name, value} = e.target
        const regexp = /^\d+(\.\d{0,1})?$/;
        if (value !== "") {
            if (['manana', 'mediodia','tarde', 'noche'].includes(name) && !regexp.test(value)) {
                value = "0"
            }
            /* eslint eqeqeq: 0 */
            if (['dias', 'unidades'].includes(name) && value != parseInt(value, 10)) {
                value = "1"
            }
        }
        setMedicacion(prev => ({...prev, [name]: value}))
    }

    function handle_medicamentos(value, object) {
        if (isMounted()) {
            const medi = {...medicacion, medicamento: value}
            setMedicacion(medi)
            props.updateMed(props.id, medi)
        }
    }

    return (
        <div className=" col-12 mb-2 medicamento-area">

            <div className="row ">
                <div className={`col-11 px-0 mr-0`}>
                    <SelectSearch
                        className="select-search w-100 mb-2 "
                        value={medicacion.medicamento}
                        options={medicamentos.filter(med => !props.selMedList.includes(med.id) || med.id === medicacion.medicamento)
                            .map(med =>
                                ({
                                    name: med.nombre.length > 60 ? med.nombre.substr(0, 57) + '...' : med.nombre,
                                    value: med.id
                                })
                            )}
                        search
                        printOptions="on-focus"
                        placeholder="Seleccione Medicamento"
                        onChange={handle_medicamentos}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-1 ml-0 pl-0">
                    <div className="row">

                        <div className="nav-item dropdown col-3 px-0">
                            <button className="nav-link border-0 ml-3 mr-0 px-0 bg-white" data-toggle="dropdown"
                                    type="button"
                                    aria-haspopup="true" aria-expanded="false">
                                <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-list"
                                     fill="currentColor"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                          d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                                </svg>
                            </button>
                            <div className="dropdown-menu">
                                {medicamento.prospecto !== "" &&
                                <a className="dropdown-item" href={medicamento.prospecto}
                                   target="_blank" rel="noopener noreferrer">
                                    Prospecto
                                </a>}
                                {medicamento.ficha_tecnica !== "" &&
                                <a className="dropdown-item" href={medicamento.ficha_tecnica}
                                   target="_blank" rel="noopener noreferrer">
                                    Ficha Técnica
                                </a>}
                                <div className="dropdown-divider"></div>
                                {!props.disabled &&
                                <button type="button" className="border-0 col-1 bg-white text-danger" onClick={(e) => {
                                    props.deleteMed(props.id)
                                }}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                        <path fillRule="evenodd"
                                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                    </svg>
                                </button>
                                }

                            </div>
                        </div>
                        {!props.disabled &&
                        <button type="button" className={`border-0 col-3 bg-white text-primary ml-auto px-0`}
                                onClick={() => {
                                    setShowSearchMedicamento(true)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-binoculars"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M3 2.5A1.5 1.5 0 0 1 4.5 1h1A1.5 1.5 0 0 1 7 2.5V5h2V2.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5v2.382a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V14.5a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 14.5v-3a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5v3A1.5 1.5 0 0 1 5.5 16h-3A1.5 1.5 0 0 1 1 14.5V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V2.5zM4.5 2a.5.5 0 0 0-.5.5V3h2v-.5a.5.5 0 0 0-.5-.5h-1zM6 4H4v.882a1.5 1.5 0 0 1-.83 1.342l-.894.447A.5.5 0 0 0 2 7.118V13h4v-1.293l-.854-.853A.5.5 0 0 1 5 10.5v-1A1.5 1.5 0 0 1 6.5 8h3A1.5 1.5 0 0 1 11 9.5v1a.5.5 0 0 1-.146.354l-.854.853V13h4V7.118a.5.5 0 0 0-.276-.447l-.895-.447A1.5 1.5 0 0 1 12 4.882V4h-2v1.5a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V4zm4-1h2v-.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V3zm4 11h-4v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V14zm-8 0H2v.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V14z"/>
                            </svg>
                        </button>}
                    </div>
                </div>
            </div>

            <div className="row ">
                <div className="col-3 py-0 my-0 drag-item" ref={props.last ? drag : null}>
                    {medicamento.foto !== "" ?
                        <img className={`img-fluid ${isDragging && 'img-dragging'}`} src={medicamento.foto} alt=""/>
                        :
                        <img className={`img-fluid ${isDragging && 'img-dragging'}`}
                             src={APIPATH + "static/media/cajamedicamentos.jpg"} alt=""/>
                    }
                </div>
                <div className="col-4">
                    <div className="row ">
                        <p className="py-1 mr-2">Tomas</p>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.manana}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="manana" onChange={handleInput}/>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.mediodia}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="mediodia" onChange={handleInput}/>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.tarde}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="tarde" onChange={handleInput}/>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.noche}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="noche" onChange={handleInput}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <p className="py-1 mr-2">Cada</p>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.dias}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="dias" onChange={handleInput}/>
                        <p className="py-1 ml-2">Día(s)</p>
                    </div>
                </div>
                <div className="col-2">
                    <div className="row">
                        <p className="py-1 mr-2">Uds</p>
                        <input type="text" className="form-control medicacion-tomas" value={medicacion.unidades}
                               onBlur={e => {
                                   props.updateMed(props.id, medicacion)
                               }} disabled={props.disabled} name="unidades" onChange={handleInput}/>
                    </div>
                </div>
            </div>
            <SearchMedicamento
                show={showSearchMedicamento}
                handleClose={() => setShowSearchMedicamento(false)}
                changeLinea={changeLinea}
            />
        </div>

    )

}

export default MedicacionInline;