import React, {useEffect, useState} from 'react';
import {authHeaders} from "../../Utils/authUtils";
import {APIPATH} from "../../settings";
import moment from 'moment';
import 'moment/locale/es';

import "./Anamnesis.css"

function Actual(props) {

    const [anamnesis, setAnamnesis] = useState({
        paciente: null,
        enfermedades: "",
        alergias: "",
        motivo_consulta: "",
        inicio_y_curso: "",
        episodios_previos: "",
        factores_desencadenantes: "",
        autodescripcion: "",
        cambio: "",
        expectativas: [],
    })
    // const [formErrors, setFormErrors] = useState({})
    const [cambio, setCambio] = useState({})
    const [logCambio, setLogCambio] = useState([])
    const [expectativas, setExpectativas] = useState([])

    useEffect(() => {

        if (Object.keys(props.anamnesis).length !== 0) {
            Object.keys(props.anamnesis).forEach(key => {
                if (props.anamnesis[key] === null) {
                    props.anamnesis[key] = ""
                }
            })
            setAnamnesis(props.anamnesis)
        }
        //
        // Carga estados_cambio
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/estado_cambio/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setCambio(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga expectativas
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/expectativas/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setExpectativas(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga logs de cambio
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/anamnesis/log/?paciente=${props.alias}&field=cambio`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setLogCambio(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [props])

    function delete_log(event) {
        const removeId = event.currentTarget.dataset.id
        console.log(removeId)
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/anamnesis/log/delete/${removeId}/`
            const lookupOptions = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    if (response.ok) {
                        setLogCambio(prevState => prevState.filter(value => value.id !== parseInt(removeId)))
                    }
                }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }

    // function handle_input(event) {
    //     let {name, value} = event.target
    //     // if (name === 'cambio') {
    //     //     value = checked
    //     // }
    //     setAnamnesis(previous => {
    //         return {...previous, [name]: value}
    //     })
    // }

    function handle_expectativa(event) {
        let {name, value, checked} = event.target

        if (checked) {
            props.update({
                paciente: anamnesis.paciente, [name]: [...anamnesis[name], {expectativa: value}]
            })
        } else {
            props.update({
                paciente: anamnesis.paciente,
                [name]: [...anamnesis[name].filter(expectativa => expectativa.expectativa !== value)]
            })
        }
    }

    // function handle_blur(event) {
    //     let {name, value} = event.target
    //     props.update({paciente: anamnesis.paciente, [name]: value})
    // }


    function submit_input(event) {
        let {name, value} = event.target
        props.update({paciente: anamnesis.paciente, [name]: value})
        // setAnamnesis(previous => {
        //     return {...previous, [name]: value}
        // })
    }


    return (
        <form className="text-small">
            {/*<div className="row mt-4">*/}
            {/*    <div className="form-group col-6">*/}
            {/*        <label htmlFor="enfermedades">Enfermedades</label>*/}
            {/*        <textarea id="enfermedades"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="enfermedades" value={anamnesis.enfermedades} onChange={handle_input}*/}
            {/*            // onBlur={(e) => {*/}
            {/*            //     props.update({id:anamnesis.id, paciente:anamnesis.paciente, enfermedades:anamnesis.enfermedades})*/}
            {/*            // }}*/}
            {/*                  onBlur={handle_blur}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-6">*/}
            {/*        <label htmlFor="enfermedades">Alergias</label>*/}
            {/*        <textarea id="alergias"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="alergias" value={anamnesis.alergias} onChange={handle_input} onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="row">*/}
            {/*    <div className="form-group col-9">*/}
            {/*        <label htmlFor="motivo_consulta">Motivo de la Consulta</label>*/}
            {/*        <textarea id="motivo_consulta"*/}
            {/*                  className={`w-100 form-control text-small`} rows={4}*/}
            {/*                  name="motivo_consulta" value={anamnesis.motivo_consulta} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-3">*/}
            {/*        <label htmlFor="inicio_y_curso">Inicio y curso</label>*/}
            {/*        <textarea id="inicio_y_curso"*/}
            {/*                  className={`w-100 form-control text-small`} rows={4}*/}
            {/*                  name="inicio_y_curso" value={anamnesis.inicio_y_curso} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="row">*/}

            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="episodios_previos">Episodios Previos</label>*/}
            {/*        <textarea id="episodios_previos"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="episodios_previos" value={anamnesis.episodios_previos} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="factores_desencadenantes">Factores Desencadenantes</label>*/}
            {/*        <textarea id="factores_desencadenantes"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="factores_desencadenantes" value={anamnesis.factores_desencadenantes}*/}
            {/*                  onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="autodescripcion">Autodescripcion</label>*/}
            {/*        <textarea id="autodescripcion"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="autodescripcion" value={anamnesis.autodescripcion} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row mt-5">
                <div className="col-8 ml-3 border">
                    <div className="row">
                        <div className="col-8 mt-2">
                            <p>Grado de autoconciencia (Prochaska y Di Clemente): </p>
                            {Object.keys(cambio).map(key =>
                                <div className="form-check mt-3" key={key}>
                                    <input className="form-check-input" type="radio" name="cambio"
                                           id={`cambio-${key}`}
                                           value={key} checked={anamnesis.cambio === key} onChange={submit_input}/>
                                    <label className="form-check-label text-small2" htmlFor={`cambio-${key}`}>
                                        {cambio[key]} <strong>({key})</strong>
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className="col-4 log-cambio ml-auto">
                            {logCambio.map((log, i) => {
                                    return (<div key={log.fecha}>
                                        <div className="row">
                                            <button type="button" className="border-0 area-opciones" onClick={delete_log}
                                                    data-id={log.id}>
                                                <svg width="1em" height="1em" viewBox="0 0 16 16"
                                                     className="bi bi-x-circle" fill="currentColor"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                    <path fillRule="evenodd"
                                                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                </svg>
                                            </button>
                                            <span className="badge badge-pill fecha-log">
                                                {moment(log.fecha).format('D MMM YY')}
                                            </span>
                                            <span className="capitalize ml-2">
                                                {log.value_char}
                                            </span>

                                        </div>
                                        {i < logCambio.length - 1 &&
                                        <div className="separacion-log"></div>
                                        }
                                    </div>)
                                }
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-3 ml-auto mr-4 border ">
                    Expectativas
                    {Object.keys(expectativas).map(key => (
                        <div className="form-check mt-2" key={key}>
                            <input className="form-check-input" type="checkbox" name="expectativas" value={key}
                                   id={key} checked={anamnesis.expectativas.map(exp => exp.expectativa).includes(key)}
                                   onChange={handle_expectativa}/>
                            <label className="form-check-label text-small2" htmlFor={key}>
                                {expectativas[key]}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        </form>


    )

}

export default Actual