import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import UserInline from "./UserInline";
import {authHeaders} from "../Utils/authUtils";
import CreateUser from "./CreateUser";

// import './App.css';


function Users() {
    const [userList, setUserList] = useState([])
    let {user, is_manager} = sessionStorage.getItem('userToken') !== null && JSON.parse(sessionStorage.getItem('userToken'));

    function loadUsers() {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(
                response => {
                    setUserList(response)
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function addUser(user) {

        setUserList(previous => {
            return [...previous, user]
        })
    }

    function delete_user(user) {
        setUserList(prevState => prevState.filter(profile => profile.user.username !== user.user.username))
    }

    useEffect(() => {
            loadUsers();
        }, []
    );

    return (
        <div className=" paciente-area overflow-auto">

            <div className="row bg-white w-100 m-0">
                <div className="col-1">
                    <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-white text-success"
                            data-toggle="modal"
                            data-target="#createUserModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                </div>
                <h3 className="col-11 text-primary">Usuarios</h3>
            </div>
            <table className="bg-white table">
                <thead>

                <tr className={"text-primary"}>
                    <th width="15%">
                        Usuario
                    </th>
                    <th colSpan="2" width="50%">Email</th>
                    <th>Administrador</th>
                    <th>Anamnesis</th>
                    <th>Monitor</th>
                    <th>Contabilidad</th>
                    <th>Extension Contabilidad</th>
                    <th>Externo</th>
                    <th>Activo</th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {userList != null && userList.map(profile =>
                    <UserInline
                        key={profile.user.id}
                        id={profile.user.id}
                        profile={profile}
                        user={user}
                        is_manager={is_manager}
                        delete_user={delete_user}
                    />
                )}
                </tbody>
            </table>
            {is_manager && <CreateUser
                modalId="createUserModal"
                addUser={addUser}
            />}

        </div>
    );
}

export default Users;
