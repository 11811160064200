import React, {useState, useEffect} from "react";
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import SelectSearch from 'react-select-search';
import "../Utils/select.css"
import {Editor} from "@tinymce/tinymce-react";
import './Terapias.css'
import ModalMsg from "../Utils/ModalMsg";


function Programacion(props) {
    const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
    const [userList, setUserList] = useState([])
    const [disabled, setDisabled] = useState(true)
    const [programacionList, setProgramacionList] = useState([])
    const [externos, setExternos] = useState([])
    const [selectExternos, setSelectExternos] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [selectPacientes, setSelectPacientes] = useState([])
    const [dias, setDias] = useState([]
    )
    const [programacion, setProgramacion] = useState({
        id: "",
        dias: [],
        nombre: "",
        comentarios: "",
        activo: true,
        centro: props.centro,
        terapeuta: null
    })
    const [modalMsg, setModalMsg] = useState({
        titulo: "",
        mensaje: "",
        show: false
    })

    function newProgramacion() {
        setProgramacion({
            id: "",
            dias: [],
            nombre: "",
            comentarios: "",
            activo: true,
            centro: props.centro,
            terapeuta: null
        })
        const diasList = []
        for (let i = 1; i < 8; i++) {
            diasList.push({
                asistentes: [],
                dia: i,
                hora: "",
                minutos: "",
            })
        }
        setDias(diasList)
    }


    useEffect(() => {
        if (props.centro !== undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/?activo=True'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setExternos(response.filter(paciente => paciente.centro !== props.centro))
                        setPacientes(response.filter(paciente => paciente.centro === props.centro))

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/programaciones/?centro=' + props.centro
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setProgramacionList(response)
                        newProgramacion()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/accounts/profile/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUserList(response.filter(profile => profile.user.is_active).map((profile) =>
                            ({id: profile.user.id, name: profile.user.username})
                        ))
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    function handle_programacion(value, object) {
        const response = programacionList.filter(prog => prog.id === value)[0]

        let externosList = []
        const diasList = []
        const extidList = externos.map(externo => externo.id)
        for (let i = 1; i < 8; i++) {
            if (response.dias.filter(value => value.dia === i).length > 0) {
                diasList.push(response.dias.filter(value => value.dia === i)[0])
                externosList = [...externosList, ...response.dias.filter(value => value.dia === i)[0].asistentes.filter(asistente => extidList.includes(asistente.paciente)).map(asistente => asistente.paciente)]
            } else {
                diasList.push({
                    asistentes: [],
                    dia: i,
                    hora: "",
                    minutos: "",
                    programacion: response.id
                })
            }
        }
        setSelectExternos([...new Set(externosList)])



        const tmpPac = [...pacientes, ...externos.filter(paciente => externosList.includes(paciente.id))].map(value => ({
            name: value.alias,
            value: value.id
        }))
        setSelectPacientes(tmpPac)
        response.dias.forEach(dia =>
            {
               dia.asistentes= dia.asistentes.filter(asistente => tmpPac.map(paciente => paciente.value).includes(asistente.paciente))
            }

        )

        setProgramacion(response)
        setDias(diasList)

        //         setDias(newList)
        // setTimeout(function () {
        //     setSelectPacientes(nuevoSelectPacientes)
        // }, 200);
    }

    function handle_input(event) {

        let {name, value} = event.target

        setProgramacion(previous => {
            return {...previous, [name]: value}
        })

    }

    function handle_editor(content, editor) {
        setProgramacion(previous => ({...previous, comentarios: content}))
    }

    function handle_terapeuta(value, object) {
        setProgramacion(prevState => ({
            ...prevState, terapeuta: value
        }))
    }

    function borrar_programacion(e) {
        e.preventDefault()
        if (programacion.tiene_terapias) {
            authHeaders().then(authHeader => {
                const data_dias = dias.map(dia => {
                    if (dia.hora === "") {
                        dia.hora = null
                        dia.minutos = null
                        dia.asistentes = []
                    } else if (dia.minutos === "") {
                        dia.minutos = 0
                    }
                    return dia
                })
                const data = {...programacion, dias: data_dias, activo: false}
                const endpoint = APIPATH + 'api/terapias/programaciones/detail/' + programacion.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        newProgramacion()
                        setProgramacionList(prev => ([...prev.filter(item => item.id !== response.id)]))
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        } else {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/programaciones/detail/' + programacion.id + '/'
                const lookupOptions = {
                    method: "DELETE",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include'
                }
                fetch(endpoint, lookupOptions).then(
                    response => {
                        setProgramacionList(prev => ([...prev.filter(item => item.id !== programacion.id)]))
                        newProgramacion()
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        }
        setDisabled(true)
    }

    function submitForm(e) {
        e.preventDefault()
        if (programacion.id === "") {
            const data_dias = [...dias.map(dia => ({
                ...dia,
                hora: dia.hora === "" ? null : dia.hora,
                minutos: dia.hora === "" ? null : dia.minutos === "" ? 0 : dia.minutos,
                asistentes: dia.hora === "" ? [] : dia.asistentes
            }))]
            const data = {...programacion, dias: data_dias}
            delete data.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/terapias/programaciones/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        const diasList = []
                        for (let i = 1; i < 8; i++) {
                            if (response.dias.filter(value => value.dia === i).length > 0) {
                                diasList.push(response.dias.filter(value => value.dia === i)[0])
                            } else {
                                diasList.push({
                                    asistentes: [],
                                    dia: i,
                                    hora: "",
                                    minutos: "",
                                    programacion: response.id
                                })
                            }
                        }
                        setDias(diasList)
                        setProgramacion(response)
                        setProgramacionList(prev => ([...prev, response]))
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        } else {
            authHeaders().then(authHeader => {
                const data_dias = dias.map(dia => {
                    if (dia.hora === "") {
                        dia.hora = null
                        dia.minutos = null
                        dia.asistentes = []
                    } else if (dia.minutos === "") {
                        dia.minutos = 0
                    }
                    return dia
                })
                const data = {...programacion, dias: data_dias}
                const endpoint = APIPATH + 'api/terapias/programaciones/detail/' + programacion.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        const diasList = []
                        for (let i = 1; i < 8; i++) {
                            if (response.dias.filter(value => value.dia === i).length > 0) {
                                diasList.push(response.dias.filter(value => value.dia === i)[0])
                            } else {
                                diasList.push({
                                    asistentes: [],
                                    dia: i,
                                    hora: "",
                                    minutos: "",
                                    programacion: response.id
                                })
                            }
                        }
                        setDias(diasList)
                        setProgramacion(response)
                        setProgramacionList(prev => ([...prev.filter(item => item.id !== response.id), response]))
                    }
                ).catch(
                    error => {
                        console.log("error", error.message)
                    }
                )
            })
        }
        setDisabled(true)
    }

    function send_email() {
        let mensaje = ""
        authHeaders().then(authHeader => {

            const data = {programacion: programacion.id}
            const endpoint = APIPATH + 'api/terapias/programaciones/email/'
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    if (response.ok) {
                        mensaje = "Email enviado a : "
                    } else {
                        mensaje = "No se pudo enviar Email"
                    }

                    return response.json()
                }
            ).then(
                response => {
                    mensaje = mensaje + response.asistentes.join(', ')
                    setModalMsg({
                        titulo: "Envio Programación",
                        mensaje: mensaje,
                        show: true
                    })
                    setModalMsg(previous => {
                        return {...previous, show: false}
                    })
                }
            ).catch(
                error => {
                    console.log("error", error.message)
                }
            )
        })
    }

    function handleDias(e) {
        const {name, value, dataset} = e.target
        /* eslint eqeqeq: 0 */
        if (value == parseInt(value, 10) || value === "") {
            const diasList = []
            for (let i = 0; i < 7; i++) {
                if (i !== parseInt(dataset.dia)) {
                    diasList.push(dias[i])
                } else {
                    diasList.push({...dias[i], [name]: value})
                }
            }
            setDias(diasList)
        }
    }

    function handle_externos(value, object) {
        const nuevoSelectPacientes = [...pacientes, ...externos.filter(externo => value.includes(externo.id))]

        const newList = dias.map(dia => ({
            ...dia, asistentes: dia.asistentes.filter(
                asist => nuevoSelectPacientes.map(pac => pac.id).includes(asist.paciente)
            )
        }))
        setDias(newList)
        setTimeout(function () {
            setSelectPacientes(nuevoSelectPacientes.map(value => ({
                name: value.alias,
                value: value.id
            })))
        }, 200);
        //
        // setSelectExternos(value)
    }

    function handle_pacientes(value, object, dia) {

        setDias(prev => ([...prev.filter(d => d.dia !== dia.dia), {
            ...prev.filter(d => d.dia === dia.dia)[0],
            asistentes: value.map(id => ({paciente: id}))
        }].sort((a, b) => (a.dia - b.dia))))


    }

    function copia_dia(dia) {
        const listDias = []
        for (let i = 1; i < 8; i++) {
            listDias.push({...dia, dia: i})
        }
        setDias(listDias)

    }

    return (
        <div className="ml-5 row mr-5">
            <div className="col-12  border-right">
                <div className="row bg-primary text-white">
                    <div className="col-2">
                        <button type="button" className=" mr-1 mt-0 pt-1 border-0 bg-primary text-white"
                                onClick={() => {
                                    setDisabled(false)
                                    newProgramacion()
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                    </div>
                    <h5 className="pt-1"> Programacion Terapia </h5>

                </div>
                <div className="row mt-4 mb-4">
                    <div className="col-7">
                        <SelectSearch
                            className="select-search "
                            value={programacion.id}
                            options={programacionList.map(prog =>
                                ({name: prog.nombre, value: prog.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder="Seleccione Programacion"
                            onChange={handle_programacion}
                        />
                    </div>
                    <div className="col-4 mx-auto">
                        <div className="row">
                            {disabled ?
                                <button type="button" className='btn col-3 mx-auto btn-primary mb-2 w-100 rounded-pill'
                                        onClick={e => {
                                            e.preventDefault();
                                            setDisabled(false)
                                        }}
                                >Editar
                                </button> :
                                <button type="submit" form="prog-form"
                                        className='btn btn-primary col-3 mx-auto mb-2 w-100 rounded-pill'
                                >Guardar
                                </button>
                            }

                            {disabled && programacion.id !== "" &&
                            <button type="button"
                                    className='btn col-3 mx-auto btn-outline-primary mb-2 w-100 rounded-pill'
                                    onClick={e => {
                                        e.preventDefault();
                                        send_email()
                                    }}
                            >E-mail
                            </button>}

                            {!disabled &&
                            <button type="button"
                                    className='btn col-3 mx-auto btn-outline-primary mb-2 w-100 rounded-pill'
                                    onClick={borrar_programacion}
                            >Borrar
                            </button>}
                            {!disabled &&
                            <button type="button"
                                    className='btn col-3 mx-auto btn-outline-primary mb-2 w-100 rounded-pill'
                                    onClick={e => {
                                        e.preventDefault();
                                        setDisabled(true)
                                    }}
                            >Cancelar
                            </button>}
                        </div>
                    </div>
                </div>
                <hr/>
                <form onSubmit={submitForm} id="prog-form">
                    <div className="row ">
                        <div className="form-group col-8 mt-2 mb-4 ">
                            <label htmlFor="nombre">Nombre</label>
                            <input type='text' id="nombre" required
                                   className={` form-control `} disabled={disabled}
                                   name="nombre" value={programacion.nombre} onChange={handle_input}/>
                        </div>
                        <div className="form-group  col-4 mt-2 mb-4 ">
                            <label className="ml-2">Terapeuta</label>
                            {userList.length > 0 &&
                            <SelectSearch
                                className="select-search ml-2"
                                value={programacion.terapeuta}
                                options={userList.map(user =>
                                    ({name: user.name, value: user.id})
                                )}
                                search
                                disabled={disabled}
                                printOptions="on-focus"
                                placeholder={" ... "}
                                onChange={handle_terapeuta}
                            />
                            }
                        </div>
                    </div>
                    <div className="form-group mt-2 ">
                        <label htmlFor="comentarios">Comentarios</label>
                        < div className={`${!disabled && 'bg-white'} comentarios `}>
                            <Editor id='comentarios'
                                    name='comentarios'
                                    init={{

                                        menubar: false,
                                        branding: false,
                                        plugins: [
                                            'advlist autolink lists link image  print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help  '
                                        ],
                                        language: 'es_ES',
                                        inline: true,
                                        elementpath: false,
                                        toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                                    }}

                                    value={programacion.comentarios} onEditorChange={handle_editor}
                                    disabled={disabled}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="ml-3 mr-4 ">Pacientes Externos </label>
                        <SelectSearch
                            className="select-search"
                            value={selectExternos}
                            options={externos.map(paciente =>
                                ({name: paciente.alias, value: paciente.id})
                            )}
                            search
                            disabled={disabled}
                            multiple
                            printOptions="on-focus"
                            placeholder=""
                            onChange={handle_externos}
                        />
                    </div>
                </form>
                <table className="table">
                    <thead>
                    <tr>
                        {diasSemana.map((diaSemana, index) => (
                            <th key={index}>{diaSemana}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {dias.map((dia, index) => (
                            <td key={index} width="14%" className="border-right border-left">
                                <div className="row d-flex justify-content-center ">
                                    <input type="text" className="form-control mx-1 horas"
                                           value={dia.hora} disabled={disabled}
                                           name="hora" data-dia={index} onChange={handleDias}/>
                                    :
                                    <input type="text" className="form-control mx-1 horas"
                                           value={dia.minutos} disabled={disabled}
                                           name="minutos" data-dia={index} onChange={handleDias}/>
                                </div>
                                <div className="row mb-5 mt-3">

                                    <SelectSearch
                                        key={`${programacion.id}-${dia.dia}`}
                                        className="select-search select-search-multiple"
                                        id={`${dia.dia}`}
                                        value={dia.asistentes.map(asistente => asistente.paciente)}
                                        options={selectPacientes}
                                        search
                                        disabled={disabled}
                                        multiple
                                        onChange={(value, obj) => handle_pacientes(value, obj, dia)}
                                    />
                                </div>
                                <div className="mt-5 mb-5">
                                    <button type="button"
                                            className='btn btn-outline-primary btn-copia-dia mb-2  w-100 rounded-pill'
                                            onClick={e => {
                                                e.preventDefault();
                                                copia_dia(dia)
                                            }}
                                    >copiar
                                    </button>
                                </div>

                            </td>
                        ))}
                    </tr>

                    </tbody>
                </table>


            </div>
            <ModalMsg
                titulo={modalMsg.titulo}
                mensaje={modalMsg.mensaje}
                show={modalMsg.show}
                modalId="msgEmail"
            />
        </div>
    )
}

export default Programacion