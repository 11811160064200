import React, {useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";

// import './App.css';

function CreateUser(props) {
    const [formFields, setFormFields] = useState({
        username: "",
        email: ""
    })
    const [valid, setValid] = useState(false)

    function handleInput(event) {
        const emailfield = document.querySelector("div.modal-body input[name='email']")

        const {name, value} = event.target
        setFormFields(prevState => {
            return {...prevState, [name]: value}
        })
        if (formFields.username !== "" && formFields.email !== "" && !emailfield.validity.typeMismatch) {
            setValid(true)
        } else {setValid(false)}

    }

    function close_modal() {
        const modal = document.getElementById(props.modalId)
        setFormFields({
            username: "",
            email: ""
        })
        const jQueryObj = Object.keys(modal).filter((key) => (key.toString().indexOf('jQuery') !== -1) && modal[key].hasOwnProperty('bs.modal'));
        modal[jQueryObj]['bs.modal'].hide();
    }

    function add_user(event) {

        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/accounts/profile/'
            const data = {user: formFields}
            const lookupOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            props.addUser(response)
                            close_modal()
                        })
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })

    }


    return (
        <div className="modal fade" id={props.modalId} data-backdrop="static" data-keyboard="false" tabIndex="-1"
             aria-labelledby="modalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabel">Nuevo Usuario</h5>
                        <button type="button" className="close" onClick={close_modal} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type="text" className="col-8 form-control mt-4 mx-auto" name="username"
                               placeholder="Usuario" value={formFields.username} onChange={handleInput}/>
                        <input type="email" className="col-8 form-control mt-2 mb-4 mx-auto" name="email"
                               placeholder="Correo Electrónico" value={formFields.email} onChange={handleInput}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={close_modal}>Cerrar</button>
                        <button type="button" className="btn btn-primary" disabled={!valid} onClick={add_user}>Crear</button>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default CreateUser