import React, {useState, useEffect} from 'react'
import moment from "moment"
import SelectSearch from "react-select-search";
import {authHeaders} from "../Utils/authUtils";
import {Container, Row, Col, Button} from 'react-bootstrap'
import {APIPATH} from "../settings";
import "./Blog.css"
import {Editor} from "@tinymce/tinymce-react";
import Foto from "./Foto";

function Blog(props) {
    const nuevoBlog = {
        id: "",
        titulo: "",
        subtitulo: "",
        imagen: "",
        fecha: moment().format('YYYY-MM-DD'),
        texto: "",
        temas: [],
    }
    const [blogs, setBlogs] = useState([])
    const [temas, setTemas] = useState([])
    const [blog, setBlog] = useState(nuevoBlog)
    const [temasBlog, setTemasBlog] = useState([])
    const [nuevoTema, setnuevoTema] = useState("")
    const [foto, setFoto] = useState()

    useEffect(() => {

        loadBlogs()

        let endpoint = APIPATH + 'api/blog/temas/'
        let lookupOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(endpoint, lookupOptions).then(
            response => response.json()
        ).then(
            response => {
                setTemas(response)

            }
        ).catch(
            error => {
                console.log("error", error)
            }
        )


    }, [])

    function loadBlogs() {
        let endpoint = APIPATH + 'api/blog/'
        let lookupOptions = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            },
        }
        fetch(endpoint, lookupOptions).then(
            response => response.json()
        ).then(
            response => {
                setBlogs(response)

            }
        ).catch(
            error => {
                console.log("error", error)
            }
        )
    }

    function selectBlog(e) {
        const {id} = e.currentTarget.dataset
        setBlog(blogs[id])
        setTemasBlog(blogs[id].temas.map(tema => tema.tema))
        setFoto(blogs[id].imagen)

    }

    function handle_input(e) {
        const {name, value} = e.target
        setBlog(prev => ({...prev, [name]: value}))
    }

    function handle_temas(value, object) {
        setTemasBlog(value)
        setBlog(prev => ({...prev, temas: value}))
    }


    function handle_nuevoTema(e) {
        setnuevoTema(e.target.value)
    }

    function add_tema(e) {
        if (nuevoTema !== "") {
            let ok = true
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/blog/temas/create/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify({titulo: nuevoTema})
                }

                fetch(endpoint, lookupOptions).then(
                    response => {
                        ok = response.ok

                        return response.json()

                    }
                ).then(
                    response => {
                        console.log(response)
                        if (!ok) {
                            setTemas(prev => [...prev, response])
                            setnuevoTema("")
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    function delete_blog(e) {
        if (blog.id !== "") {
            authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/blog/detail/' + blog.id + "/"
                    const lookupOptions = {
                        method: "DELETE",
                        headers: {
                            // 'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }

                    fetch(endpoint, lookupOptions).then(
                        response => {
                            loadBlogs()
                            setBlog(nuevoBlog)
                            setTemasBlog([])
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        }
    }


    function save_blog(e) {
        let form_data = new FormData()
        const data = {...blog}
        if (data.imagen === "" || data.imagen === null) {
            delete data.imagen
        }
        if (!(data.imagen instanceof File)) {
                delete data.imagen
            }
        Object.keys(data).forEach(key => {
            if (key !== 'id' && key !== 'temas') {
                form_data.append(key, data[key])
            }
        })

        if (blog.id === "") {
            let ok = true
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/blog/create/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        // 'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: form_data
                }

                fetch(endpoint, lookupOptions).then(
                    response => {
                        ok = response.ok

                        return response.json()

                    }
                ).then(
                    response => {
                        if (ok) {

                            authHeaders().then(authHeader => {
                                const endpoint = APIPATH + 'api/blog/temas/blog/'
                                const lookupOptions = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        ...authHeader
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify({blog: response.id, temas: data.temas})
                                }

                                fetch(endpoint, lookupOptions).then(
                                    response => {
                                        ok = response.ok

                                        return response.json()

                                    }
                                ).then(
                                    response => {

                                        if (ok) {
                                            loadBlogs()
                                            setBlog(nuevoBlog)
                                            setTemasBlog([])
                                            setFoto()
                                        }
                                    }
                                ).catch(
                                    error => {
                                        console.log("error", error)
                                    }
                                )
                            })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            let ok = true
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/blog/detail/' + blog.id + "/"
                const lookupOptions = {
                    method: "PATCH",
                    headers: {
                        // 'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: form_data
                }

                fetch(endpoint, lookupOptions).then(
                    response => {
                        ok = response.ok

                        return response.json()

                    }
                ).then(
                    response => {

                        if (ok) {

                            authHeaders().then(authHeader => {
                                const endpoint = APIPATH + 'api/blog/temas/blog/'
                                const lookupOptions = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        ...authHeader
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify({blog: response.id, temas: data.temas})
                                }

                                fetch(endpoint, lookupOptions).then(
                                    response => {
                                        ok = response.ok

                                        return response.json()

                                    }
                                ).then(
                                    response => {

                                        if (ok) {
                                            loadBlogs()
                                            setBlog(nuevoBlog)
                                            setTemasBlog([])
                                            setFoto()
                                        }
                                    }
                                ).catch(
                                    error => {
                                        console.log("error", error)
                                    }
                                )
                            })
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    function handle_editor(content, editor) {
        setBlog(previous => ({...previous, texto: content}))
    }

    function uploadImage(imageBase64, fotoFile) {
        setFoto(imageBase64)
        setBlog(previous => {
            return {...previous, imagen: fotoFile}
        })
    }

    return (
        <Container fluid>
            <Row className="bg-white  ">
                <h2 className="w-100 text-center">
                    <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                            onClick={(e) => {
                                setBlog(nuevoBlog)
                            }}>
                        <svg width="0.5em" height="0.5em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                             fill="currentColor"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd"
                                  d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path fillRule="evenodd"
                                  d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                        </svg>
                    </button>
                    BLOG

                </h2>
            </Row>
            <Row>
                <Col xs={3} className="list_area border-right">
                    {blogs.map((blog, index) =>
                        <Row className="blogList_item border-bottom py-2" onClick={selectBlog} key={blog.id}
                             data-id={index}>
                            <Col xs={3}>{moment(blog.fecha).format('DD MMM YYYY')}</Col>
                            <Col xs={9}>{blog.titulo}</Col>
                        </Row>
                    )}

                </Col>
                <Col xs={9} className="bg-white">
                    <Row>
                        <Col xs={3}>
                            <button type="button" className="border bg-white w-100 p-0 m-0 "
                                    data-toggle="modal"
                                    data-target="#fotoModal">
                                <img src={foto} className="img-fluid w-100" alt="Foto ID"/>
                            </button>
                        </Col>
                        <Col xs={9}>
                            <div className="form-group">
                                <label htmlFor="titulo">Titulo</label>
                                <input type='text' id="titulo" required
                                       className={`w-100 form-control `}
                                       name="titulo" value={blog.titulo} onChange={handle_input}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="subtitulo">SubTitulo</label>
                                <input type='text' id="subtitulo" required
                                       className={`w-100 form-control `}
                                       name="subtitulo" value={blog.subtitulo} onChange={handle_input}/>
                            </div>
                            <Row>
                                <div className="form-group col-2">
                                    <label htmlFor="fecha">Fecha</label>
                                    <input type='date' id="fecha" required
                                           className={`w-100 form-control `}
                                           name="fecha" value={blog.fecha} onChange={handle_input}/>
                                </div>
                                <div className="col-4 ">
                                    Temas
                                    {temas.length > 0 &&
                                    <SelectSearch
                                        className="select-search mt-2"
                                        value={temasBlog}
                                        options={temas.map(tema =>
                                            ({name: tema.titulo, value: tema.id})
                                        )}
                                        multiple
                                        search
                                        printOptions="on-focus"
                                        placeholder="Seleccione tema"
                                        onChange={handle_temas}
                                    />
                                    }
                                </div>
                                <div className="form-group col-4">
                                    <label htmlFor="fecha">Nuevo Tema</label>
                                    <input type='text' id="nuevoTema" required
                                           className={`w-100 form-control `}
                                           name="nuevoTema" value={nuevoTema} onChange={handle_nuevoTema}
                                           onBlur={add_tema}
                                    />
                                </div>
                            </Row>
                        </Col>
                    </Row>

                    <div className={`bg-white mt-2`}>
                        <Editor id='texto'
                                name='texto'
                                init={{
                                    placeholder: "Texto",
                                    menubar: true,
                                    branding: false,
                                    plugins: [
                                        'advlist autolink lists link image  print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help  '
                                    ],
                                    language: 'es_ES',
                                    inline: false,
                                    elementpath: false,
                                    height: 500,
                                    toolbar: `undo redo |underline bold italic forecolor backcolor | 
                            alignleft aligncenter alignright alignjustify 
                            | bullist numlist outdent indent | removeformat| fullscreen `
                                }}
                                value={blog.texto}
                                onEditorChange={handle_editor}
                            // onBlur={handle_blur}

                        />
                    </div>
                    <Button variant="primary" onClick={save_blog}
                            disabled={blog.titulo === "" || blog.subtitulo === "" || blog.texto === ""}>Guardar</Button>
                    <Button variant="warning" className="ml-4" onClick={delete_blog}
                            disabled={blog.id === ""}>Borrar</Button>
                </Col>
            </Row>
            <Foto
                modalId="fotoModal"
                uploadImage={uploadImage}
            />
        </Container>

    )


}

export default Blog