import React, {useEffect, useState} from 'react'
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import moment from "moment";

import SelectSearch from "react-select-search";

import "./Notas.css"
import AltaPauta from "./AltaPauta";


function Pautas(props) {
    const [pautas, setPautas] = useState([])
    const [users, setUsers] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [user, setUser] = useState("")
    const [paciente, setPaciente] = useState("")
    const [search, setSearch] = useState("")
    const [showAltaPauta, setShowAltaPauta] = useState(false)
    const [is_max, setIs_max] = useState(false)
    const [pacienteAlta, setPacienteAlta] = useState("")


    useEffect(() => {
            if (props.is_max !== undefined) {
                setIs_max(props.is_max)
            }
            if (props.paciente !== undefined) {
                setPaciente(props.paciente)
            }
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/accounts/profile/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUsers(response)
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/pacientes/?activo=True&centro=' + props.centro
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setPacientes(response)

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
            query_pautas()
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function query_pautas() {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/notas/pauta/?centro=' + props.centro + '&activa=True'

            if (search !== "") {
                endpoint = endpoint + '&search=' + search
            }
            if (props.paciente !== undefined) {
                endpoint = endpoint + "&paciente=" + props.paciente
            } else if (paciente !== "") {
                endpoint = endpoint + "&paciente=" + paciente
            }

            if (user !== "") {
                endpoint = endpoint + "&user=" + user
            }
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                // body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    if (response.ok) {
                        response.json()
                            .then(
                                response => {
                                    setPautas(response)
                                }
                            )
                    } else {
                        setPautas([])
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }


    function handle_paciente(value, object) {
        setPaciente(value)
    }

    function handle_user(value, object) {
        setUser(value)
    }

    function alta_pauta(pauta) {
        query_pautas()
    }

    function handle_input(e) {
        const {value} = e.target;
        setSearch(value)
    }

    function restablece_pautas(e) {
        setSearch("")
        setPaciente("")
        setUser("")

    }

    return (
        <div>
            <div className="row mt-4">
                <div className="col-12 bg-white m-0">
                    <div className="row">

                        <button type="button" className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                onClick={() => {
                                    setShowAltaPauta(true)
                                }}>
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                 fill="currentColor"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path fillRule="evenodd"
                                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                        </button>
                        <h3 className="col-4 text-primary">Pautas</h3>
                        <div className="col-1 ml-auto">
                            <button type="button" className=" mr-1 mt-0 pt-1 btn-link border-0 bg-white text-success"
                                    onClick={() => {
                                        props.maximizar("pautas", !is_max)
                                        setIs_max(prev => !prev)
                                    }}>
                                {is_max ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                    </svg> :
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                        <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                        <path
                                            d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
                {is_max &&
                <div className="col-3 pb-4 border-right border-secondaryvpx-0">
                    <div className=" mb-3 subtitulo">
                        <h5 className="py-2 px-3"> Búsqueda</h5>
                    </div>
                    {props.paciente === undefined &&
                    <div className="row">
                        <label className=" ml-5 mr-1">Paciente</label>
                        {pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={paciente}
                            options={[{name: "...", value: ""}, ...pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }
                    </div>
                    }

                    <div className="row">
                        <label className=" ml-5 mr-2">Usuario</label>
                        {users.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={user}
                            options={[{name: "...", value: ""}, ...users.map((user, index) =>
                                ({name: user.user.username, value: user.user.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_user}
                        />
                        }
                    </div>


                    <textarea rows="3" className=" ml-3 mb-5 mt-2 mr-3 w-75 form-control" name="search" value={search}
                              placeholder={"Texto a buscar"} onChange={handle_input}/>

                    <button type="button" className="btn btn-primary ml-5 mr-5" onClick={query_pautas}>Buscar
                    </button>
                    <button type="button" className="btn btn-secondary ml-5"
                            onClick={restablece_pautas}>Restablecer
                    </button>

                </div>}
                <div className={`${is_max ? 'col-9 lista-notas-max' : 'col-12 lista-notas'} bg-white`}>
                    {pautas.map((pauta, index) =>
                        <div className="row border "
                             key={index}
                        >
                            <div className="col-12 mx-1 nota-header ">
                                <div className="row">
                                <div className="col-2">
                                    <div className={`${is_max ? 'col-4' : props.foto_width} px-0 mx-0`}>
                                        {pauta.img &&
                                        <img className="img-fluid" src={`${pauta.img}`} alt=""/>
                                        }
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="row">
                                        <span className=" col-3 text-medium">{pauta.nombrePaciente}</span>
                                        <span className="col-2 ml-2 mr-auto mt-1">({pauta.nombreUser})</span>
                                        <span className="col-4">{moment(pauta.fecha).format('ddd D MMM YYYY, h:mm:ss a')}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="ml-5 col-11" dangerouslySetInnerHTML={{__html: pauta.texto}}/>


                        </div>
                    )}

                </div>
            </div>
            <AltaPauta
                show={showAltaPauta}
                handleClose={() => {
                    setPacienteAlta("")
                    setShowAltaPauta(false)
                }}
                pacientes={pacientes}
                alta={alta_pauta}
                paciente={pacienteAlta}
            />
        </div>
    )
}

export default Pautas