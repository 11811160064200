import React, {useRef, useState, useEffect} from 'react'
import moment from "moment"
import SelectSearch from "react-select-search";
import "./Contabilidad.css"
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import FicheroInline from "../Utils/FicheroInline";
import MovimientoIva from "./MovimientoIva";

function MovimientoUpdate(props) {
    const firstUpdate = useRef(true)
    const nuevoMov = {
        id: "",
        fecha: moment(new Date()).format('YYYY-MM-DD'),
        deposito: "",
        concepto: "",
        foto: "",
        grupoMovimiento: "",
        centro: props.centro.id,
        paciente: 0,
        factura: null,
        signo: "H",
        etiqueta: "",
        suplido: true,
        traspaso: false,
        registro: false,
        factura_suplido: "",
        importeTotal: 0,
        importeNeto: 0,
        tiposIva: [],
        factura_proveedor: "",
        oculto: false,
    }
    const [movimiento, setMovimiento] = useState(nuevoMov)
    const [checkImporte, setcheckImporte] = useState(0)
    const [showMovimientoIva, setShowMovimientoIva] = useState(false)
    const [valid, setValid] = useState(false)
    const [mensaje, setMensaje] = useState()
    let userAccess = sessionStorage.getItem('userToken') === null ? {user: {id: 0}} : JSON.parse(sessionStorage.getItem('userToken'));


    useEffect(() => {
            if (props.movimiento && props.movimiento.id === undefined) {
                if (props.depositos[0] !== undefined) {
                    setMovimiento({...nuevoMov, deposito: props.depositos[0].id})
                } else {
                    setMovimiento(nuevoMov)
                }
                setcheckImporte(0)
                setValid(false)
            } else {
                const mov = {...props.movimiento}

                if (mov["etiqueta"] === null) {
                    mov["etiqueta"] = ""
                }
                setcheckImporte(mov.importeTotal)
                setMovimiento(mov)
                setValid(true)
            }
            if (firstUpdate.current) {
                firstUpdate.current = false
            }

// eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props]
    )

    function handle_input(e) {
        let {name, value, checked} = e.target
        if (['registro', 'suplido', 'oculto'].includes(name)) {
            value = checked
        }

        setValid(validar({...movimiento, [name]: value}, checkImporte))
        setMovimiento(prev => ({...prev, [name]: value}))

    }

    function validar(mov, importe) {
        let valid = true
        setMensaje()
        if (!(!isNaN(parseFloat(mov.importeTotal)) && isFinite(mov.importeTotal)) && mov.importeTotal !== "") {
            valid = false
            setMensaje("Importe no válido")
        }
        if ((!isNaN(parseFloat(mov.importeTotal)) && isFinite(mov.importeTotal)) && parseFloat(mov.importeTotal) === 0) {
            valid = false
            setMensaje("Importe no puede ser 0")
        }
        if (mov.registro && !mov.suplido && mov.importeTotal !== importe) {
            valid = false
            setMensaje("Desglosar Iva ")
        }
        if (mov.concepto === "") {
            valid = false
            setMensaje("Introducir Concepto")
        }
        if (mov.suplido && mov.paciente === 0) {
            valid = false
            setMensaje("Introducir paciente para suplido")
        }
        if (mov.deposito === "") {
            valid = false
            setMensaje("Seleccionar depósito")
        }
        return valid
    }

    function handle_deposito(value, object) {
        setValid(validar({...movimiento, deposito: value}, checkImporte))
        setMovimiento(prev => ({...prev, deposito: value}))

    }

    function handle_paciente(value, object) {
        setValid(validar({...movimiento, paciente: value}, checkImporte))
        setMovimiento(prev => ({...prev, paciente: value}))
    }

    function update_movimiento(e) {
        const mov = {...movimiento}
        if (mov.paciente === 0) {
            mov.paciente = ""
        }
        if (mov.grupoMovimiento === 0) {
            mov.grupoMovimiento = ""
        }
        if (!(mov.foto instanceof File)) {
            delete mov.foto
        }
        if (mov.factura === null) {
            mov.factura = ""
        }
        if (mov.importeNeto===0 || !(!movimiento.suplido && movimiento.registro)) {
            mov.importeNeto = mov.importeTotal
        }

        if (mov.id === "") {
            delete mov.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/movimientos/'
                let form_data = new FormData()
                Object.keys(mov).forEach(key => {
                    if (key !== 'tiposIva')
                        form_data.append(key, mov[key])
                })

                const lookupOptions = {
                    method: "POST",
                    headers: {
                        // 'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: form_data
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        if (movimiento.tiposIva.length > 0) {
                            authHeaders().then(authHeader => {
                                const endpoint = APIPATH + 'api/contabilidad/movimientos/iva/'
                                const data = {
                                    movimiento: response.id,
                                    tiposIva: movimiento.tiposIva,
                                }
                                const lookupOptions = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        ...authHeader
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify(data)
                                }
                                fetch(endpoint, lookupOptions).then(
                                    response => response.json()
                                ).then(
                                    response => {
                                        setMovimiento(nuevoMov)
                                        props.update()
                                    }
                                ).catch(
                                    error => {
                                        console.log("error", error)
                                    }
                                )
                            })
                        } else {
                            props.update()
                            setMovimiento(nuevoMov)
                        }

                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })


        } else {
            delete mov.id
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/movimientos/detail/' + movimiento.id + '/'
                let form_data = new FormData()
                Object.keys(mov).forEach(key => {
                    if (mov[key] !== null) {
                        form_data.append(key, mov[key])
                    }
                })
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        // 'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: form_data
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        if (movimiento.tiposIva.length > 0) {
                            authHeaders().then(authHeader => {
                                const endpoint = APIPATH + 'api/contabilidad/movimientos/iva/'
                                const data = {
                                    movimiento: response.id,
                                    tiposIva: movimiento.tiposIva,
                                }
                                const lookupOptions = {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        ...authHeader
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify(data)
                                }
                                fetch(endpoint, lookupOptions).then(
                                    response => response.json()
                                ).then(
                                    response => {
                                        // setMovimiento(nuevoMov)
                                        props.update()
                                    }
                                ).catch(
                                    error => {
                                        console.log("error", error)
                                    }
                                )
                            })
                        } else {
                            props.update()
                            // setMovimiento(nuevoMov)
                        }
                    }
                ).catch(
                    error => {
                        console.log("error", error)

                    }
                )
            })
        }

    }

    function uploadFile(imageBase64, movFile) {
        setMovimiento(prev => ({...prev, foto: movFile}))
    }

    function update_iva(tablaIva, prov, fra) {

        setMovimiento(prev => ({
            ...prev,
            factura_proveedor: fra,
            proveedor: prov,
            importeNeto: parseFloat(tablaIva.map(iva => iva.base).reduce((a, b) => a + b, 0)).toFixed(2),
            tiposIva: tablaIva.map(iva => ({base: iva.base, tipo: iva.tipo}))
        }))
        setcheckImporte(movimiento.importeTotal)
        setShowMovimientoIva(false)
        setValid(validar(movimiento, movimiento.importeTotal))
    }

    return (
        <div className="row">
            <div className="mt-3 col-7 border-right">
                <div className="row ml-1">
                    <label htmlFor="fecha">Fecha</label>
                    <input type='date' id="fecha" required
                           className="col-3 ml-2 form-control "
                           name="fecha"
                           value={movimiento.fecha}
                           onChange={handle_input}/>

                    <div className="col-6">
                        <div className="row">
                            <label className="ml-2 col-3 select-1">Deposito</label>
                            <SelectSearch
                                className="select-search select-1 col-8"
                                value={movimiento.deposito}
                                options={props.depositos.map((deposito, index) =>
                                    ({name: deposito.nombre, value: deposito.id})
                                )}
                                search
                                printOptions="on-focus"
                                placeholder={" ... "}
                                onChange={handle_deposito}
                            />

                        </div>
                    </div>

                    <label className="check-container">
                        <input type="checkbox" checked={movimiento.suplido} name="suplido" onChange={handle_input}/>
                        <span className="checkmark"></span>
                    </label>
                    Suplido
                </div>
                <div className="form-row mt-3">
                    <label htmlFor="concepto">Concepto</label>
                    <input type='text' id="concepto" required
                           className="col-7 ml-2 mr-4 form-control "
                           name="concepto"
                           value={movimiento.concepto}
                           onChange={handle_input}/>
                    {userAccess.is_contabilidad &&
                    <label className="">Grupo</label>
                    }
                    {userAccess.is_contabilidad &&
                    <SelectSearch
                        className="select-search select-3 col-3"
                        value={movimiento.grupoMovimiento}
                        options={[{name: "...", value: 0}, ...props.grupos.map((grupo, index) =>
                            ({name: grupo.nombre, value: grupo.id})
                        )]}
                        search
                        printOptions="on-focus"
                        placeholder={" ... "}
                        onChange={(value, obj) => {
                            setMovimiento(prev => ({...prev, grupoMovimiento: value}))
                        }}
                    />
                    }

                </div>

                <div className="form-row mt-3">
                    <div className="col-4">
                        <div className="row">
                            <label className="col-2">Paciente</label>
                            <SelectSearch
                                className="select-search select-1 ml-3 col-7"
                                value={movimiento.paciente}
                                options={[{name: "...", value: 0}, ...props.pacientes.map((paciente, index) =>
                                    ({name: paciente.alias, value: paciente.id})
                                )]}
                                search
                                printOptions="on-focus"
                                placeholder={" ... "}
                                onChange={handle_paciente}
                            />

                        </div>
                    </div>
                    <div className="col-2">
                        <div className="row">
                            <label htmlFor="importe">Importe</label>
                            <input type='text' id="importe" required
                                   className="col-7 ml-2 form-control "
                                   name="importeTotal"
                                   value={movimiento.importeTotal}
                                   onChange={handle_input}/>
                        </div>
                    </div>
                    {userAccess.is_contabilidad && movimiento.suplido &&
                    <div className="col-2   ">
                        <select name="signo" value={movimiento.signo} className="form-control " onChange={handle_input}>
                            <option value="D">Debe</option>
                            <option value="H">Haber</option>
                        </select>
                    </div>
                    }
                    <div className="col-3  ml-4 border">
                        {movimiento.proveedor_nombre}
                    </div>
                </div>
                <div className="form-row mt-3">
                    <div className="col-3">
                        <div className="row">
                            <label className=" col-2 ">Etiqueta</label>
                            <SelectSearch
                                className="select-search select-2 ml-3 col-9"
                                value={movimiento.etiqueta}
                                options={[{name: "...", value: " "}, ...props.etiquetas.map((etiqueta, index) =>
                                    ({name: etiqueta, value: etiqueta})
                                )]}
                                search
                                printOptions="on-focus"
                                placeholder={" ... "}
                                onChange={(value, obj) => {
                                    setMovimiento(prev => ({...prev, etiqueta: value}))
                                }}
                            />
                        </div>
                    </div>
                    <input type='text' id="etiqueta" required
                           className="col-3 form-control "
                           name="etiqueta"
                           value={movimiento.etiqueta}
                           onChange={handle_input}/>
                    <label className="check-container mt-2 ml-3">
                        <input type="checkbox" checked={movimiento.registro} name="registro" onChange={handle_input}/>
                        <span className="checkmark"></span>

                    </label>
                    <span className="mt-2"> Libro </span>
                    {!movimiento.suplido && movimiento.registro &&
                    <button type="button" className="btn btn-success ml-3 px-5"
                            onClick={() => setShowMovimientoIva(true)}>
                        IVA
                    </button>
                    }
                    {userAccess.is_contabilidad_all &&
                    <label className="check-container mt-2 ml-3">
                        <input type="checkbox" checked={movimiento.oculto} name="oculto" onChange={handle_input}/>
                        <span className="checkmark"></span>

                    </label>
                    }
                    {userAccess.is_contabilidad_all &&
                    <span className="mt-2"> Oculto </span>
                    }
                </div>

                <div className="row mt-3">
                    <button type="button" className="btn col-2 ml-3 btn-primary " onClick={update_movimiento} disabled={
                        (movimiento.factura !== null || movimiento.traspaso || !valid)
                    }
                    >Actualizar
                    </button>
                    <button type="button" className="btn col-2 ml-3 btn-outline-primary " onClick={() => {
                        setMensaje()
                        setMovimiento(nuevoMov)
                    }}>
                        Cancelar
                    </button>
                    <div className="col-4">
                        {mensaje &&
                        <span className="ml-4 text-danger">{mensaje}</span>
                        }
                    </div>
                    <div className="col-3 ml-3 bg-light text-center">
                        {movimiento.user_nombre}
                    </div>
                </div>
                <div className="row mt-3">
                 {userAccess.is_contabilidad &&
                    <label className="">Centro</label>
                    }
                    {userAccess.is_contabilidad &&
                    <SelectSearch
                        className="select-search select-3 col-3"
                        value={movimiento.centro}
                        options={[...props.centros.map((centro, index) =>
                            ({name: centro.nombre, value: centro.id})
                        )]}
                        search
                        printOptions="on-focus"
                        placeholder={" ... "}
                        onChange={(value, obj) => {
                            setMovimiento(prev => ({...prev, centro: value}))
                        }}
                    />
                    }
                </div>
            </div>
            <div className="mt-3 col-5">
                {((movimiento.foto instanceof File) || movimiento.foto === "" || movimiento.foto === null || movimiento.foto === undefined) ?
                    <FicheroInline
                        modalId="ficheroIdModal"
                        uploadFile={uploadFile}
                    /> :
                    <button className="btn-link border-0 col-11 w-100"
                            onClick={(e) => setMovimiento(prev => ({...prev, foto: ""}))}>
                        <img src={`${movimiento.foto}`}
                             className="img-fluid w-100" alt=" "/>
                    </button>
                }
            </div>
            <MovimientoIva
                handleClose={() => setShowMovimientoIva(false)}
                tiposIva={movimiento.tiposIva}
                proveedor={movimiento.proveedor}
                factura={movimiento.factura_proveedor ? movimiento.factura_proveedor : ""}
                total={movimiento.importeTotal}
                show={showMovimientoIva}
                update={update_iva}
            />
        </div>
    )
}

export default MovimientoUpdate