import React, {useEffect, useState} from 'react';
import {authHeaders} from "../../Utils/authUtils";
import {APIPATH} from "../../settings";


import "./Anamnesis.css"

function Laboral(props) {

    const [anamnesis, setAnamnesis] = useState({
        paciente: null,
        tiene_secundarios: true,
        tiene_superiores: false,
        estudios_primarios_secundarios: "",
        estudios_superiores: "",
        problemas_legales: "",
        profesion: "",
        relacciones_trabajo: "",
        ambiente_laboral: "",
        cambios_profesion: "",
        sexualidad: true,
        inicio_conducta_sexual: "",
        orientacion_sexual: "",
        situacion_socio_economica: "",
        habitos_intereses: "",
        alter_personalidad: [],
        alter_psico_fisio: [],
        estilo_relacional: "",
        capacidad_relacional: "",
        conflictividad_relacional: ""

    })
    const check_fields = ["tiene_secundarios", "tiene_superiores", "sexualidad", "pareja"]
    // const [formErrors, setFormErrors] = useState({})
    const [orientacion, setOrientacion] = useState({})
    const [estiloRel, setEstiloRel] = useState([])
    const [capacidadRel, setCapacidadRel] = useState([])
    const [conflictividadRel, setConflictividadRel] = useState([])
    const [alterPersonalidad, setAlterPersonalidad] = useState([])
    const [alterPsicoFisio, setAlterPsicoFisio] = useState([])

    useEffect(() => {

        if (Object.keys(props.anamnesis).length !== 0) {
            setAnamnesis(props.anamnesis)
        }
        //
        // Carga orientacion sexual
        //
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/pacientes/orientacion/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setOrientacion(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga Estilo Relacional
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/relacional/estilo/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setEstiloRel(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga Capacidad Relacional
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/relacional/capacidad/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setCapacidadRel(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga conflictividad Relacional
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/relacional/conflictividad/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setConflictividadRel(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga alteraciones Personalidad
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/alteraciones/personalidad/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setAlterPersonalidad(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
        //
        // Carga alteraciones PsicoFisiológicas
        //
        authHeaders().then(authHeader => {
            const endpoint = `${APIPATH}api/pacientes/alteraciones/psicofisiologicas/`
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => response.json()
            ).then(response => {
                setAlterPsicoFisio(response)
            }).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }, [props])


    function handle_input(event) {

        let {name, value, checked} = event.target
        if (check_fields.includes(name)) {
            value = checked
        }
        setAnamnesis(previous => {
            return {...previous, [name]: value}
        })
    }

    function submit_input(event) {
        let {name, value, checked} = event.target
        if (check_fields.includes(name)) {
            value = checked
        }
        props.update({paciente: anamnesis.paciente, [name]: value})
    }

    function handle_alter(event) {
        let {name, value, checked} = event.target
        if (checked) {
            props.update({
                paciente: anamnesis.paciente, [name]: [...anamnesis[name], {alteracion: value}]
            })
        } else {
            props.update({
                paciente: anamnesis.paciente,
                [name]: [...anamnesis[name].filter(alteracion => alteracion.alteracion !== value)]
            })
        }
    }

    function handle_blur(event) {
        let {name, value} = event.target
        props.update({paciente: anamnesis.paciente, [name]: value})
    }

    return (
        <form className="text-small">

            <div className="row form-group mt-3">
                <div className="col-3">
                    <label htmlFor="secundarios">Estudios Secundarios</label>
                    <input className="form-check-input ml-2" type="checkbox" name="tiene_secundarios"
                           checked={anamnesis.tiene_secundarios}
                           onChange={submit_input}/>
                    {/*<input type='text' id="secundarios"*/}
                    {/*       className={` w-100 form-control text-small`}*/}
                    {/*       name="estudios_primarios_secundarios" value={anamnesis.estudios_primarios_secundarios}*/}
                    {/*       onChange={handle_input} onBlur={handle_blur}/>*/}
                </div>

                <div className="col-3">
                    <label htmlFor="superiores">Estudios Superiores</label>
                    <input className="form-check-input ml-2" type="checkbox" name="tiene_superiores"
                           checked={anamnesis.tiene_superiores}
                           onChange={submit_input}/>
                    {/*<input type='text' id="superiores"*/}
                    {/*       className={` w-100 form-control text-small`}*/}
                    {/*       name="estudios_superiores" value={anamnesis.estudios_superiores}*/}
                    {/*       onChange={handle_input} onBlur={handle_blur}*/}
                    {/*/>*/}
                </div>
                <div className="col-4 ml-5">
                    <div className="row ml-auto">

                        <input className="form-check-input " type="checkbox" name="sexualidad"
                               checked={anamnesis.sexualidad}
                               onChange={submit_input}/>
                        <label htmlFor="sexualidad">Sexualidad</label>
                        <select className="form-control text-small bg-white ml-3 col-3" id="orientacion_sexual"
                                name="orientacion_sexual"
                                value={anamnesis.orientacion_sexual} onChange={handle_input} onBlur={handle_blur}>
                            <option value=""></option>
                            {Object.keys(orientacion).map(key => {
                                return (<option key={key} value={key}>{orientacion[key]}</option>)
                            })}
                        </select>
                    </div>
                </div>
                {/*<div className="form-group col-2">*/}
                {/*    <label htmlFor="inicio_conducta_sexual">Inicio Conducta Sexual</label>*/}
                {/*    <input type='date' id={"inicio_conducta_sexual"} required*/}
                {/*           className={` w-100 form-control text-small`}*/}
                {/*           name="inicio_conducta_sexual"*/}
                {/*           value={anamnesis.inicio_conducta_sexual}*/}
                {/*           onChange={submit_input}/>*/}
                {/*</div>*/}
            </div>
            {/*<div className="row">*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="problemas_legales">Problemas Legales</label>*/}
            {/*        <textarea id="problemas_legales"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="problemas_legales" value={anamnesis.problemas_legales} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="situacion_socio_economica">Situación Socio-Económica</label>*/}
            {/*        <textarea id="situacion_socio_economica"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="situacion_socio_economica" value={anamnesis.situacion_socio_economica}*/}
            {/*                  onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4 ">*/}
            {/*        <label htmlFor="habitos_intereses">Hábitos / Intereses</label>*/}
            {/*        <textarea id="habitos_intereses"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="habitos_intereses" value={anamnesis.habitos_intereses} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row ml-2 mt-4 ">
                <div className="col-9 border py-3">
                    Eje Socio-Relacional
                    <select className="form-control  bg-white mt-2 " id="estilo_relacional"
                            name="estilo_relacional"
                            value={anamnesis.estilo_relacional} onChange={submit_input}>
                        <option value="">Estilo Relacional</option>
                        <optgroup label="_______________________________"/>
                        {Object.keys(estiloRel).map(key => {
                            return (<option key={key} value={key}>{estiloRel[key]}</option>)
                        })}
                    </select>
                    <select className="form-control  mt-2 bg-white  " id="capacidad_relacional"
                            name="capacidad_relacional"
                            value={anamnesis.capacidad_relacional} onChange={submit_input}>
                        <option value="">Capacidad Relacional</option>
                        <optgroup label="_______________________________"/>
                        {Object.keys(capacidadRel).map(key => {
                            return (<option key={key} value={key}>{capacidadRel[key]}</option>)
                        })}
                    </select>
                    <select className="form-control  mt-2 bg-white " id="conflictividad_relacional"
                            name="conflictividad_relacional"
                            value={anamnesis.conflictividad_relacional} onChange={submit_input}>
                        <option value="">Conflictividad Relacional</option>
                        <optgroup label="_______________________________"/>
                        {Object.keys(conflictividadRel).map(key => {
                            return (<option key={key} value={key}>{conflictividadRel[key]}</option>)
                        })}
                    </select>
                </div>
            </div>
            <div className="row mt-5 ml-2">
                <div className="col-3  border ">
                    Alteraciones Personalidad
                    {Object.keys(alterPersonalidad).map(key => (
                        <div className="form-check mt-2" key={key}>
                            <input className="form-check-input" type="checkbox" name="alter_personalidad" value={key}
                                   id={key}
                                   checked={anamnesis.alter_personalidad.map(alter => alter.alteracion).includes(key)}
                                   onChange={handle_alter}/>
                            <label className="form-check-label " htmlFor={key}>
                                {alterPersonalidad[key]}
                            </label>
                        </div>
                    ))}
                </div>
                <div className="col-3 ml-5 border ">
                    Alteraciones Psico-Fisiológicas
                    {Object.keys(alterPsicoFisio).map(key => (
                        <div className="form-check mt-2" key={key}>
                            <input className="form-check-input" type="checkbox" name="alter_psico_fisio" value={key}
                                   id={key}
                                   checked={anamnesis.alter_psico_fisio.map(alter => alter.alteracion).includes(key)}
                                   onChange={handle_alter}/>
                            <label className="form-check-label " htmlFor={key}>
                                {alterPsicoFisio[key]}
                            </label>
                        </div>
                    ))}
                </div>
            </div>

            <hr/>
            {/*<div className={"row"}>*/}
            {/*    <div className="form-group col-4">*/}
            {/*        <label htmlFor="profesion">Profesión</label>*/}
            {/*        <textarea id="profesion"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="profesion" value={anamnesis.profesion} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4 ">*/}
            {/*        <label htmlFor="ambiente_laboral">Ambiente laboral</label>*/}
            {/*        <textarea id="ambiente_laboral"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="ambiente_laboral" value={anamnesis.ambiente_laboral} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*    <div className="form-group col-4 ">*/}
            {/*        <label htmlFor="cambios_profesion">Cambios profesión</label>*/}
            {/*        <textarea id="cambios_profesion"*/}
            {/*                  className={`w-100 form-control text-small`} rows={2}*/}
            {/*                  name="cambios_profesion" value={anamnesis.cambios_profesion} onChange={handle_input}*/}
            {/*                  onBlur={handle_blur}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </form>


    )

}

export default Laboral

