import React, {useEffect, useState} from 'react';
import {APIPATH} from '../settings';
import 'whatwg-fetch';
import {authHeaders} from "../Utils/authUtils";
import DepositosInline from './DepositosInline'

// import './App.css';


function Depositos(props) {
    const [depositos, setDepositos] = useState([])

    function loadDepositos() {

        if (props.centro !== undefined && props.centro.id !== undefined) {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/contabilidad/depositos/'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {

                        setDepositos(response.map(deposito => {
                            Object.keys(deposito).forEach(key =>{
                                if (deposito[key] === null) {
                                    deposito[key] = ""
                                }
                            })
                            return deposito
                        }))
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        }
    }

    useEffect(() => {
            loadDepositos();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props]
    );

    return (
        <div>

            <div className="row bg-white w-100 m-0">
                <div className="col-1">

                </div>
                <h4 className="col-11 text-primary">Depositos</h4>
            </div>
            <table className="bg-white table">
                <thead>

                <tr className={"text-primary"}>
                    <th className={"text-center"}>
                        Nombre
                    </th>
                    <th className={"text-center"}>
                        Cuenta Banco
                    </th>
                    <th className={"text-center"}>
                        Centro(Blanco todos)
                    </th>
                    <th className={"text-center"}>
                        Acceso
                    </th>
                    <th className={"text-center"}>Registro</th>

                </tr>
                </thead>
                <tbody>
                {depositos != null && depositos.length > 0 && depositos.map(deposito =>
                    <DepositosInline
                        key={deposito.id}
                        ultimo={false}
                        deposito={deposito}
                        user={props.user}
                    />
                )}
                {depositos != null &&
                <DepositosInline
                    key={"altaDeposito"}
                    ultimo={true}
                    alta={loadDepositos}
                    deposito={{id: "", nombre: "", cc: "", centro: "", registro: true, usuarios: []}}
                    user={props.user}
                />}
                </tbody>
            </table>

        </div>
    );
}

export default Depositos;
