import React, {useEffect, useState} from 'react';
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import moment from "moment";
import {addDays} from "date-fns";
import SelectSearch from "react-select-search";
import DateRange from "react-date-range/dist/components/DateRange";
import {es} from "react-date-range/dist/locale";
import "./Terapias.css"


function TerapiasView(props) {

    const [terapias, setTerapias] = useState([])
    const [etiquetas, setEtiquetas] = useState([])
    const [pacientes, setPacientes] = useState([])
    const [tipoTerapias, setTipoTerapias] = useState([])
    const [tipoTerapia, setTipoTerapia] = useState("")
    const [terapeutas, setTerapeutas] = useState([])
    const [terapeuta, setTerapeuta] = useState("")
    const [paciente, setPaciente] = useState("")
    const [etiqueta, setEtiqueta] = useState("")
    const [etiquetaId, setEtiquetaId] = useState("")
    const [search, setSearch] = useState("")
    const [is_max, setIs_max] = useState(false)
    const [dateSelection, setDateSelection] = useState([
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    useEffect(() => {
            if (props.maximizar === undefined) {
                setIs_max(true)
            }
            if (props.centro !== undefined) {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/terapias/tags/'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setEtiquetas(response.tags)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/terapias/programaciones/?centro=' + props.centro
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setTipoTerapias(response.map(prog => ({nombre: prog.nombre, id: prog.id})))
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/accounts/profile/?is_terapeuta=True'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setTerapeutas(response)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/?activo=True&centro=' + props.centro
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientes(response)

                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                query_terapias()
            }
        }
        ,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props]
    )

    function query_terapias() {
        authHeaders().then(authHeader => {
            let endpoint = APIPATH + 'api/terapias/query/list/'
            const data = {
                centro: props.centro,
                startDate: moment(dateSelection[0].startDate).format('YYYY-MM-DD 00:00'),
                endDate: moment(dateSelection[0].endDate).format('YYYY-MM-DD 23:59'),
            }
            if (etiqueta !== "") {
                data.tag = etiqueta
            }
            if (search !== "") {
                data.search = search
            }
            if (paciente !== "") {
                data.paciente = paciente
            }
            if (tipoTerapia !== "") {
                data.programacion = tipoTerapia
            }
            if (terapeuta !== "") {
                data.terapeuta = terapeuta
            }
            const lookupOptions = {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
                body: JSON.stringify(data)
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    if (response.ok) {
                        response.json()
                            .then(
                                response => {
                                    setTerapias(response.terapias)
                                }
                            )
                    } else {
                        setTerapias([])
                    }
                }
            ).catch(
                error => {
                    console.log("error", error)
                }
            )
        })
    }

    function handle_etiqueta(value, object) {
        setEtiquetaId(value)
        setEtiqueta(object.name)
    }

    function handle_paciente(value, object) {
        setPaciente(value)
    }

    function handle_terapeuta(value, object) {
        setTerapeuta(value)
    }

    function handle_tipoTerapia(value, object) {
        setTipoTerapia(value)
    }

    function handle_input(e) {
        const {value} = e.target;
        setSearch(value)
    }

    function restablece_terapias(e) {
        setSearch("")
        setDateSelection([{
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }])
        setEtiquetaId(null)
        setPaciente("")
        setTerapeuta("")
        setTipoTerapia("")
    }

    return (
        <div>
            <div className="row ">
                <div className="col-12 mb-4 bg-white m-0">
                    <div className="row">
                        <h3 className="col-4 text-primary">Terapias</h3>
                        <div className="col-1 ml-auto">
                            {props.maximizar !== undefined &&
                            <button type="button" className=" mr-1 mt-0 pt-1 btn-link border-0 bg-white text-success"
                                    onClick={() => {
                                        props.maximizar("terapias", !is_max)
                                        setIs_max(prev => !prev)
                                    }}>
                                {is_max ?
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-dash "
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                    </svg> :
                                    <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" className="bi bi-window"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M14 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2z"/>
                                        <path fillRule="evenodd" d="M15 6H1V5h14v1z"/>
                                        <path
                                            d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                    </svg>
                                }
                            </button>
                                }
                        </div>
                    </div>
                </div>
                { is_max &&
                <div className="col-3 pb-5 border-right border-secondary">
                    <div className=" mb-3 subtitulo">
                        <h5 className="py-2 px-3"> Búsqueda</h5>
                    </div>
                    <div className="row">
                        <label className=" ml-3 mr-2">Paciente</label>
                        {etiquetas.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={paciente}
                            options={[{name: "...", value: ""}, ...pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }
                    </div>
                    <div className="row">
                        <label className=" ml-3 mr-2">Terapia</label>
                        {etiquetas.length > 0 &&
                        <SelectSearch
                            className="select-search ml-4"
                            value={tipoTerapia}
                            options={[{name: "...", value: ""}, ...tipoTerapias.map((tipo, index) =>
                                ({name: tipo.nombre, value: tipo.id})) ]}

                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_tipoTerapia}
                        />
                        }
                    </div>
                    <div className="row">
                        <label className=" ml-3 mr-1">Terapeuta</label>
                        {etiquetas.length > 0 &&
                        <SelectSearch
                            className="select-search ml-2"
                            value={terapeuta}
                            options={[{name: "...", value: ""}, ...terapeutas.map((terapeuta, index) =>
                                ({name: terapeuta.user.username, value: terapeuta.user.id})
                            ) ]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_terapeuta}
                        />
                        }
                    </div>
                    <div className="row">
                        <label className=" ml-3">Etiqueta</label>
                        {etiquetas.length > 0 &&
                        <SelectSearch
                            className="select-search ml-4"
                            value={etiquetaId}
                            options={ [{name: "...", value: ""}, ...etiquetas.map((etiqueta, index) =>
                                ({name: etiqueta, value: index})
                            ),]}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_etiqueta}
                        />
                        }
                    </div>

                    <textarea rows="3" className=" ml-3 mt-2 mr-3 w-75 form-control" name="search" value={search}
                              placeholder={"Texto a buscar"} onChange={handle_input}/>
                    <div className="ml-4 mt-4 mb-4">
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setDateSelection([item.selection])}
                            moveRangeOnFirstSelection={false}
                            locale={es}
                            direction="vertical"
                            scroll={{enabled: true}}
                            ranges={dateSelection}
                        />
                    </div>
                    <button type="button" className="btn btn-primary ml-5 mr-5" onClick={query_terapias}>Buscar
                    </button>
                    <button type="button" className="btn btn-secondary ml-5"
                            onClick={restablece_terapias}>Restablecer
                    </button>

                </div>}
                <div className={`${is_max ? 'col-9' : 'col-12'} lista-terapias bg-white`}>
                    {terapias.map((terapia, index) =>
                        <div
                            key={index}
                        >
                            <div className="row subtitulo py-2 px-1">
                                <h6 className="">{moment(terapia.fecha).format('dddd Do MMMM  YYYY, h:mm:ss a')}</h6>
                                <h5 className="ml-5">{terapia.nombre}</h5>
                                <h6 className="mr-auto ml-2 mt-1">({terapia.terapeuta})</h6>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: terapia.comentarios}}/>
                            {terapia.intervenciones.map((intervencion, index2) => (
                                <div
                                    key={String(index) + String(index2)}
                                >
                                    <div className="row ml-3">
                                        <span className="font-weight-bold text-medium">{intervencion.paciente}</span>
                                        <span className="ml-auto">{intervencion.tag}</span>
                                    </div>
                                    <div className="ml-4" dangerouslySetInnerHTML={{__html: intervencion.texto}}/>
                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>


        </div>
    )

}

export default TerapiasView