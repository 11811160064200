import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import moment from "moment";
import {Calendar} from 'react-date-range';
import {es} from "react-date-range/dist/locale";

function FechaFacturacion(props) {
    const [fecha, setFecha] = useState(new Date())

    function handleFecha(date) {
       setFecha(date)
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Fecha de Facturación</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-8 mx-auto text-center">
                        <label className="font-weight-bold">Seleccionar Fecha de Facturación / Cobro</label>
                        <Calendar
                            date = {fecha}
                            minDate={new Date(props.fecha)}
                            onChange={handleFecha}
                            locale={es}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={ () => {
                        props.facturar(moment(fecha).format("YYYY-MM-DD"), props.cobro, props.anular)
                        props.handleClose()
                    }
                    }>Aceptar
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default FechaFacturacion;