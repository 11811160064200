import React, {useState, useEffect} from 'react';
import 'whatwg-fetch';
import {Modal} from "react-bootstrap";
import {authHeaders} from "../Utils/authUtils";
import {APIPATH} from "../settings";
import SelectSearch from "react-select-search";
import moment from "moment";
import "./Facturacion.css"


function UpdateProforma(props) {
    const [updating, setUpdating] = useState(false);
    const [factura, setFactura] = useState({
        id: "",
        detalle: [],
        deposito: "",
        fecha_creacion: moment(new Date()).format('YYYY-MM-DDThh::mm'),
        importe_neto: "0",
        importe_total: "0",
        envio_proForma: false,
        anulada: false,
        anticipado: true,
        facturar: true,
        paciente: "",
        centro: "",
    })
    const lineaNueva = {
        factura: props.factura.id,
        concepto: "",
        importe: "",
        iva: "0.00",
        grupoTarifa: "",
    }
    const [tarifas, setTarifas] = useState([])
    const [depositos, setDepositos] = useState([])
    const [validData, setValidData] = useState(true)
    const [pacientes, setPacientes] = useState([])
    useEffect(() => {
            if (props.factura.id !== undefined) {
                const fact = {...props.factura}
                delete fact.select
                setValidData(true)
                setFactura(fact)
            } else {
                setValidData(false)
                setFactura({
                        id: "",
                        detalle: [],
                        fecha_creacion: moment(new Date()).format('YYYY-MM-DDThh:mm'),
                        importe_neto: "0",
                        importe_total: "0",
                        envio_proForma: false,
                        anulada: false,
                        facturar: true,
                        paciente: "",
                        centro: props.centro.id,
                    }
                )
            }
            if (props.centro.id !== undefined) {
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/facturacion/tarifas/query/'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setTarifas(response)

                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/contabilidad/depositos/'
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setDepositos(response)

                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })
                authHeaders().then(authHeader => {
                    const endpoint = APIPATH + 'api/pacientes/?centro=' + props.centro.id
                    const lookupOptions = {
                        method: "GET",
                        headers: {
                            'Content-Type': 'application/json',
                            ...authHeader
                        },
                        credentials: 'include',
                    }
                    fetch(endpoint, lookupOptions).then(
                        response => response.json()
                    ).then(
                        response => {
                            setPacientes(response)
                        }
                    ).catch(
                        error => {
                            console.log("error", error)
                        }
                    )
                })

            }
        }
        ,
        [props]
    )


    function handle_input(e) {
        let {value, name, checked} = e.target
        if (['facturar', 'activo'].includes(name)) {
            value = checked
        }

        setFactura(prev => ({...prev, [name]: value}))

    }

    function handle_linea(e) {
        let {value, name, dataset} = e.target
        let valid = true
        if (['importe', 'iva'].includes(name) && !(!isNaN(parseFloat(value)) && isFinite(value)) && value !== "") {
            valid = false
        }


        const lineas = [...factura.detalle]
        let imp_neto = 0
        let imp_total = 0
        lineas[dataset.index] = {...lineas[dataset.index], [name]: value}
        if (valid) {
            lineas.forEach(linea => {
                if (linea.grupoTarifa === "" || linea.concepto === "") {
                    valid = false
                }
                imp_neto += parseFloat(linea.importe)
                imp_total += parseFloat(linea.importe) * (1 + parseFloat(linea.iva) / 100)
            })
        }

        setFactura(prev => ({...prev, detalle: lineas, importe_neto: imp_neto, importe_total: imp_total}))

        setValidData(valid)
    }

    function handle_paciente(value, object) {
        setFactura(prev => ({...prev, paciente: value}))
    }

    function handle_deposito(value, object) {
        setFactura(prev => ({...prev, deposito: value}))
    }

    function handle_grupoTarifa(value, object, index) {
        const lineas = [...factura.detalle]
        lineas[index] = {...lineas[index], grupoTarifa: value}
        setFactura(prev => ({...prev, detalle: lineas}))
    }

    function update_factura(e) {
        setUpdating(true)
        if (factura.id === "") {
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/'
                const lookupOptions = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(factura)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUpdating(false)
                        props.refresh()
                        props.handleClose()
                    }
                ).catch(
                    error => {
                        console.log("error", error)
                    }
                )
            })
        } else {
            const data = {...factura}
            delete data['id']
            authHeaders().then(authHeader => {
                const endpoint = APIPATH + 'api/facturacion/detail/' + factura.id + '/'
                const lookupOptions = {
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/json',
                        ...authHeader
                    },
                    credentials: 'include',
                    body: JSON.stringify(data)
                }
                fetch(endpoint, lookupOptions).then(
                    response => response.json()
                ).then(
                    response => {
                        setUpdating(false)
                        props.refresh()
                        props.handleClose()

                    }
                ).catch(
                    error => {
                        console.log("error", error)

                    }
                )
            })
        }

    }

    function delete_factura(e) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/detail/' + factura.id + '/'
            const lookupOptions = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',
            }
            fetch(endpoint, lookupOptions).then(
                response => {
                    props.refresh()
                    props.handleClose()

                }
            ).catch(
                error => {
                    console.log("error", error)

                }
            )
        })

    }

    function imprime_factura(e) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/getpdf/' + factura.id + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',

            }

            fetch(endpoint, lookupOptions).then(response =>
                response.json()
            ).then(response => {
                const url = APIPATH + 'pdf/proforma/' + response.token + '/'
                window.open(url);
            })
                .catch(error => {
                    console.log(error);
                });
        })
    }

    function envia_factura(id) {
        authHeaders().then(authHeader => {
            const endpoint = APIPATH + 'api/facturacion/getpdf/' + factura.id + '/'
            const lookupOptions = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    ...authHeader
                },
                credentials: 'include',


            }

            fetch(endpoint, lookupOptions).then(response =>
                response.json()
            ).then(response => {
                const endpoint = APIPATH + 'pdf/proforma/' + response.token + '/?email=True'
                const lookupOptions = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    mode: 'no-cors',
                }
                fetch(endpoint, lookupOptions).then(response => {
                    alert("Factura enviada")
                    props.refresh()
                    props.handleClose()
                }).catch(error => {
                    console.log(error);
                    alert("No se pudo enviar la Factura")
                })
            })
                .catch(error => {
                    console.log(error);
                    alert("No se pudo enviar la Factura")
                });
        })
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.handleClose} animation={false} size="xl">
                <Modal.Header closeButton>
                    <h5 className="modal-title">Proforma</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <label className=" ml-3 mr-2">Paciente</label>
                        {pacientes.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={factura.paciente}
                            options={[{name: "...", value: ""}, ...pacientes.map((paciente, index) =>
                                ({name: paciente.alias, value: paciente.id})
                            )]}
                            search
                            disabled={factura.id !== ""}
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_paciente}
                        />
                        }

                        <label className="col-1 ml-4 mr-2" htmlFor="fecha_creacion">Fecha</label>
                        <input type='datetime-local' id="concepto"
                               className={` col-3 form-control `}
                               name="fecha_creacion" value={factura.fecha_creacion.substring(0, 16)}
                               disabled={true}/>
                        <label className=" ml-3 mr-2">Deposito</label>
                        {depositos.length > 0 &&
                        <SelectSearch
                            className="select-search ml-3"
                            value={factura.deposito}
                            options={depositos.map((deposito, index) =>
                                ({name: deposito.nombre, value: deposito.id})
                            )}
                            search
                            printOptions="on-focus"
                            placeholder={" ... "}
                            onChange={handle_deposito}
                        />
                        }
                    </div>
                    <div className="form-group row ml-3 mt-4">
                        <label htmlFor="importe_neto" className="mt-2">Importe Neto</label>
                        <input type='text' id="importe_neto" disabled={true}
                               className={` col-2 form-control ml-3 mr-3`}
                               name="importe_neto" value={parseFloat(factura.importe_neto).toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR'
                        })}/>
                        <label htmlFor="importe_total" className="mt-2 ml-5 ">Importe Total</label>
                        <input type='text' id="importe_total" disabled={true}
                               className={` col-2 form-control ml-3`}
                               name="importe_total"
                               value={parseFloat(factura.importe_total).toLocaleString('de-DE', {
                                   style: 'currency',
                                   currency: 'EUR'
                               })}/>
                    </div>
                    <div className="form-group ml-4">
                        <input className="form-check-input " type="checkbox" checked={factura.envio_proForma}
                               id="envio_proforma"
                               name="envio_proforma"
                               disabled={true}/>
                        <label className="form-check-label mr-5" htmlFor="envio_proforma">
                            Enviada
                        </label>

                        <input className="form-check-input " type="checkbox" checked={factura.facturar}
                               id="facturar"
                               name="facturar"
                               onChange={handle_input}/>
                        <label className="form-check-label mr-5" htmlFor="facturar">
                            Generar Factura
                        </label>
                    </div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th>
                                <button type="button"
                                        className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                        onClick={(e) => {
                                            setFactura(prev => ({...prev, detalle: [...prev.detalle, lineaNueva]}))
                                        }}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-plus-circle"
                                         fill="currentColor"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path fillRule="evenodd"
                                              d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                    </svg>
                                </button>
                            </th>
                            <th className="text-center">
                                Tarifa
                            </th>
                            <th className="text-center">
                                concepto
                            </th>
                            <th className="text-center">
                                importe
                            </th>
                            <th className="text-center">
                                iva
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {factura.detalle.map((linea, index) =>
                            <tr key={index}>
                                <td width="5%">
                                    <button type="button"
                                            className=" mr-2 ml-2 mt-0 pt-0 border-0 bg-white text-success"
                                            onClick={(e) => {
                                                const lineas = [...factura.detalle]
                                                lineas.splice(index, 1)
                                                let imp_neto = 0
                                                let imp_total = 0

                                                lineas.forEach(linea => {
                                                    imp_neto += parseFloat(linea.importe)
                                                    imp_total += parseFloat(linea.importe) * (1 + parseFloat(linea.iva) / 100)
                                                })

                                                setFactura(prev => ({
                                                    ...prev,
                                                    detalle: lineas,
                                                    importe_neto: imp_neto,
                                                    importe_total: imp_total
                                                }))

                                            }}>
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash"
                                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                            <path fillRule="evenodd"
                                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                        </svg>
                                    </button>
                                </td>
                                <td width="25%">
                                    {tarifas.length > 0 &&
                                    <SelectSearch
                                        className="select-search ml-3"
                                        value={linea.grupoTarifa}
                                        options={[{name: "...", value: ""}, ...tarifas.map((tarifa, index) =>
                                            ({name: tarifa.nombre, value: tarifa.id})
                                        )]}
                                        search
                                        printOptions="on-focus"
                                        placeholder={" ... "}
                                        onChange={(value, object) => handle_grupoTarifa(value, object, index)}
                                        disabled={linea.id !== undefined}
                                    />
                                    }
                                </td>
                                <td>
                                    <input type='text' data-index={index} onChange={handle_linea}
                                           className={` form-control ml-3`}
                                           name="concepto" value={linea.concepto}/>
                                </td>
                                <td width="10%">
                                    <input type='text' data-index={index} onChange={handle_linea}
                                           className={` form-control ml-3`}
                                           name="importe" value={linea.importe}/>
                                </td>
                                <td width="8%">
                                    <input type='text' data-index={index} onChange={handle_linea}
                                           className={`  form-control ml-3`}
                                           name="iva" value={linea.iva}/>

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {updating &&
                    <div className="spinner-border text-primary updating" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={props.handleClose}>Cancelar
                    </button>
                    <button type="button" className="btn btn-outline-danger" onClick={delete_factura}
                            disabled={factura.id === ""}>Borrar
                    </button>
                    <button type="button" className="btn btn-outline-warning" onClick={imprime_factura}
                            disabled={!factura.facturar}>Imprimir
                    </button>
                    <button type="button" className="btn btn-outline-warning" onClick={envia_factura}
                            disabled={!factura.facturar}>Enviar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={update_factura} disabled={
                        (factura.deposito === undefined  || factura.deposito === "" || factura.paciente === "" || !validData)
                    }
                    >Actualizar
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default UpdateProforma;